import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getAllTransporters, deletetransporter } from "../../../../../utils/firebaseOperations";
import { toast } from "react-toastify";
import MiniTransporterRegModal from "../../../../../components/userAccBtn/MiniTransporterRegModal";
import { AnimatePresence, motion } from "framer-motion";
import MultiselectDropdown from "../../../../../components/UI/dropdowns/MultiselectDropdown";
 
const TransportersMng = () => {
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const [selectedOptions, setSelectedOptions] = useState(["All"]);
	const [selectedTreatments, setSelectedTreatments] = useState([]);
	const [tooltipPosition, setTooltipPosition] = useState({ left: 0, top: 0 });
	const [tooltipTimer, setTooltipTimer] = useState(null);
	const [activeTooltip, setActiveTooltip] = useState(null);
	const [modalData, setModalData] = useState(null);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const navigate = useNavigate();
	const [hoveredRow, setHoveredRow] = useState(null);
	const [isTooltipActive, setIsTooltipActive] = useState(false);
	const [isHoveringTooltip, setIsHoveringTooltip] = useState(false);

	useEffect(() => {
		const fetchTransporters = async () => {
			try {
				const transporters = await getAllTransporters();
				setData(transporters);
			} catch (err) {
				console.error("Error fetching transporter data:", err);
				setError("Failed to load transporter data.");
			} finally {
				setLoading(false);
			}
		};
		fetchTransporters();
	}, []);

	const handleRowClick = (id) => {
		navigate(`/admin/assets/transporters/add-transporter/${id}/edit`);
	};

	const handleCheckboxChange = (index, e) => {
		e.stopPropagation();
		setSelectedTreatments((prev) => {
			const newSelected = prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index];
			return newSelected;
		});
	};

	const handleSelectAllChange = (e) => {
		e.stopPropagation();
		setSelectedTreatments(selectedTreatments.length === data.length ? [] : data.map((_, index) => index));
	};

	const handleDelete = async (id) => {
		const loadingToast = toast.loading("Deleting...");
		try {
			await deletetransporter(id);
			toast.update(loadingToast, {
				render: "Satellite Location deleted successfully!",
				type: "success",
				isLoading: false,
				autoClose: 3000,
			});
			const transporters = await getAllTransporters();
			setData(transporters);
			setSelectedTreatments((prev) => prev.filter((index) => data[index].id !== id));
		} catch (error) {
			toast.update(loadingToast, {
				render: "Error deleting transporter!",
				type: "error",
				isLoading: false,
				autoClose: 3000,
			});
			console.error("Error deleting transporter: ", error);
		}
	};

	const clickHandler = (action) => {
		const selectedTransporters = selectedTreatments.map((index) => filteredData[index]);
		if (action === "Delete") {
			selectedTransporters.forEach((transporter) => handleDelete(transporter.id));
		} else if (action === "Edit") {
			selectedTransporters.forEach((transporter) => {
				handleRowClick(transporter.id);
			});
		}
	};

	const handleMouseEnter = (item, event) => {
		clearTimeout(tooltipTimer);
		const timer = setTimeout(() => {
			setActiveTooltip(item.id);
			setModalData(item);
			updateTooltipPosition(event);
			setIsTooltipActive(true);
		}, 3000);
		setTooltipTimer(timer);
		setHoveredRow(item.id);
	};

	const handleMouseLeave = () => {
		if (!isHoveringTooltip) {
			clearTimeout(tooltipTimer);
			setActiveTooltip(null);
			setIsTooltipActive(false);
			setHoveredRow(null);
		}
	};

	const updateTooltipPosition = (event) => {
		const viewportWidth = window.innerWidth;
		const cursorX = event.clientX;
		const cursorY = event.clientY;
		const tooltipWidth = 500;
		const tooltipHeight = 500;
		const offset = 10;
		let left, top;

		if (cursorX > viewportWidth / 2) {
			left = cursorX - tooltipWidth - offset;
		} else {
			left = cursorX + offset;
		}

		top = cursorY - offset;

		left = Math.max(0, Math.min(left, viewportWidth - tooltipWidth));
		top = Math.max(0, Math.min(top, window.innerHeight - tooltipHeight));

		setTooltipPosition({ left, top });
	};

	const openAddTransporterModal = () => {
		setIsModalOpen(true);
	};

	const handleSelectedOptionsChange = (selected) => {
		if (selected.includes("All")) {
			setSelectedOptions(["All"]);
		} else {
			setSelectedOptions(selected);
		}
	};

	const filteredData = data.filter((item) => {
		const treatmentStatus = item.transportorstatus || [];
		if (selectedOptions.includes("All")) {
			return true;
		}
		const includesActive = selectedOptions.includes("Active");
		const includesInactive = selectedOptions.includes("Inactive");
		if (includesActive && includesInactive) {
			return true;
		}
		if (includesActive) {
			return treatmentStatus.includes("Active");
		}
		if (includesInactive) {
			return treatmentStatus.includes("Inactive");
		}
		return false;
	});

	if (loading) {
		return (
			<div className="p-6">
				<div className="main-container flex flex-col border border-cardBorderCol bg-white rounded-cardRadii h-full">
					<div className="text-lg font-normal ml-10 mt-4 text-black pb-2 mb-2">Satellite Locations Facilities</div>
					<div className="flex flex-col md:flex-row justify-between text-sm font-medium pl-8 py-4 bg-indigo-50">
						{["Name", "Octo Number", "Address", "Email", "Phone Number"].map((header) => (
							<div className="w-full md:w-1/5" key={header}>
								{header}
							</div>
						))}
					</div>
					<div className="overflow-y-scroll">
						{Array.from({ length: 9 }).map((_, index) => (
							<div key={index} className="flex flex-col md:flex-row items-start pt-2 border-b pl-8 border-gray-300 animate-pulse" style={{ minHeight: "80px" }}>
								{Array.from({ length: 5 }).map((_, i) => (
									<div className="w-full md:w-1/5 text-sm font-normal" key={i}>
										<div className="h-4 bg-gray-300 rounded mt-4 w-3/4"></div>
									</div>
								))}
							</div>
						))}
					</div>
				</div>
			</div>
		);
	}

	if (error) {
		return <div>{error}</div>;
	}

	const anyChecked = selectedTreatments.length > 0;

	return (
		<>
			 
			<div className="p-2">
				<div className="main-container flex flex-col border border-cardBorderCol bg-white rounded-cardRadii h-full">
					<div className="flex justify-between mx-8">
						<div className="text-lg font-normal text-black my-4 mt-6">Satellite Locations List</div>
						<div className="my-4 px-2">
							<MultiselectDropdown
								buttonText="Satellite Locations Status"
								options={[
									{ label: "All", value: "All" },
									{ label: "Active", value: "Active" },
									{ label: "Inactive", value: "Inactive" },
								]}
								selectedOptions={selectedOptions}
								onSelectedOptionsChange={handleSelectedOptionsChange}
							/>
						</div>
					</div>
					<div className="flex flex-col md:flex-row justify-between text-sm font-medium pl-4 py-4 bg-indigo-50">
						<div className="w-8 mx-6 flex justify-center items-center hover:cursor-pointer">
							<input type="checkbox" className="w-4 h-4 bg-white" checked={selectedTreatments.length === data.length} onChange={handleSelectAllChange} />
						</div>
						{["Name", "Octo Number", "Email", "Phone Number", "Status"].map((header) => (
							<div className="w-full md:w-1/5" key={header}>
								{header}
							</div>
						))}
					</div>

					<div className="overflow-y-scroll">
						{filteredData.map((item, index) => (
							<div
								key={item.id}
								className={`flex flex-col md:flex-row items-start py-4 border-b pl-4 border-gray-300 ${hoveredRow === item.id ? "bg-gray-200" : ""}`}
								onMouseEnter={(e) => handleMouseEnter(item, e)}
								onMouseLeave={handleMouseLeave}
							>
								<div className="mx-8 flex justify-center items-center mt-1">
									<input type="checkbox" className="w-4 h-4 bg-white" checked={selectedTreatments.includes(index)} onChange={(e) => handleCheckboxChange(index, e)} />
								</div>
								<div className="w-full md:w-1/5" onClick={() => handleRowClick(item.id)} style={{ cursor: "pointer" }}>
									{item.name ? (item.name.length > 18 ? `${item.name.slice(0, 18)}...` : item.name) : "Not provided"}
								</div>
								<div className="w-full md:w-1/5" onClick={() => handleRowClick(item.id)} style={{ cursor: "pointer" }}>
									{item.octoNumber || "Not provided"}
								</div>
								<div className="w-full md:w-1/5" onClick={() => handleRowClick(item.id)} style={{ cursor: "pointer" }}>
									{item.generalEmail || "Not provided"}
								</div>
								<div className="w-full md:w-1/5" onClick={() => handleRowClick(item.id)} style={{ cursor: "pointer" }}>
									{item.phoneNumber || "Not provided"}
								</div>
								<div className="w-full md:w-1/5" onClick={() => handleRowClick(item.id)} style={{ cursor: "pointer" }}>
									{item.transportorstatus || "Not provided"}
								</div>
							</div>
						))}
					</div>
				</div>

				<div className={`fixed bottom-0 left-0 right-0 ml-72 shadow-md px-8 transition-all duration-300 ease-in-out ${selectedTreatments.length > 0 ? "translate-y-0" : "translate-y-full"}`}>
					<div className="w-full py-4 flex justify-between items-center">
						<span className="truncate">{selectedTreatments.length} item(s) selected</span>
						<div className="space-x-2">
							{["Edit", "Save as PDF", "Share", "Delete"].map((actionBtn) => (
								<button
									key={actionBtn}
									className={`px-4 py-2 rounded-lg ${actionBtn === "Delete" ? "bg-lightBlue text-white hover:bg-cardTextBlue" : "bg-creamWhite hover:bg-cardTextGray hover:bg-opacity-10"
										} border transition-colors duration-200 ease-in-out`}
									onClick={() => clickHandler(actionBtn)}
								>
									{actionBtn}
								</button>
							))}
						</div>
					</div>
				</div>

				<div className="fixed bottom-8 right-8">
					{!anyChecked && (
						<button
							onClick={openAddTransporterModal}
							className="bg-lightBlue hover:bg-cardTextBlue text-white rounded-full p-4 shadow-lg transition-all duration-300 flex items-center space-x-2"
						>
							<svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
								<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
							</svg>
							<span>Add New Satellite Location</span>
						</button>
					)}
					{isModalOpen && <MiniTransporterRegModal onClose={() => setIsModalOpen(false)} activeSubmenu="transporter" />}
				</div>

				<AnimatePresence>
					{activeTooltip && modalData && isTooltipActive && (
						<motion.div
							className="fixed bg-white rounded-cardRadii min-w-[500px] shadow-2xl border border-cardTextGray border-opacity-10 p-6 z-10 overflow-hidden"
							style={{
								left: `${tooltipPosition.left}px`,
								top: `${tooltipPosition.top}px`,
							}}
							initial={{ opacity: 0, scale: 0.8 }}
							animate={{ opacity: 1, scale: 1 }}
							exit={{ opacity: 0, scale: 0.8 }}
							transition={{ duration: 0.2 }}
							onMouseEnter={() => setIsHoveringTooltip(true)}
							onMouseLeave={() => setIsHoveringTooltip(false)}
						>
							<ToolTipContent transporters={modalData} />
						</motion.div>
					)}
				</AnimatePresence>
			</div>
		</>
	);
};

const ToolTipContent = ({ transporters }) => {
	const details = [
		{ label: "Name", value: transporters.name || "--" },
		{ label: "Phone", value: transporters.phoneNumber || "--" },
		{ label: "Email", value: transporters.generalEmail || "--" },
		{ label: "octoNumber", value: transporters.octoNumber || "--" },
		{ label: "Website", value: transporters.website || "--" },
		{ label: "Satellite Location Status", value: transporters.transportorstatus || "--" },
		{ label: "Satellite Location Id", value: transporters.transporterId || "--" },
	];

	return (
		<div className="w-full">
			{details.map((item) => (
				<div key={item.label} className="w-full flex">
					<div className="w-1/2 p-2 text-cardTextGray truncate">{item.label}</div>
					<div className="w-1/2 p-2 truncate">{item.value}</div>
				</div>
			))}
		</div>
	);
};

export default TransportersMng;
