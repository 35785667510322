import CalendarWithTimeSlots from "./CalendarComponent";
import TimeSlotRepeater from "./TimeSlotRepeater";

const OpenCalendar = () => {
	return (
		<div className="bg-white rounded-cardRadii h-full shadow-sm p-6 pb-0 w-full">
			{/* <div className="pt-4"> */}
			<h2 className="text-lg p-1 h-10 font-medium">Routes By Date</h2>
			<div className="h-[calc(100%-60px)] w-full grid gap-4">
				<div className="h-full">
					<div className="h-2/5">
						<CalendarWithTimeSlots />
					</div>
					<div className="h-3/5">
						<TimeSlotRepeater />
					</div>
				</div>
			</div>
			{/* </div> */}
		</div>
	);
};

export default OpenCalendar;
