import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { getAllTransporters } from "../../../utils/firebaseOperations";
import ServiceDropdown from "../generator-management/components/reports/ServiceDropdown";
import Loader from "../../../components/UI/loaders/Loader";

const SubcontractorFinder = () => {
	const [isScrollable, setIsScrollable] = useState(false);
	const [hoveredRow, setHoveredRow] = useState(null);
	const [searchQuery, setSearchQuery] = useState("");
	const [contractors, setContractors] = useState([]);
	const [selectAll, setSelectAll] = useState(false);
	const [subContractors, setSubContractors] = useState([]);
	const [contractorLoading, setContractorLoading] = useState(true);
	const [subContractorLoading, setSubContractorLoading] = useState(true);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const rowRef = useRef(null);
	const navigate = useNavigate();
	const [indeterminate, setIndeterminate] = useState(false);
	const [selectedContracters, setSelectedContracters] = useState([]);
	const [hoveredSteps, setHoveredSteps] = useState(false);

	useEffect(() => {
		if (selectedContracters.length === 0) {
			setSelectAll(false);
			setIndeterminate(false);
		} else if (selectedContracters.length === contractors.length) {
			setSelectAll(true);
			setIndeterminate(false);
		} else {
			setSelectAll(false);
			setIndeterminate(true);
		}
	}, [selectedContracters, contractors]);

	useEffect(() => {
		const fetchContractors = async () => {
			try {
				const contractors = await getAllTransporters();
				setContractors(contractors);
			} catch (err) {
				console.error("Error fetching transporter data:", err);
				setError("Failed to load transporter data.");
			} finally {
				setContractorLoading(false);
			}
		};
		const fetchSubContractors = async () => {
			try {
				// const contractors = await getAllTransporters();
				setSubContractors([]);
			} catch (err) {
				console.error("Error fetching subtransporter data:", err);
				setError("Failed to load subtransporter data.");
			} finally {
				setSubContractorLoading(false);
			}
		};
		fetchContractors();
		fetchSubContractors();
	}, []);

	const handleSelectAllChange = (e) => {
		const checked = e.target.checked;
		if (checked) {
			setSelectedContracters(contractors.map((_, index) => index));
		} else {
			setSelectedContracters([]);
		}
	};

	const handleCheckboxChange = (index) => {
		setSelectedContracters((prev) => (prev.includes(index) ? prev.filter((id) => id !== index) : [...prev, index]));
	};

	const handleNextClick = () => {
		console.log("Next button clicked");
		navigate("/admin/octo-connect/subcontractor-management");
	};

	const handlePreviousBtn = () => {
		console.log("Previous button clicked");
		navigate("/admin");
	};

	const handleSearchChange = (e) => {
		setSearchQuery(e.target.value);
	};

	const filteredSubContractorData = subContractors.filter((item) =>
		item.Customer.toLowerCase().includes(searchQuery.toLowerCase())
	);

	if (error) return <div>{error}</div>;

	const dropdownOptions = [];

	const handlePhoneClick = (event, phone) => {
		event.stopPropagation();
		window.location.href = `tel:${phone}`;
	};

	return (
		<div className="p-6 text-sm xl:text-base">
			<div className="main-container flex flex-col border border-cardBorderCol bg-white rounded-cardRadii h-full">
				<div className="p-4">
					<div className="flex items-center bg-white rounded-full p-3 border border-gray-200 w-2/5 ml-4">
						<input
							type="text"
							placeholder="Search Sub-Contractor"
							value={searchQuery}
							onChange={handleSearchChange}
							className="ml-2 bg-transparent focus:outline-none text-gray-500 flex-1 w-full"
						/>
					</div>
				</div>
				<div className="mx-8 font-semibold mb-3 text-xl text-black">Contractors List</div>
				<div className="overflow-x-scroll overflow-y-hidden">
					<div className="bg-[#E5F2FF] flex font-medium min-w-fit py-4 px-8">
						<div className="flex items-center">
							<div className="truncate w-80">Contractor Name</div>
							<div className="truncate w-60">OCTO Number</div>
							<div className="truncate w-60">Contractor Address</div>
							<div className="truncate w-60">Contractor Email</div>
							<div className="truncate w-60">Contractor Phone</div>
							<div className="truncate w-60">Contractor Website</div>
						</div>
					</div>

					<div className={`min-w-fit overflow-y-scroll min-h-40 h-[40%] overflow-x-hidden`}>
						{contractorLoading ? (
							<Loader height="h-12 pt-4" />
						) : contractors.length > 0 ? (
							contractors.map((transporter, index) => (
								<div
									className={`flex w-full items-center px-8 transition-colors duration-300  ${
										index < contractors.length - 1 ? "border-b border-gray-300" : ""
									} ${hoveredSteps ? "" : "hover:bg-cardTextGray hover:bg-opacity-20"}`}
									style={{ height: "45px" }}
								>
									<div className={`w-full flex items-center cursor-pointer`}>
										<div className="truncate w-80 h-full">{transporter.name}</div>
										<div className="w-60 h-full text-cardTextBlue truncate">{transporter.octoNumber}</div>
										<div className="truncate h-full w-60">{transporter.address ?? "--"}</div>
										<div className="truncate h-full w-60">{transporter.generalEmail ?? "--"}</div>
										<div className={`w-60 h-full`}>
											<span
												className={`truncate ${
													transporter.phoneNumber?.length > 0 ? "hover:text-blueText hover:underline" : ""
												}`}
												onClick={(e) => (transporter.phoneNumber ? handlePhoneClick(e, transporter.phoneNumber) : "")}
											>
												{transporter.phoneNumber?.length > 0 ? transporter.phoneNumber : "--"}
											</span>
										</div>
										<div className="truncate h-full w-60">{transporter.website ?? "--"}</div>
									</div>
								</div>
							))
						) : (
							<div className="flex min-h-40 text-cardTextGray text-lg justify-center items-center">
								<p>No result found</p>
							</div>
						)}
					</div>
				</div>
			</div>

			{/* Sub-Contractors Section */}
			<div className="main-container flex flex-col border border-cardBorderCol bg-white rounded-cardRadii h-full mt-10">
				<div className="text-xl mx-8 my-3 font-semibold text-black">Sub-Contractors List</div>
				<div className="overflow-x-scroll overflow-y-hidden">
					<div className="bg-[#E5F2FF] flex font-medium min-w-fit py-4 px-8">
						<div className="flex items-center">
							<div className="truncate w-80">Sub-contractor Name</div>
							<div className="truncate w-60">OCTO Number</div>
							<div className="truncate w-60">Sub-contractor Address</div>
							<div className="truncate w-60">Sub-contractor Email</div>
							<div className="truncate w-60">Sub-contractor Phone</div>
							<div className="truncate w-60">Sub-contractor Website</div>
							<div className="truncate w-40 text-center">Connect</div>
						</div>
					</div>

					<div className={`min-w-fit overflow-y-scroll h-[40%] overflow-x-hidden`}>
						{subContractorLoading ? (
							<Loader height="h-12 pt-4" />
						) : filteredSubContractorData.length > 0 ? (
							filteredSubContractorData.map((subContractor, index) => (
								<div
									className={`flex w-full items-center px-8 transition-colors duration-300 ${
										index < filteredSubContractorData.length - 1 ? "border-b border-gray-300" : ""
									} ${hoveredSteps ? "" : "hover:bg-cardTextGray hover:bg-opacity-20"}`}
									style={{ height: "45px" }}
								>
									<div className={`w-full flex items-center cursor-pointer`}>
										<div className="truncate w-80 h-full">{subContractor.name}</div>
										<div className="w-60 h-full text-cardTextBlue truncate">{subContractor.octoNumber}</div>
										<div className="truncate h-full w-60">{subContractor.address ?? "--"}</div>
										<div className="truncate h-full w-60">{subContractor.generalEmail ?? "--"}</div>
										<div className={`w-60 h-full`}>
											<span
												className={`truncate ${
													subContractor.phoneNumber?.length > 0 ? "hover:text-blueText hover:underline" : ""
												}`}
												onClick={(e) =>
													subContractor.phoneNumber ? handlePhoneClick(e, subContractor.phoneNumber) : ""
												}
											>
												{subContractor.phoneNumber?.length > 0 ? subContractor.phoneNumber : "--"}
											</span>
										</div>
										<div className="truncate h-full w-60">{subContractor.website ?? "--"}</div>
										<div className="truncate h-full w-40 flex items-center justify-center">
											<button
												onClick={handleNextClick}
												className="bg-[#007AFF] text-sm text-white px-3 py-1.5 rounded-full hover:bg-white hover:text-black hover:border-[#666666] transition-opacity duration-300"
											>
												Connect
											</button>
										</div>
									</div>
								</div>
							))
						) : (
							<div className="flex min-h-40 text-cardTextGray text-lg justify-center items-center">
								<p>No result found</p>
							</div>
						)}
					</div>
				</div>
				{/* Buttons Section */}
				<div className="flex justify-end p-4 mb-12 mt-12">
					<button
						onClick={handlePreviousBtn}
						className="bg-[#007AFF] text-white px-4 py-2 rounded-full mr-4 border border-[#666666] hover:bg-white hover:text-black hover:border-[#666666] transition-opacity duration-300"
						style={{ width: "184px", height: "44px" }}
					>
						Previous
					</button>
					<button
						onClick={handleNextClick}
						className="bg-[#007AFF] text-white px-4 py-2 rounded-full border border-[#666666] hover:bg-white hover:text-black hover:border-[#666666] transition-opacity duration-300"
						style={{ width: "184px", height: "44px" }}
					>
						Next
					</button>
				</div>
			</div>
		</div>
	);
};

export default SubcontractorFinder;
