import React, { useState, useEffect, useRef } from "react";
import { AnimatePresence, motion } from "framer-motion";
import Tooltip from "../../../../../../components/UI/Tooltip";
import ShimmerLoader from "../../../../../../components/UI/loaders/ShimmerLoader";
import { deleteGenerator } from "../../../../../../store/sliceReducers/generatorSlice";
import { useDispatch } from "react-redux";
import { emailRegex } from "../../../../../../utils/mimes";
import { createPortal } from "react-dom";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";

const GenreratorsList = ({ isLoading, generators, handleCheckboxChange, handleRowClick, selectedGenerators }) => {
	const [selectAll, setSelectAll] = useState(false);
	const [indeterminate, setIndeterminate] = useState(false);

	useEffect(() => {
		if (selectedGenerators.length === 0) {
			setSelectAll(false);
			setIndeterminate(false);
		} else if (selectedGenerators.length === generators.length) {
			setSelectAll(true);
			setIndeterminate(false);
		} else {
			setSelectAll(false);
			setIndeterminate(true);
		}
	}, [selectedGenerators, generators]);

	const handleSelectAllChange = () => {
		const newSelectAll = !selectAll;
		setSelectAll(newSelectAll);
		setIndeterminate(false);
		if (newSelectAll) {
			handleCheckboxChange("select_all");
		} else {
			handleCheckboxChange("deselect_all");
		}
	};

	return (
		<div
			className="overflow-x-scroll overflow-y-hidden"
			style={{
				height: `calc(100% - 10px)`,
			}}
		>
			<div className="bg-[#E5F2FF] flex font-medium min-w-fit py-4">
				<div className="w-8 mx-6 flex justify-center items-center hover:cursor-pointer">
					<input
						type="checkbox"
						className="w-4 h-4 bg-white"
						checked={selectAll}
						ref={(input) => input && (input.indeterminate = indeterminate)}
						onChange={handleSelectAllChange}
					/>
				</div>
				<div className="flex items-center">
					<div className="truncate w-60">Generator Name</div>
					<div className="truncate w-40">OCTO Number</div>
					<div className="truncate w-60">Internal Account Number</div>
					<div className="truncate w-80">Service Address</div>
					<div className="truncate w-40">Service Phone</div>
					<div className="truncate w-60">Service Email</div>
					<div className="truncate w-32">Status</div>
					<div className="truncate w-40 text-center">Onboarding Stage</div>
					{/* <div className="truncate w-40">Service Frequency</div> */}
				</div>
			</div>

			{isLoading ? (
				<ShimmerLoader cols={6} rows={20} />
			) : (
				<div style={{ height: `calc(100% - 60px)` }} className={`min-w-fit overflow-y-scroll overflow-x-hidden`}>
					{generators.length > 0 ? (
						generators.map((generator, index) => (
							<GeneratorItem
								key={index}
								generator={generator}
								index={index}
								selectedGenerators={selectedGenerators}
								handleCheckboxChange={handleCheckboxChange}
								handleRowClick={handleRowClick}
							/>
						))
					) : (
						<div className="flex h-full text-cardTextGray text-xl justify-center items-center">
							<p>No result found</p>
						</div>
					)}
				</div>
			)}

			<FooterActionItems genSelected={selectedGenerators} generators={generators} />
		</div>
	);
};

export default GenreratorsList;

const GeneratorItem = ({ generator, index, selectedGenerators, handleCheckboxChange, handleRowClick }) => {
	const [activeTooltip, setActiveTooltip] = useState(null);
	const [tooltipPosition, setTooltipPosition] = useState({ left: 0, top: 0 });
	const [transformOrigin, setTransformOrigin] = useState("top left");
	const [hoveredSteps, setHoveredSteps] = useState(false);
	const timeoutRef = useRef(null);
	const tooltipRef = useRef(null);
	const rowRef = useRef(null);

	const handleMouseEnter = (generatorId, event) => {
		if (hoveredSteps) return;

		clearTimeout(timeoutRef.current);
		timeoutRef.current = setTimeout(() => {
			setActiveTooltip(generatorId);
			updateTooltipPosition(event);
		}, 3000);
	};

	const handleMouseLeave = (event) => {
		clearTimeout(timeoutRef.current);
		if (
			!tooltipRef.current ||
			!event.relatedTarget ||
			!(event.relatedTarget instanceof Node) ||
			!tooltipRef.current.contains(event.relatedTarget)
		) {
			setActiveTooltip(null);
		}
	};

	const updateTooltipPosition = (event) => {
		const viewportWidth = window.innerWidth;
		const cursorX = event.clientX;
		const cursorY = event.clientY;
		const tooltipWidth = 500;
		const tooltipHeight = 500;
		const offset = 10;
		let left, top;

		if (cursorX > viewportWidth / 2) {
			left = cursorX - tooltipWidth - offset;
			setTransformOrigin("top right");
		} else {
			left = cursorX + offset;
			setTransformOrigin("top left");
		}

		top = cursorY - offset;

		left = Math.max(0, Math.min(left, viewportWidth - tooltipWidth));
		top = Math.max(0, Math.min(top, window.innerHeight - tooltipHeight));

		setTooltipPosition({ left, top });
	};

	const handlePhoneClick = (event, phone) => {
		event.stopPropagation();
		window.location.href = `tel:${phone}`;
	};

	const handleStepsMouseEnter = () => {
		setHoveredSteps(true);
		setActiveTooltip(null);
		clearTimeout(timeoutRef.current);
	};

	const handleStepsMouseLeave = () => {
		setHoveredSteps(false);
	};

	useEffect(() => {
		return () => clearTimeout(timeoutRef.current);
	}, []);

	useEffect(() => {
		const handleRowMouseLeave = (event) => {
			if (!rowRef.current.contains(event.relatedTarget)) {
				setHoveredSteps(false);
			}
		};

		rowRef.current.addEventListener("mouseleave", handleRowMouseLeave);

		return () => {
			if (rowRef.current) {
				rowRef.current.removeEventListener("mouseleave", handleRowMouseLeave);
			}
		};
	}, []);

	return (
		<div
			ref={rowRef}
			className={`flex w-full items-center transition-colors duration-300 border-b border-gray-300 ${
				hoveredSteps ? "" : "hover:bg-cardTextGray hover:bg-opacity-20"
			}`}
			style={{ height: "45px" }}
		>
			<div className="min-w-8 mx-6 flex justify-center hover:cursor-pointer">
				<input
					type="checkbox"
					className="w-4 h-4 bg-white"
					checked={selectedGenerators.includes(index)}
					onChange={() => handleCheckboxChange(index)}
				/>
			</div>
			<div
				className={`w-full flex items-center cursor-pointer`}
				onClick={() => handleRowClick(generator)}
				onMouseEnter={(e) => !hoveredSteps && handleMouseEnter(generator.id, e)}
				onMouseLeave={handleMouseLeave}
			>
				<div className="truncate w-60 h-full">{generator.name}</div>
				<div className="w-40 h-full text-cardTextBlue truncate">{generator.octoNumber}</div>
				<div className="truncate h-full w-60">{generator.internalAccountNumber}</div>
				<div className="truncate h-full w-80">{generator.address}</div>
				<div className={`w-40 h-full`}>
					<span
						className={`truncate ${generator.phone?.length > 0 ? "hover:text-blueText hover:underline" : ""}`}
						onClick={(e) => (generator.phone ? handlePhoneClick(e, generator.phone) : "")}
					>
						{generator.phone?.length > 0 ? generator.phone : "--"}
					</span>
				</div>
				<div className="truncate h-full w-60">{generator.email}</div>
				<div className="truncate h-full w-32">{generator.generatorStatus}</div>
				<div
					className="truncate h-full w-40 z-0"
					onMouseEnter={handleStepsMouseEnter}
					onMouseLeave={handleStepsMouseLeave}
				>
					<StepsComp steps={generator?.onboardingStatus} />
				</div>
				{/* <div className="truncate h-full w-40">{generator?.serviceFrequency?.toString()}</div> */}
			</div>
			<AnimatePresence>
				{activeTooltip === generator.id && !hoveredSteps && (
					<motion.div
						ref={tooltipRef}
						className="fixed bg-white rounded-cardRadii min-w-[500px] shadow-2xl border border-cardTextGray border-opacity-10 p-6 z-10 overflow-hidden"
						style={{
							left: `${tooltipPosition.left}px`,
							top: `${tooltipPosition.top}px`,
							width: "300px",
						}}
						initial={{ opacity: 0, scale: 0.8, transformOrigin }}
						animate={{ opacity: 1, scale: 1 }}
						exit={{ opacity: 0, scale: 0.8 }}
						transition={{ duration: 0.2 }}
						onMouseEnter={() => setActiveTooltip(generator.id)}
						onMouseLeave={handleMouseLeave}
					>
						<ToolTipContent generator={generator} />
					</motion.div>
				)}
			</AnimatePresence>
		</div>
	);
};

const ToolTipContent = ({ generator }) => {
	const details = [
		{ label: "Name", value: generator.name },
		{ label: "Phone", value: generator.phone || "--" },
		{ label: "Email", value: generator.email || "--" },
		{ label: "OCTO Number", value: generator?.octoNumber || "--" },
		{ label: "Suite Number", value: generator?.serviceAddress?.suite || "--" },
		{ label: "Street", value: generator?.serviceAddress?.street || "--" },
		{ label: "State", value: generator?.serviceAddress?.state || "--" },
		{ label: "ZIP Code", value: generator?.serviceAddress?.zipCode || "--" },
	];

	return (
		<div className="w-full">
			{details.map((item) => (
				<div key={item.label} className={`w-full flex`}>
					<div className="w-1/2 p-2 text-cardTextGray truncate">{item.label}</div>
					<div className="w-1/2 p-2 truncate">{item.value}</div>
				</div>
			))}
		</div>
	);
};

const StepsComp = ({ steps }) => {
	return (
		<div className="flex h-11 items-center justify-center gap-1">
			{
				<Tooltip text={"Registration"}>
					<span
						className={`h-3.5 w-1.5 rounded-full  ${
							steps?.["registrationCompleted"] ? "bg-lightBlue" : "bg-cardTextGray"
						}`}
					></span>
				</Tooltip>
			}
			{
				<Tooltip text={"Profile"}>
					<span
						className={`h-3.5 w-1.5 rounded-full ${steps?.["profileCompleted"] ? "bg-lightBlue" : "bg-cardTextGray"}`}
					></span>
				</Tooltip>
			}
			{
				<Tooltip text={"Contacts"}>
					<span
						className={`h-3.5 w-1.5 rounded-full ${steps?.["contactsCompleted"] ? "bg-lightBlue" : "bg-cardTextGray"}`}
					></span>
				</Tooltip>
			}
			{
				<Tooltip text={"Service Notes"}>
					<span
						className={`h-3.5 w-1.5 rounded-full ${
							steps?.["serviceNotesCompleted"] ? "bg-lightBlue" : "bg-cardTextGray"
						}`}
					></span>
				</Tooltip>
			}
			{
				<Tooltip text={"Routes"}>
					<span
						className={`h-3.5 w-1.5 rounded-full ${steps?.["routesCompleted"] ? "bg-lightBlue" : "bg-cardTextGray"}`}
					></span>
				</Tooltip>
			}
		</div>
	);
};

const FooterActionItems = ({ genSelected, generators }) => {
	const dispatch = useDispatch();

	const deleteGenerators = () => {
		const selectedGenIds = generators.filter((item, i) => genSelected.includes(i)).map((item) => item.id);
		try {
			dispatch(deleteGenerator(selectedGenIds));
		} catch (error) {
			console.log(error);
		}
	};

	const sendEmail = () => {
		const selectedGens = generators
			.filter((_, i) => genSelected.includes(i))
			.map((item) => item.generatorEmail)
			.filter((item) => emailRegex.test(item))
			.join(";");
		window.open(`mailto:${selectedGens}`, "_blank");
	};

	const clickHandler = async (btnType) => {
		if (btnType == "Delete") {
			deleteGenerators();
		} else if (btnType == "Email") {
			sendEmail();
		}
	};
	return (
		<div
			className={`fixed bottom-0 left-0 right-0 ml-72 shadow-md px-8 transition-all duration-300 ease-in-out ${
				genSelected?.length > 0 ? "translate-y-0" : "translate-y-full"
			}`}
		>
			<div className="w-full py-4 flex justify-between items-center">
				<span className="truncate">{genSelected?.length} item(s) selected</span>
				<div className="flex gap-2">
					<ExportComponent genSelected={genSelected} generators={generators} />
					{["Edit", "Merge", "Create Lead", "Send To MailChimp", "Add To Audience", "Email", "Delete"].map(
						(actionBtn) => (
							<button
								disabled={actionBtn !== "Email" && actionBtn !== "Delete" && actionBtn !== "Merge"}
								key={actionBtn}
								className={`px-4 py-2 rounded-lg disabled:bg-cardTextGray disabled:bg-opacity-10 disabled:cursor-not-allowed ${
									actionBtn == "Delete"
										? "bg-lightBlue text-white hover:bg-primaryBlue"
										: "bg-creamWhite hover:bg-cardTextGray hover:bg-opacity-10"
								}  border transition-colors duration-200 ease-in-out`}
								onClick={() => clickHandler(actionBtn)}
							>
								{actionBtn}
							</button>
						)
					)}
				</div>
			</div>
		</div>
	);
};

// Separate Modal Component
const ExportModal = ({ isOpen, isExporting, progress, onClose }) => {
	if (!isOpen) return null;

	return createPortal(
		<div className="fixed inset-0 z-[9999] flex items-center justify-center">
			<div className="modal-overlay absolute inset-0 bg-black bg-opacity-50" />
			<div className="relative bg-white rounded-lg shadow-xl w-full max-w-md mx-4 animate-fadeIn">
				<div className="p-8">
					{/* Header */}
					<div className="mb-6">
						<h3 className="text-2xl font-semibold text-gray-900">Exporting Data</h3>
						<p className="mt-2 text-sm text-gray-600">Please wait while we prepare your file...</p>
					</div>

					{/* Progress Section */}
					<div className="space-y-6">
						{/* Progress Bar */}
						<div className="w-full bg-gray-100 rounded-full h-3">
							<div
								className="bg-lightBlue h-3 rounded-full transition-all duration-300 ease-out"
								style={{ width: `${progress}%` }}
							/>
						</div>

						{/* Progress Details */}
						<div className="flex justify-between items-center">
							<p className="text-sm text-gray-600">{progress < 100 ? "Processing..." : "Download Complete!"}</p>
							<span className="text-sm font-medium text-gray-900">{Math.round(progress)}%</span>
						</div>

						{/* Status Message */}
						<div className="text-center">
							{progress === 100 ? (
								<p className="text-green-600 font-medium">
									Your download will begin automatically. This window will close shortly.
								</p>
							) : (
								<p className="text-gray-600">Please don't close this window while exporting...</p>
							)}
						</div>
					</div>

					{/* Close Button - Only shown when not exporting */}
					{!isExporting && (
						<button
							onClick={onClose}
							className="absolute top-4 right-4 text-gray-400 hover:text-gray-500 
				  focus:outline-none focus:text-gray-500 transition-colors duration-200"
							aria-label="Close modal"
						>
							<svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
								<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
							</svg>
						</button>
					)}
				</div>
			</div>
		</div>,
		document.body
	);
};

// Main Export Component
const ExportComponent = ({ genSelected, generators }) => {
	const [progress, setProgress] = useState(0);
	const [isExporting, setIsExporting] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);

	// Close modal when clicking outside
	useEffect(() => {
		const handleClickOutside = (e) => {
			if (!isExporting && e.target.classList.contains("modal-overlay")) {
				setIsModalOpen(false);
			}
		};

		document.addEventListener("mousedown", handleClickOutside);
		return () => document.removeEventListener("mousedown", handleClickOutside);
	}, [isExporting]);

	const handleExport = async () => {
		setIsExporting(true);
		setIsModalOpen(true);
		setProgress(0);

		try {
			// Step 1: Data preparation (20% of progress)
			let data = generators.filter((_, i) => genSelected.includes(i));
			const worksheetData = data.map((item, index) => {
				setProgress((index / data.length) * 20);
				return {
					"Generator Name": item.name,
					"OCTO Number": item.octoNumber,
					"Internal Account Number": item.internalAccountNumber,
					"Service Address": item.address,
					"Service Phone": item.phone,
					"Service Email": item.email,
					Status: item.generatorStatus,
					"Onboarding Stage": JSON.stringify(item.onboardingStatus ?? {}),
				};
			});
			setProgress(20);

			// Step 2: Create worksheet (40% of progress)
			const workbook = XLSX.utils.book_new();
			const worksheet = XLSX.utils.json_to_sheet(worksheetData);
			XLSX.utils.book_append_sheet(workbook, worksheet, "DataSheet");
			setProgress(40);

			// Step 3: Convert to binary (70% of progress)
			const excelBuffer = XLSX.write(workbook, {
				bookType: "xlsx",
				type: "array",
			});
			setProgress(70);

			// Step 4: Create blob and prepare for download (90% of progress)
			const blob = new Blob([excelBuffer], {
				type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
			});
			setProgress(90);

			// Step 5: Trigger download (100% of progress)
			saveAs(blob, "exported_data.xlsx");
			setProgress(100);

			// Reset after showing 100% completion
			setTimeout(() => {
				setIsExporting(false);
				setProgress(0);
				setIsModalOpen(false);
			}, 1000);
		} catch (error) {
			console.error("Export failed:", error);
			setIsExporting(false);
			setProgress(0);
			setIsModalOpen(false);
		}
	};

	return (
		<>
			<button
				onClick={handleExport}
				disabled={isExporting}
				className={`px-4 py-2 rounded-lg disabled:bg-cardTextGray disabled:bg-opacity-10 
			disabled:cursor-not-allowed bg-creamWhite hover:bg-cardTextGray hover:bg-opacity-10 
			border transition-colors duration-200 ease-in-out`}
			>
				{isExporting ? "Exporting..." : "Export"}
			</button>

			<ExportModal
				isOpen={isModalOpen}
				isExporting={isExporting}
				progress={progress}
				onClose={() => setIsModalOpen(false)}
			/>
		</>
	);
};
