import React from "react";

const ReportCheckbox = ({ checked, label, onChange, type = "primary", styles = "gap-4", color = "", minwidth = "", reverse = false }) => {
	return (
		<div className={`inline-flex items-center cursor-pointer rounded-lg ${minwidth ? "justify-between w-full" : ""} ${styles} ${reverse ? "flex-row-reverse justify-end" : ""}`} onClick={onChange}>
			<div
				className={`border border-gray-300 flex items-center justify-center ${checked ? "bg-lightBlue" : "bg-white"} ${type === "primary" ? "w-6 h-6" : "w-6 h-6"}`}
				style={{ borderWidth: "1px" }}
			>
				{checked && (
					<svg
						className={`${type === "primary" ? "w-6 h-6" : "w-3 h-3"} text-white`}
						viewBox="0 0 24 24"
						fill="none"
						stroke="currentColor"
						strokeWidth="4"
						strokeLinecap="round"
						strokeLinejoin="round"
					>
						<polyline points="20 6 9 17 4 12"></polyline>
					</svg>
				)}
			</div>
			{label && <span className={`text-cardTextGray ${type === "primary" ? "" : "text-sm"} ml-2`}>{label}</span>}
		</div>
	);
};

export default ReportCheckbox;
