import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ReportCheckbox from "./ReportCheckBox";
import ServiceDropdown from "./ServiceDropdown";
import PickupFilters from "./Filter";
import { FilterDropdown } from "./Filter";

const handleCheckboxChange = (data, setData, index, field) => {
    const newData = [...data];
    newData[index][field] = !newData[index][field];
    setData(newData);
};

const handleInputChange = (data, setData, index, value) => {
    const newData = [...data];
    newData[index].paidValue = value;
    setData(newData);
};

const DropOffReport = () => {
    const [hoveredRow, setHoveredRow] = useState(null);
    const navigate = useNavigate();

    // Dummy data
    const dummyData = Array.from({ length: 3 }, (_, index) => {
        const date = new Date(2024, 8, index + 1);
        return {
            id: index + 1,
            date: date.toLocaleDateString(),
            time: date.toLocaleTimeString(),
            Customer: `${index + 1} Generator Name details details`,
            itemsDetails: `#12345126789 ${index}`,
            email: `user${index + 1}@example.com details`,
            address: `${index + 1} Address details details`,
            billed: false,
            paid: false,
            paidValue: "",
            optionChecked: false,
        };
    });

    const [data, setData] = useState(dummyData);
    const dropdownOptions = ["Edit", "Delete", "Share"];

    const handleNextClick = () => {
        navigate('BoxLocation');
    };

    const handlePerviousbtn = () => {
        navigate(-1);
    };

    const handleAllDropdown = [
        { value: 'option1', label: 'Option 1' },
        { value: 'option2', label: 'Option 2' },
        { value: 'option3', label: 'Option 3' },
    ];
    const handleSortDropdown = [
        { value: 'option1', label: 'Option 1' },
        { value: 'option2', label: 'Option 2' },
        { value: 'option3', label: 'Option 3' },
    ];
    const handleDisplayDropdown = [
        { value: 'option1', label: 'Option 1' },
        { value: 'option2', label: 'Option 2' },
        { value: 'option3', label: 'Option 3' },
    ];

    return (
		<div className=" flex flex-col border border-cardBorderCol bg-white rounded-cardRadii">
            <div className="flex-grow overflow-y-auto">
                <div className="text-lg font-normal ml-10 my-4 text-black">Drop Offs Report </div>
                <div className="flex justify-between my-10 mx-6">
                    <PickupFilters Name="From" />
                    <PickupFilters Name="To" />
                     <FilterDropdown name="Treatment Facilities" options={handleAllDropdown} />
                    <FilterDropdown name="Sort By" options={handleSortDropdown} />
                    <FilterDropdown name="Display" options={handleDisplayDropdown} />
                    <button onClick={() => console.log('Viewing details!')} className="bg-blue-500 text-white p-2 px-8 rounded">
                        View
                    </button>
                </div>
                <div className="flex flex-col md:flex-row justify-between text-sm font-medium pl-8 py-4 bg-indigo-50">
                    <div className="w-full md:w-1/5">Date and Time</div>
                    <div className="w-full md:w-1/5">Generator & Items Details</div>
                    <div className="w-full md:w-1/5">Billed & Paid</div>
                    <div className="w-full md:w-1/5">Pickup Details</div>
                    <div className="w-full md:w-1/5">Options</div>
                </div>
                {data.map((item, index) => (
                    <div key={index} className={`flex flex-col md:flex-row items-start py-4 border-b pl-8 border-gray-300 ${hoveredRow === index ? "bg-gray-200" : ""}`}
                        onMouseEnter={() => setHoveredRow(index)} onMouseLeave={() => setHoveredRow(null)}
                        style={{ minHeight: "80px" }}>
                        <div className="w-full md:w-1/5 text-sm font-medium">
                            <div>{item.date}</div>
                            <div className="text-sm font-medium">{item.time}</div>
                        </div>
                        <div className="w-full md:w-1/5 text-sm font-medium">
                            <div className="text-sm font-medium">{item.Customer}</div>
                            <div><span className="font-medium">Account:</span> #12345</div>
                        </div>
                        <div className="w-full md:w-1/5 text-sm font-medium pl-2">
                            <div className="flex mt-1">
                                <div className="text-sm font-medium items-center">Billed:</div>
                                <div className="ml-4">
                                    <ReportCheckbox checked={item.billed} onChange={() => handleCheckboxChange(data, setData, index, 'billed')} />
                                </div>
                                <input type="text" value={item.paidValue} onChange={(e) => handleInputChange(data, setData, index, e.target.value)}
                                    className="ml-2 border border-gray-300 px-2 py-1 w-16 h-6" />
                            </div>
                            <div className="flex mt-1">
                                <div className="text-sm font-medium items-center">Paid:</div>
                                <div className="ml-6">
                                    <ReportCheckbox checked={item.paid} onChange={() => handleCheckboxChange(data, setData, index, 'paid')} />
                                </div>
                                <input type="text" value={item.paidValue} onChange={(e) => handleInputChange(data, setData, index, e.target.value)}
                                    className="ml-2 border border-gray-300 px-2 py-1 w-16 h-6" />
                            </div>
                        </div>
                        <div className="w-full md:w-1/5 text-sm font-medium">
                            <div>Medical Waste</div>
                            <div><span className="text-blue-500">Driver:</span><span className="ml-4">ABC</span></div>
                            <div><span className="text-blue-500">Pickup Truck:</span><span className="ml-4">XYZ</span></div>
                            <div><span className="text-blue-500">Service Type:</span><span className="ml-4">XYZ</span></div>
                            <div><span className="text-blue-500">Treatment Facilities:</span><span className="ml-4">XYZ</span></div>

                        </div>
                        <div className="w-full md:w-1/5 text-sm font-medium">
                            <div className="mt-2">
                                <div className="flex items-center mt-2 text-xs font-medium">
                                    <ServiceDropdown buttonText="Options" options={dropdownOptions} />
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            {/* Buttons Section */}
            <div className="flex justify-end p-4 mb-12 mt-12">
                <button
                    onClick={handlePerviousbtn}
                    style={{ width: '184px', height: '44px' }}
                    className="bg-[#007AFF] text-white px-4 py-2 rounded-full mr-4 border border-[#666666] hover:bg-white hover:text-black hover:border-[#666666] transition-opacity duration-300"
                >
                    Previous
                </button>
                <button
                    onClick={handleNextClick}
                    style={{ width: '184px', height: '44px' }}
                    className="bg-[#007AFF] text-white px-4 py-2 rounded-full border border-[#666666] hover:bg-white hover:text-black hover:border-[#666666] transition-opacity duration-300"
                >
                    Next
                </button>
            </div>
        </div>
    );
};

export default DropOffReport;
