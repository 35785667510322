import React, { useState } from "react";

const Checkbox = ({ isChecked, label, setIsChecked, type = "primary", styles = "gap-3", color = "", minwidth = "", reverse = false }) => {
	// const [isChecked, setIssChecked] = useState(false);
	const handleCheckboxChange = () => {
		setIsChecked(!isChecked);
	};

	return (
		<div
			className={`inline-flex items-center cursor-pointer rounded-lg ${minwidth ? "justify-between w-full" : ""} ${styles} ${reverse ? "flex-row-reverse justify-end" : ""}`}
			onClick={handleCheckboxChange}
		>
			<div
				className={`${type == "primary" ? "min-w-[26px] min-h-[26px]" : "min-w-5 min-h-5"} border-2 flex items-center justify-center 
                        ${isChecked ? "bg-lightBlue border-cardTextBlue" : color ? "bg-white border-cardTextBlue" : "bg-white border-[#DADADA]"}`}
			>
				{isChecked && (
					<svg
						className={`${type == "primary" ? "w-4 h-4" : "w-3 h-3"} text-white`}
						viewBox="0 0 24 24"
						fill="none"
						stroke="currentColor"
						strokeWidth="4"
						strokeLinecap="round"
						strokeLinejoin="round"
					>
						<polyline points="20 6 9 17 4 12"></polyline>
					</svg>
				)}
			</div>
			{label && <span className={`text-cardTextGray truncate select-none ${type == "primary" ? "" : "text-sm"}`}>{label}</span>}
		</div>
	);
};

export default Checkbox;
