import React, { useEffect, useState, useMemo } from "react";
import { useFieldArray, Controller, useForm } from "react-hook-form";
import Input from "../../../../../components/UI/Input";
import { useNavigate, useParams } from "react-router-dom";
import { createNewTreatment, GetTreatmentData, editTreatmentDetails } from "../../../../../utils/firebaseOperations";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Dropdown from "../../../../../components/UI/dropdowns/Dropdown";
import Button from "../../../../../components/UI/Button";
import PhoneInput from "../../../../../components/UI/PhoneInput";
import Checkbox from "../../../../../components/UI/Checkbox";
import { useGeocoding } from "../../../../../hooks/treatmentGeocoding";
import TreatmentMap from "../../../../../components/maps/TreatmentMap";
import { debounce } from "lodash";

const AddTreatment = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
    trigger,
    watch,
  } = useForm({
    defaultValues: {
      Treatment: [
        {
          name: "",
          street: "",
          suite: "",
          city: "",
          phone: "",
          state: "",
          treatmentstatus: "",
          zip: "",
          email1: "",
          email2: "",
          email3: "",
          notificationTypeOption: "",
          stateRegistrationNumber1: "",
          stateRegistrationNumber2: "",
          stateRegistrationNumber3: "",
          medicalwaste: {
            transferStation: false,
            treatmentFacilities: false,
            paperShredding: false
          },
        },
      ],
    },
  });

  const { fields, append } = useFieldArray({
    control,
    name: "Treatment",
  });

  const addTreatment = () => {
    append({
      name: "",
      street: "",
      suite: "",
      city: "",
      phone: "",
      state: "",
      treatmentstatus: "",
      zip: "",
      email1: "",
      email2: "",
      email3: "",
      notificationTypeOption: "",
      stateRegistrationNumber1: "",
      stateRegistrationNumber2: "",
      stateRegistrationNumber3: "",
      medicalwaste: {
        transferStation: false,
        treatmentFacilities: false,
        paperShredding: false
      },
    });
  };

  const handlePreviousBtn = () => {
    navigate("/admin/assets/treatments-facilities");
  };

  const getTreatmentDataById = async (id) => {
    try {
      const treatmentData = await GetTreatmentData(id);
      if (treatmentData) {
        const initializedTreatmentData = {
          ...treatmentData,
          notificationTypeOption: treatmentData.notificationTypeOption || [],
        };
        reset({ Treatment: [initializedTreatmentData] });
        setIsActive(treatmentData.treatmentstatus === "Active");

        // Set geocoded data for map after fetching treatment data
        if (treatmentData.street && treatmentData.city && treatmentData.state && treatmentData.zip) {
          const address = {
            street: treatmentData.street,
            city: treatmentData.city,
            state: treatmentData.state,
            zip: treatmentData.zip,
          };

          // Geocode the address and set the initial map data
          const geocodedData = await geocodeAddress(address);
          if (geocodedData) {
            setMarkers([geocodedData]);
            setCurrentCoordinates({
              lat: geocodedData.lat,
              lng: geocodedData.lng,
            });
          }
        }
      } else {
        toast.error("No treatment data found for the provided ID.");
      }
    } catch (error) {
      console.error("Error fetching treatment data:", error);
      toast.error("Failed to fetch treatment details.");
    }
  };

  useEffect(() => {
    if (id) {
      getTreatmentDataById(id);
    } else {
      reset({
        Treatment: [
          {
            name: "",
            street: "",
            suite: "",
            city: "",
            phone: "",
            state: "",
            treatmentstatus: "Active",
            zip: "",
            email1: "",
            email2: "",
            email3: "",
            notificationTypeOption: "",
            stateRegistrationNumber1: "",
            stateRegistrationNumber2: "",
            stateRegistrationNumber3: "",
            medicalwaste: {
              transferStation: false,
              treatmentFacilities: false,
              paperShredding: false
            },
          },
        ],
      });
    }
  }, [id, reset]);

  const [isActive, setIsActive] = useState(false);
  const [paidinputValue, setpaidInputValue] = useState("");
  const [billedinputValue, setbilledInputValue] = useState("");

  const handleInputChange = (e) => {
    setpaidInputValue(e.target.value);
  };


  const onSubmit = async (data) => {
    try {
      const treatmentData = {
        ...data.Treatment[0],
        treatmentCoordinates: currentCoordinates,
      };

      if (id) {
        await editTreatmentDetails(treatmentData, id);
        toast.success("Treatment updated successfully!");
        navigate("/admin/assets/treatments-facilities");
      } else {
        await createNewTreatment(treatmentData);
        toast.success("Treatment added successfully!");
      }
      reset();
    } catch (error) {
      console.error("Error saving treatment:", error);
      toast.error("Failed to save treatment.");
    }
  };

  // Geolocation and Map handling
  const { batchGeocodeTreatmentAddresses, calculateCenterPosition, geocodeAddress } = useGeocoding();
  const [currentCoordinates, setCurrentCoordinates] = useState(null);
  const [markers, setMarkers] = useState([]);
  const [isAddressesLoaded, setIsAddressesLoaded] = useState(true);

  const initialCenter = useMemo(() => {
    return markers.length > 0
      ? calculateCenterPosition(markers)
      : { lat: 32.7157, lng: -117.1611 }; // Default to San Diego coordinates if no markers
  }, [markers]);

  const debouncedGeocoding = useMemo(
    () =>
      debounce(async (serviceAddress) => {
        if (!serviceAddress) return;

        const currentAddressString = `${serviceAddress.street?.trim() || ""} ${serviceAddress.city.trim()} ${serviceAddress.state.trim()} ${serviceAddress.zipCode.trim()}`.toLowerCase();
        const oldAddressString = markers[0]
          ? `${markers[0].serviceAddress.street?.trim() || ""
            } ${markers[0].serviceAddress.city.trim()} ${markers[0].serviceAddress.state.trim()} ${markers[0].serviceAddress.zipCode.trim()}`.toLowerCase()
          : "";

        // If address has changed, update coordinates
        if (currentAddressString !== oldAddressString) {
          setIsAddressesLoaded(false);
          const geocodedMarkers = await batchGeocodeTreatmentAddresses([{
            name: "New Treatment",
            serviceAddress,
          }]);

          if (geocodedMarkers?.[0]) {
            const newMarker = {
              ...geocodedMarkers[0],
              key: Math.random().toString(36).substr(2, 9),
              formattedAdd: `${serviceAddress.street}, ${serviceAddress.city}, ${serviceAddress.state} ${serviceAddress.zipCode}`,
            };
            setMarkers([newMarker]);
            setCurrentCoordinates({
              lat: newMarker.lat,
              lng: newMarker.lng,
            });
          } else {
            toast.error("Could not find location for the provided address");
          }
          setIsAddressesLoaded(true);
        }
      }, 1000),
    [batchGeocodeTreatmentAddresses]
  );

  useEffect(() => {
    if (watch("Treatment.0")) {
      const currentAddress = watch("Treatment.0");
      debouncedGeocoding(currentAddress);
    }

    return () => {
      debouncedGeocoding.cancel();
    };
  }, [watch("Treatment.0"), debouncedGeocoding]);

  const handleFormSubmit = async (data) => {
    try {
      const isAddressValid = await trigger("Treatment.0");
      if (!isAddressValid) {
        toast.error("Please fill all required address fields");
        return;
      }

      // Save the location data if the address has changed or if new coordinates are available
      if (data.Treatment[0] && !currentCoordinates) {
        toast.error("Unable to get location coordinates. Please try again.");
        return;
      }

      // Creating a treatmentCoordinates object with lat and lng
      const treatmentCoordinates = {
        lat: currentCoordinates?.lat,
        lng: currentCoordinates?.lng,
      };

      const submissionData = {
        id: id || undefined,
        address: {
          street: data.Treatment[0].street,
          city: data.Treatment[0].city,
          state: data.Treatment[0].state,
          zip: data.Treatment[0].zip,
        },
        treatmentCoordinates, // Use treatmentCoordinates here
        formattedAddress: markers[0]?.formattedAdd,
      };

      await onSubmit(submissionData);
      toast.success("Location saved successfully!");
    } catch (error) {
      console.error("Error saving treatment location:", error);
      toast.error("Error saving location: " + error.message);
    }
  };


  const stateOptions = [
    { value: 'AZ', label: 'AZ' },
    { value: 'CA', label: 'CA' },
    { value: 'FL', label: 'FL' },
    { value: 'GA', label: 'GA' },
    { value: 'IL', label: 'IL' },
    { value: 'MI', label: 'MI' },
    { value: 'MA', label: 'MA' },
    { value: 'NC', label: 'NC' },
    { value: 'NJ', label: 'NJ' },
    { value: 'NY', label: 'NY' },
    { value: 'OH', label: 'OH' },
    { value: 'PA', label: 'PA' },
    { value: 'TX', label: 'TX' },
    { value: 'VA', label: 'VA' },
    { value: 'WA', label: 'WA' }
  ]

  const statusOptions = [
    { value: 'Active', label: 'Active' },
    { value: 'Inactive', label: 'Inactive' },
  ]

  const notificationTypeOption = [
    { value: "Full Manifest", label: "Full Manifest" },
    { value: "Combined Manifest", label: "Combined Manifest" },
  ];

  // const formatPhoneNumber = (value) => {
  //   const numericValue = value.replace(/[^\d]/g, "");
  //   const formattedValue = numericValue.replace(
  //     /(\d{3})(\d{3})(\d{4})/,
  //     "($1) $2-$3"
  //   );
  //   return numericValue.length > 10 ? formattedValue.slice(0, 14) : formattedValue;
  // };

  return (
    <>
      <div className="p-6">
        <div className="flex flex-col border border-cardBorderCol bg-white rounded-cardRadii h-full">
          <div className="h-[400px]">
            {isAddressesLoaded && (
              <TreatmentMap
                geocodedMarker={markers[0]}
                isAddressesLoaded={isAddressesLoaded}
                initialCenter={initialCenter}
                zoomVal={16}
              />
            )}
          </div>
          <ToastContainer />
          <div className="border-b mb-2 border-[#CCCCCC]"></div>
          <div className="ml-6 mr-6">
            {fields.map((field, index) => (
              <div className="flex gap-8 w-full" key={field.id}>
                <div className="w-1/2">
                  <Controller
                    name={`Treatment.${index}.name`}
                    control={control}
                    rules={{ required: "Name is required" }}
                    render={({ field: { onChange, value } }) => (
                      <div>
                        <Input label="Name*" value={value} onChange={onChange} />
                        {errors.Treatment?.[index]?.name && (
                          <p className="text-red-500 text-sm mt-1">
                            {errors.Treatment[index].name.message}
                          </p>
                        )}
                      </div>
                    )}
                  />

                  <Controller
                    name={`Treatment.${index}.street`}
                    control={control}
                    rules={{ required: "Street is required" }}
                    render={({ field: { onChange, value } }) => (
                      <div>
                        <Input label="Street*" value={value} onChange={onChange} />
                        {errors.Treatment?.[index]?.street && (
                          <p className="text-red-500 text-sm mt-1">
                            {errors.Treatment[index].street.message}
                          </p>
                        )}
                      </div>
                    )}
                  />

                  <Controller
                    name={`Treatment.${index}.suite`}
                    control={control}
                    rules={{ required: "Suite is required" }}
                    render={({ field: { onChange, value } }) => (
                      <div>
                        <Input label="Suite*" value={value} onChange={onChange} />
                        {errors.Treatment?.[index]?.suite && (
                          <p className="text-red-500 text-sm mt-1">
                            {errors.Treatment[index].suite.message}
                          </p>
                        )}
                      </div>
                    )}
                  />

                  <Controller
                    name={`Treatment.${index}.city`}
                    control={control}
                    rules={{ required: "City is required" }}
                    render={({ field: { onChange, value } }) => (
                      <div>
                        <Input label="City*" value={value} onChange={onChange} />
                        {errors.Treatment?.[index]?.city && (
                          <p className="text-red-500 text-sm mt-1">
                            {errors.Treatment[index].city.message}
                          </p>
                        )}
                      </div>
                    )}
                  />

                  <Controller
                    name={`Treatment.${index}.state`}
                    control={control}
                    rules={{ required: "State is required" }}
                    render={({ field: { value, onChange } }) => (
                      <div>
                        <Dropdown
                          value={value}
                          onChange={onChange}
                          options={stateOptions}
                          label="State*"
                        />
                        {errors.Treatment?.[index]?.state && (
                          <p className="text-red-500 text-sm mt-1">
                            {errors.Treatment[index].state.message}
                          </p>
                        )}
                      </div>
                    )}
                  />

                  <Controller
                    name={`Treatment.${index}.zip`}
                    control={control}
                    rules={{
                      required: "Zip is required",
                      pattern: { value: /^[0-9]+$/, message: "Zip must be numeric" },
                    }}
                    render={({ field: { onChange, value } }) => (
                      <div>
                        <Input label="Zip*" value={value} onChange={onChange} />
                        {errors.Treatment?.[index]?.zip && (
                          <p className="text-red-500 text-sm mt-1">
                            {errors.Treatment[index].zip.message}
                          </p>
                        )}
                      </div>
                    )}
                  />

                  <Controller
                    name={`Treatment.${index}.phone`}
                    control={control}
                    rules={{
                      required: "Phone is required",
                      pattern: {
                        value: /^\(\d{3}\) \d{3}-\d{4}$/,
                        message: "Phone number format is invalid"
                      }
                    }}
                    render={({ field }) => (
                      <div>
                        <PhoneInput
                          id={`Treatment.${index}.phone`}
                          label="Phone*"
                          value={field.value || ""}
                          onChange={(formattedNumber) => {
                            field.onChange(formattedNumber);
                          }}
                          isDisabled={false}
                        //isRequired={true}
                        />
                        {errors.Treatment?.[index]?.phone && (
                          <p className="text-red-500 text-sm mt-1">
                            {errors.Treatment[index].phone.message}
                          </p>
                        )}
                      </div>
                    )}
                  />

                  <Controller
                    name={`Treatment.${index}.treatmentstatus`}
                    control={control}
                    rules={{ required: "Treatment Status is required" }}
                    render={({ field: { value, onChange } }) => (
                      <div>
                        <Dropdown
                          value={value}
                          onChange={onChange}
                          options={statusOptions}
                          label="Treatment/Transfer Facility Status*"
                        />
                        {errors.Treatment?.[index]?.treatmentstatus && (
                          <p className="text-red-500 text-sm mt-1">
                            {errors.Treatment[index].treatmentstatus.message}
                          </p>
                        )}
                      </div>
                    )}
                  />
                </div>

                <div className="w-1/2">
                  <Controller
                    name={`Treatment.${index}.email1`}
                    control={control}
                    rules={{
                      required: "Email 1 is required",
                      pattern: {
                        value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                        message: "Please enter a valid email address"
                      }
                    }}
                    render={({ field: { onChange, value } }) => (
                      <div>
                        <Input label="Email 1*" value={value} onChange={onChange} />
                        {errors.Treatment?.[index]?.email1 && (
                          <p className="text-red-500 text-sm mt-1">
                            {errors.Treatment[index].email1.message}
                          </p>
                        )}
                      </div>
                    )}
                  />

                  <Controller
                    name={`Treatment.${index}.email2`}
                    control={control}
                    rules={{
                      // required: "Email 2 is required",
                      pattern: {
                        value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                        message: "Please enter a valid email address"
                      }
                    }} render={({ field: { onChange, value } }) => (
                      <div>
                        <Input label="Email 2" value={value} onChange={onChange} />
                        {errors.Treatment?.[index]?.email2 && (
                          <p className="text-red-500 text-sm mt-1">
                            {errors.Treatment[index].email2.message}
                          </p>
                        )}
                      </div>
                    )}
                  />

                  <Controller
                    name={`Treatment.${index}.email3`}
                    control={control}
                    rules={{
                      // required: "Email 3 is required",
                      pattern: {
                        value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                        message: "Please enter a valid email address"
                      }
                    }} render={({ field: { onChange, value } }) => (
                      <div>
                        <Input label="Email 3" value={value} onChange={onChange} />
                        {errors.Treatment?.[index]?.email3 && (
                          <p className="text-red-500 text-sm mt-1">
                            {errors.Treatment[index].email3.message}
                          </p>
                        )}
                      </div>
                    )}
                  />

                  <Controller
                    name={`Treatment.${index}.notificationTypeOption`}
                    control={control}
                    rules={{ required: "Notification Type is required" }}
                    render={({ field: { value, onChange } }) => (
                      <div>
                        <Dropdown
                          value={value}
                          onChange={onChange}
                          options={notificationTypeOption}
                          label="Notification Type*"
                        />
                        {errors.Treatment?.[index]?.notificationTypeOption && (
                          <p className="text-red-500 text-sm mt-1">
                            {errors.Treatment[index].notificationTypeOption.message}
                          </p>
                        )}
                      </div>
                    )}
                  />

                  <Controller
                    name={`Treatment.${index}.stateRegistrationNumber1`}
                    control={control}
                    rules={{ required: "State Registration Number 1 is required" }}
                    render={({ field: { onChange, value } }) => (
                      <div>
                        <Input label="State Registration Number 1*" value={value} onChange={onChange} />
                        {errors.Treatment?.[index]?.stateRegistrationNumber1 && (
                          <p className="text-red-500 text-sm mt-1">
                            {errors.Treatment[index].stateRegistrationNumber1.message}
                          </p>
                        )}
                      </div>
                    )}
                  />

                  <Controller
                    name={`Treatment.${index}.stateRegistrationNumber2`}
                    control={control}
                    //rules={{ required: "State Registration Number 2 is required" }}
                    render={({ field: { onChange, value } }) => (
                      <div>
                        <Input label="State Registration Number 2" value={value} onChange={onChange} />
                        {errors.Treatment?.[index]?.stateRegistrationNumber2 && (
                          <p className="text-red-500 text-sm mt-1">
                            {errors.Treatment[index].stateRegistrationNumber2.message}
                          </p>
                        )}
                      </div>
                    )}
                  />
                  <Controller
                    name={`Treatment.${index}.stateRegistrationNumber3`}
                    control={control}
                    //rules={{ required: "State Registration Number 3 is required" }}
                    render={({ field: { onChange, value } }) => (
                      <div>
                        <Input label="State Registration Number 3" value={value} onChange={onChange} />
                        {errors.Treatment?.[index]?.stateRegistrationNumber3 && (
                          <p className="text-red-500 text-sm mt-1">
                            {errors.Treatment[index].stateRegistrationNumber3.message}
                          </p>
                        )}
                      </div>
                    )}
                  />
                </div>
              </div>

            ))}
            <div>
              <div className="mt-12 mb-4">Services Offered</div>
              <div className="border-t"></div>
              <div className="grid gap-5 py-4 mt-2">
                <Controller
                  name={`Treatment.${fields.length - 1}.medicalwaste.treatmentFacility`}
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <Checkbox
                      label="Medical Waste Treatment Facility (TF)"
                      isChecked={value}
                      setIsChecked={onChange}
                    />
                  )}
                />

                <Controller
                  name={`Treatment.${fields.length - 1}.medicalwaste.transferStation`}
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <Checkbox
                      label="Medical Waste Transfer Station (TS)"
                      isChecked={value}
                      setIsChecked={onChange}
                    />
                  )}
                />

                <Controller
                  name={`Treatment.${fields.length - 1}.medicalwaste.paperShredding`}
                  control={control}

                  render={({ field: { value, onChange } }) => (
                    <Checkbox
                      label="Onsite Paper Shredding"
                      isChecked={value}
                      setIsChecked={onChange}
                    />
                  )}
                />
              </div>
            </div>
            <div>
              <div className="mt-12 mb-4">Waste Processing Fees</div>
              <div className="border-t"></div>
              <div className="flex flex-col md:flex-row justify-between text-sm font-medium pl-8 py-4 bg-indigo-50">
                <div className="w-1/5">Fee Type</div>
                <div className="w-1/5">Unit</div>
                <div className="w-1/5">Price</div>
                <div className="w-1/5">Tare</div>
                <div className="w-1/5">Active</div>
              </div>
              <div className="flex justify-around py-2 pl-8 ">
                <div className="w-1/5">
                  <div >Biohazard/Sharps</div>
                  <div className="mt-2">Pharmaceutical</div>
                  <div className="mt-2">Chemotherapy</div>
                  <div className="mt-2">Container Washing</div>
                  <div className="mt-2">Document Fee</div>
                  <div className="mt-2">Energy Fee</div>
                </div>
                <div className="w-1/5">
                  <div className="mt-2">Pound</div>
                  <div className="mt-2">Pound</div>
                  <div className="mt-2">Pound</div>
                  <div className="mt-2">Each</div>
                  <div className="mt-2">Per Transfer</div>
                  <div className="mt-2">Per Transfer</div>
                </div>
                <div className="w-1/5">
                  <div className="mt-2">$ 0.31 </div>
                  <div className="mt-2">$ 1.49</div>
                  <div className="mt-2">$ 1.49</div>
                  <div className="mt-2">$ 2</div>
                  <div className="mt-2">$ 15.75</div>
                  <div className="mt-2">$ 5.25</div>
                </div>
                <div className="w-1/5">
                  <div className="mt-2">N/A</div>
                  <div className="mt-2">N/A</div>
                  <div className="mt-2">N/A</div>
                  <div className="mt-2">8 lb</div>
                  <div className="mt-2">N/A</div>
                  <div className="mt-2">N/A</div>
                </div>
                <div className="w-1/5 mt-2">
                  <div> <Checkbox /></div>
                  <div> <Checkbox /></div>
                  <div> <Checkbox /></div>
                  <div> <Checkbox /></div>
                  <div> <Checkbox /></div>
                  <div> <Checkbox /></div>
                </div>
              </div>
            </div>
            <div>
              <div className="flex-grow overflow-y-auto">
                <div className="text-lg font-normal  my-4 text-black">Transfer Report </div>
                <div className="flex flex-col md:flex-row justify-between text-sm font-medium pl-8 py-4 bg-indigo-50">
                  <div className="w-full md:w-1/5">Date and Time</div>
                  <div className="w-full md:w-1/5">Customer</div>
                  <div className="w-full md:w-1/5">Item Details</div>
                  <div className="w-full md:w-1/5">Pickup Details</div>
                  <div className="w-full md:w-1/5">Options</div>
                </div>

                <div className="flex flex-col md:flex-row items-start py-4 border-b pl-8 border-gray-300">
                  <div className="w-full md:w-1/5 text-sm font-medium">
                    <div>2024-11-15</div>
                    <div className="text-sm font-medium">10:00 AM</div>
                  </div>
                  <div className="w-full md:w-1/5 text-sm font-medium">
                    <div className="text-sm font-medium">Customer 1</div>
                    <div><span className="font-medium">Account:</span> #12345</div>
                    <div className="flex mt-1">
                      <div className="text-sm font-medium items-center">Billed:</div>
                      <div className="ml-4">
                        <Checkbox checked={true} onChange={() => { }} />
                      </div>
                      <div>
                        <input
                          type="text"
                          value={billedinputValue}
                          onChange={handleInputChange}
                          className="ml-2 border-2 border-gray-300 px-2 py-1 w-16 h-6"
                        />
                      </div>
                    </div>
                    <div className="flex mt-1">
                      <div className="text-sm font-medium items-center">Paid:</div>
                      <div className="ml-6">
                        <Checkbox checked={true} onChange={() => { }} />
                      </div>
                      <div>
                        <input
                          type="text"
                          value={paidinputValue}
                          onChange={handleInputChange}
                          className="ml-2 border-2 border-gray-300 px-2 py-1 w-16 h-6"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="w-full md:w-1/5 text-sm font-medium pl-2">
                    <div>Lorem ipsum, adipisicing elit. Dolorem, fugiat. </div>

                  </div>
                  <div className="w-full md:w-1/5 text-sm font-medium">
                    <div>Medical Waste</div>
                    <div><span className="">Driver:</span><span className="ml-4">ABC</span></div>
                    <div><span className="">Truck:</span><span className="ml-5">XYZ</span></div>
                  </div>

                  <div className="w-full md:w-1/5 text-sm font-medium">
                    <div className="mt-2">
                      <div>Download</div>
                      <div>Delete</div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div className="flex justify-end gap-8 py-10">
              <Button
                onClick={handlePreviousBtn}
                btnStyle="form_nav_secondary"
                text={"View TS/TF List"}
              />
              <Button
                type="submit"
                btnStyle="form_nav_primary"
                onClick={handleSubmit(onSubmit)}

                text={"Save"}
              />
            </div>
          </div>
        </div>
      </div>

    </>
  );
};

export default AddTreatment;
