import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
  import { BreadcrumbProvider } from "../../../context/BreadcrumbContext";
import TransportSideBar from "./TransportSideBar";
import TransportHeader from "./TransportHeader";
import Dashboard from "../../admin/dashboard/Dashboard";

const GradientDefinition = () => (
    <svg width="0" height="0" style={{ position: "absolute" }}>
        <defs>
            <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="100%">
                <stop offset="0%" stopColor="#007AFF" />
                <stop offset="100%" stopColor="#4CD964" />
            </linearGradient>
        </defs>
    </svg>
);

const Layout = () => {
    const [active, setActive] = useState(null);
    const scrollContainerRef = useRef(null);
    const location = useLocation();
    const dispatch = useDispatch();

    useEffect(() => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollTo({
                top: 0,
                behavior: "smooth",
            });
        }
    }, [location.pathname]);

    return (
        <BreadcrumbProvider>
            <div className="">
                <GradientDefinition />
                <TransportSideBar active={active} />
                <div className={`${location.pathname == "/satellites" ? "overflow-scroll" : "ml-72"}`}>
                    <TransportHeader />
                </div>
            </div>
        </BreadcrumbProvider>
    );
};

export default Layout;
