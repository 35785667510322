import React, { useRef, useEffect } from "react";
import Chart from "chart.js/auto";

const data = [
	{ month: "Jan", revenue: 18000 },
	{ month: "Feb", revenue: 7000 },
	{ month: "Mar", revenue: 14000 },
	{ month: "Apr", revenue: 20000 },
	{ month: "May", revenue: 14000 },
	{ month: "Jun", revenue: 25000 },
	{ month: "Jul", revenue: 28000 },
	// { month: "Aug", revenue: 26000 },
	// { month: "Sep", revenue: 22000 },
	// { month: "Oct", revenue: 32000 },
	// { month: "Nov", revenue: 29000 },
	// { month: "Dec", revenue: 24000 },
];

const ChartjsRevenueAreaChart = () => {
	const chartRef = useRef(null);
	const chartInstance = useRef(null);

	useEffect(() => {
		if (chartInstance.current) {
			chartInstance.current.destroy();
		}

		const ctx = chartRef.current.getContext("2d");

		// Create gradient
		const gradientFill = ctx.createLinearGradient(0, 0, 0, 400);
		gradientFill.addColorStop(0, "rgba(0, 122, 255, 0.19)");
		gradientFill.addColorStop(1, "rgba(76, 217, 100, 0.0)");

		chartInstance.current = new Chart(ctx, {
			type: "line",
			data: {
				labels: data.map((item) => item.month),
				datasets: [
					{
						label: "Revenue",
						data: data.map((item) => item.revenue),
						fill: true,
						backgroundColor: gradientFill,
						borderColor: "#3B82F6",
						borderWidth: 2.5,
						pointBackgroundColor: "#FFFFFF",
						pointBorderColor: "#3B82F6",
						pointBorderWidth: 3,
						pointRadius: 6,
						pointHoverRadius: 8,
						tension: 0,
					},
				],
			},
			options: {
				responsive: true,
				maintainAspectRatio: false,
				plugins: {
					legend: {
						display: false,
					},
					tooltip: {
						backgroundColor: "black",
						titleColor: "white",
						bodyColor: "white",
						bodyFont: {
							size: 14,
						},
						padding: 12,
						cornerRadius: 15,
						displayColors: false,
						callbacks: {
							title: () => "",
							label: (context) => `$${context.parsed.y.toLocaleString()}`,
						},
					},
				},
				scales: {
					x: {
						grid: {
							display: false,
						},
						ticks: {
							color: "#9CA3AF",
						},
					},
					y: {
						beginAtZero: true,
						grid: {
							color: "#F3F4F6",
						},
						ticks: {
							color: "#9CA3AF",
							callback: (value) => `${value / 1000}K`,
						},
					},
				},
				elements: {
					line: {
						tension: 0,
					},
					point: {
						radius: 24,
						hoverRadius: 30,
					},
				},
			},
		});

		return () => {
			if (chartInstance.current) {
				chartInstance.current.destroy();
			}
		};
	}, []);

	return <canvas ref={chartRef}></canvas>;
};

export default ChartjsRevenueAreaChart;
