import React, { useState, useEffect, useRef } from "react";
import { IoIosArrowDown } from "react-icons/io";
import { useLocation, useNavigate } from "react-router-dom";
import MiniRegistrationModal from "./MiniRegistrationModal";
import { AnimatePresence, motion } from "framer-motion";
import MiniDriverRegistrationForm from "./MiniDriverRegModal";
import MiniVendorRegModal from "./userAccBtn/MiniVendorRegModal";
import MiniTreatmentRegModal from "./userAccBtn/MiniTreatmentRegModal";
import MiniTransporterRegModal from "./userAccBtn/MiniTransporterRegModal";

const AddNewBtnBar = () => {
	const navigate = useNavigate();
	const [isSubmenuOpen, setIsSubmenuOpen] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const menuRef = useRef(null);
	const location = useLocation();
	const [activeSubmenu, setActiveSubmenu] = useState("generator");
	const [WchModalOpen, setWchModalOpen] = useState("");

	const toggleSubmenu = () => setIsSubmenuOpen(!isSubmenuOpen);

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (menuRef.current && !menuRef.current.contains(event.target)) {
				setIsSubmenuOpen(false);
			}
		};
		document.addEventListener("mousedown", handleClickOutside);
		return () => document.removeEventListener("mousedown", handleClickOutside);
	}, []);

	const clickHandler = (submenu) => {
		setActiveSubmenu(submenu);
		setIsSubmenuOpen(false);

		if (submenu === "Vendor") {
			setWchModalOpen("vendor");
		} else if (submenu === "driver") {
			setWchModalOpen("driver");
		} else if (submenu === "treatment") {
			setWchModalOpen("treatment");
		}
		else if (submenu === "transport") {
			setWchModalOpen("transport");
		}
		else {
			setWchModalOpen("generator");
		}
	};

	return (
		<div className="relative flex flex-col gap-4 justify-end py-4" ref={menuRef}>
			<button className="bg-dashBtnGradient py-2.5 px-7 w-36 xl:w-40 h-12 flex justify-between hover:opacity-90 items-center rounded-full text-white" onClick={toggleSubmenu}>
				<h6>Add New</h6>
				<IoIosArrowDown size={20} className={`font-bold transform transition-transform duration-200 ${isSubmenuOpen ? "rotate-180" : "rotate-0"}`} />
			</button>
			<AnimatePresence>
				{isSubmenuOpen && (
					<motion.ul
						className={`absolute z-50 top-3/4 mt-2 min-w-full w-fit border border-borderCol rounded-xl overflow-hidden shadow-lg`}
						initial={{ height: 0, opacity: 0 }}
						animate={{ height: "auto", opacity: 1 }}
						exit={{ height: 0, opacity: 0 }}
						transition={{ duration: 0.25 }}
					>
						<li className="pl-3 py-1.5 pr-10 bg-white cursor-pointer hover:text-white hover:bg-lightBlue" onClick={() => clickHandler("generator")}>
							Generator
						</li>
						<li className="pl-3 py-1.5 pr-10 bg-white cursor-pointer hover:text-white hover:bg-lightBlue" onClick={() => clickHandler("driver")}>
							Driver
						</li>
						<li className="pl-3 py-1.5 pr-10 bg-white cursor-pointer hover:text-white hover:bg-lightBlue" onClick={() => clickHandler("route")}>
							Route
						</li>
						<li className="pl-3 py-1.5 pr-10 bg-white cursor-pointer hover:text-white hover:bg-lightBlue" onClick={() => clickHandler("Vendor")}>
							Vendor
						</li>
						<li className="pl-3 py-1.5 pr-10 bg-white cursor-pointer hover:text-white hover:bg-lightBlue" onClick={() => clickHandler("treatment")}>
							Treatment & Transfer
						</li>
						<li className="pl-3 py-1.5 pr-3 bg-white text-nowrap cursor-pointer hover:text-white hover:bg-lightBlue" onClick={() => clickHandler("transport")}>
							Satellite Location
						</li>
					</motion.ul>
				)}
			</AnimatePresence>
			{WchModalOpen === "generator" && <MiniRegistrationModal onClose={() => setWchModalOpen("")} activeSubmenu={activeSubmenu} />}
			{WchModalOpen === "driver" && <MiniDriverRegistrationForm onClose={() => setWchModalOpen("")} activeSubmenu={activeSubmenu} />}
			{WchModalOpen === "vendor" && <MiniVendorRegModal onClose={() => setWchModalOpen("")} activeSubmenu={activeSubmenu} />}
			{WchModalOpen === "treatment" && <MiniTreatmentRegModal onClose={() => setWchModalOpen("")} activeSubmenu={activeSubmenu} />}
			{WchModalOpen === "transport" && <MiniTransporterRegModal onClose={() => setWchModalOpen("")} activeSubmenu={activeSubmenu} />}
		</div>
	);
};

export default AddNewBtnBar;
