import { createContext, useState, useContext } from "react";

const BreadcrumbContext = createContext();

export const BreadcrumbProvider = ({ children }) => {
	const [breadcrumbData, setBreadcrumbData] = useState({
		type: "", // 'generator', 'driver', 'vendor'
		id: "", // octoNumber, driverId, vendorId
		steps: {
			"Generator Profile": true,
			"Generator Contacts": true,
			"Service Notes": false,
			Routes: false,
		},
	});

	const handleSetBreadcrumb = (type, id) => {
		setBreadcrumbData((prev) => ({ ...prev, type, id }));
	};

	const handleStepCompletion = (step, isCompleted) => {
		setBreadcrumbData((prev) => ({
			...prev,
			steps: { ...prev.steps, [step]: isCompleted },
		}));
	};

	return <BreadcrumbContext.Provider value={{ breadcrumbData, handleSetBreadcrumb, handleStepCompletion }}>{children}</BreadcrumbContext.Provider>;
};

export const useBreadcrumb = () => {
	return useContext(BreadcrumbContext);
};
