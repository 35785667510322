import React, { useState, useEffect, useMemo, useCallback } from "react";
import ProgressBar from "./components/ProgressBar";
import DateRangePicker from "../../../components/UI/DateRangePicker";
import Dropdown from "../../../components/UI/dropdowns/Dropdown";
import Wastetable from "./components/Wastetable";
import MapWithRoutes from "./components/MapWithRoutes";
import { useNavigate } from "react-router-dom";
import {
	getAllRoutes,
	getAllGenerators,
	getAllVendor,
	getDrivers,
	getAllTreatment,
} from "../../../utils/firebaseOperations";
import { serviceTypes, generatorStatus } from "../../../utils/constants";

const WasteManagement = () => {
	const [filters, setFilters] = useState({
		generators: "",
		serviceType: "",
		startDate: null,
		endDate: null,
	});
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [apiData, setApiData] = useState({
		routes: [],
		generators: [],
		vendor: [],
		drivers: [],
		treatment: [],
	});

	console.log("apiData", apiData);

	const navigate = useNavigate();

	// Fetch all necessary data from the APIs when the component loads
	useEffect(() => {
		const fetchData = async () => {
			// setLoading(true);
			// try {
			// 	const [routes, generators, vendor, drivers, treatment] = await Promise.all([
			// 		getAllRoutes(),
			// 		getAllGenerators(),
			// 		getAllVendor(),
			// 		getDrivers(),
			// 		getAllTreatment(),
			// 	]);
			// 	const generatorOptions = generators
			// 		.filter((gen) => gen.generatorName && gen.generatorName.trim() !== "" && gen.id >= 0)
			// 		.map((gen) => ({
			// 			label: gen.generatorName,
			// 			value: gen.id,
			// 		}));
			// 	setApiData({
			// 		routes,
			// 		generators: generatorOptions,
			// 		vendor,
			// 		drivers,
			// 		treatment,
			// 	});
			// } catch (error) {
			// 	console.error("Error fetching data:", error);
			// } finally {
			// 	setLoading(false);
			// }
		};

		fetchData();
	}, []);

	// Use dummy data for table (assuming API data will replace this later)
	useEffect(() => {
		const generateDummyData = () => {
			const dummyData = Array.from({ length: 10 }, (_, index) => ({
				id: index + 1,
				date: `2024-10-${index + 1}`,
				time: "12:00 PM",
				generatorName: `Generator ${index + 1}`,
				serviceType: index % 2 === 0 ? "Type A" : "Type B",
			}));
			setData(dummyData);
		};
		generateDummyData();
	}, []);

	// Memoized callback for progress bar click
	const handleProgressClick = useCallback(() => {
		console.log("Progress bar clicked");
	}, []);

	// Memoized filter handler to avoid re-renders
	const handleFilter = useCallback(() => {
		console.log("Filter button clicked", filters);
	}, [filters]);

	const handleRowClick = useCallback((id) => {
		console.log(`Row clicked with ID: ${id}`);
	}, []);

	const handleDeleteRow = useCallback((id) => {
		console.log(`Row clicked with ID: ${id}`);
	}, []);

	const handleSaveAsPDF = useCallback((id) => {
		console.log(`Save PDF clicked with ID: ${id}`);
	}, []);

	const handlePreviousBtn = () => {
		navigate("/admin");
	};

	const handleNextClick = useCallback(() => {
		navigate("/admin/wastes/wastefinder");
	}, []);

	// Generator options for Dropdown memoized
	const generatorOptions = useMemo(() => {
		console.log("apiData.generators", apiData.generators);

		return apiData.generators.length > 0 ? apiData.generators : generatorStatus;
	}, [apiData.generators]);

	//   const routes = useMemo(() => apiData.routes, [apiData.routes]);

	const garageLocation = { latitude: 34.0522, longitude: -118.2437 };

	const routes = [
		{
			name: "Route 1",
			startLocation: garageLocation,
			endLocation: garageLocation,
			stops: [
				{ lat: 34.0522, lng: -118.2937 }, // West (5 miles)
				{ lat: 34.1022, lng: -118.2937 }, // Northwest (10 miles)
				{ lat: 34.1022, lng: -118.2437 }, // North (10 miles)
				{ lat: 34.0522, lng: -118.2437 }, // East (5 miles)
				{ lat: 34.0522, lng: -118.1937 }, // South (5 miles)
				{ lat: 34.0022, lng: -118.1937 }, // Southeast (10 miles)
				{ lat: 34.0022, lng: -118.2437 }, // South (10 miles)
				{ lat: 34.0522, lng: -118.2437 }, // Return to garage
			],
		},
		{
			name: "Route 2",
			startLocation: garageLocation,
			endLocation: garageLocation,
			stops: [
				{ lat: 34.0522, lng: -118.3437 }, // West (5 miles)
				{ lat: 34.1022, lng: -118.3437 }, // Northwest (10 miles)
				{ lat: 34.1022, lng: -118.2937 }, // North (10 miles)
				{ lat: 34.0522, lng: -118.2937 }, // East (5 miles)
				{ lat: 34.0522, lng: -118.2437 }, // South (5 miles)
				{ lat: 34.0022, lng: -118.2437 }, // Southeast (10 miles)
				{ lat: 34.0022, lng: -118.2937 }, // South (10 miles)
				{ lat: 34.0522, lng: -118.2437 }, // Return to garage
			],
		},
		{
			name: "Route 3",
			startLocation: garageLocation,
			endLocation: garageLocation,
			stops: [
				{ lat: 34.0522, lng: -118.3937 }, // West (5 miles)
				{ lat: 34.1022, lng: -118.3937 }, // Northwest (10 miles)
				{ lat: 34.1022, lng: -118.3437 }, // North (10 miles)
				{ lat: 34.0522, lng: -118.3437 }, // East (5 miles)
				{ lat: 34.0522, lng: -118.2937 }, // South (5 miles)
				{ lat: 34.0022, lng: -118.2937 }, // Southeast (10 miles)
				{ lat: 34.0022, lng: -118.3437 }, // South (10 miles)
				{ lat: 34.0522, lng: -118.2437 }, // Return to garage
			],
		},
		{
			name: "Route 4",
			startLocation: garageLocation,
			endLocation: garageLocation,
			stops: [
				{ lat: 34.0522, lng: -118.4437 }, // West (5 miles)
				{ lat: 34.1022, lng: -118.4437 }, // Northwest (10 miles)
				{ lat: 34.1022, lng: -118.3937 }, // North (10 miles)
				{ lat: 34.0522, lng: -118.3937 }, // East (5 miles)
				{ lat: 34.0522, lng: -118.3437 }, // South (5 miles)
				{ lat: 34.0022, lng: -118.3437 }, // Southeast (10 miles)
				{ lat: 34.0022, lng: -118.3937 }, // South (10 miles)
				{ lat: 34.0522, lng: -118.2437 }, // Return to garage
			],
		},
		{
			name: "Route 5",
			startLocation: garageLocation,
			endLocation: garageLocation,
			stops: [
				{ lat: 34.0522, lng: -118.4937 }, // West (5 miles)
				{ lat: 34.1022, lng: -118.4937 }, // Northwest (10 miles)
				{ lat: 34.1022, lng: -118.4437 }, // North (10 miles)
				{ lat: 34.0522, lng: -118.4437 }, // East (5 miles)
				{ lat: 34.0522, lng: -118.3937 }, // South (5 miles)
				{ lat: 34.0022, lng: -118.3937 }, // Southeast (10 miles)
				{ lat: 34.0022, lng: -118.4437 }, // South (10 miles)
				{ lat: 34.0522, lng: -118.2437 }, // Return to garage
			],
		},
	];

	return (
		<div className="p-6">
			<div className="main-container flex flex-col border border-cardBorderCol bg-white rounded-cardRadii h-full">
				<div className="text-lg font-medium px-8 p-6 pb-2 border-b border-cardTextGray border-opacity-40">
					Waste Management Overview
				</div>

				<div className="px-8 py-4 relative">
					<div className="text-lg font-medium py-2 pb-4">View Map</div>
					<MapWithRoutes routes={[]} isLoading={false} />
				</div>

				<div className="px-8 py-10">
					<ProgressBar
						Labelone="Types of Waste"
						Labeltwo="Total waste collected"
						Labelthree="Facilities used"
						completed={20}
						scheduled={74}
						incompleted={72}
						onClick={handleProgressClick}
						isLoading={loading}
					/>
				</div>

				<div className="text-lg font-medium mx-8 pb-2 border-b-2 border-cardTextGray border-opacity-40">
					Customizing Reports
				</div>

				<div className="flex items-end py-[2%] gap-6 px-8">
					<div className="w-56">
						<p className="text-cardTextGray">Date</p>
						<DateRangePicker
							startDate={filters.startDate}
							endDate={filters.endDate}
							setStartDate={(date) => setFilters((prev) => ({ ...prev, startDate: date }))}
							setEndDate={(date) => setFilters((prev) => ({ ...prev, endDate: date }))}
						/>
					</div>

					<Dropdown
						label="Generator"
						options={generatorOptions}
						styles="flex-col gap-1 w-56"
						margin={"0"}
						value={filters.generators}
						onChange={(value) => setFilters((prev) => ({ ...prev, generators: value }))}
					/>

					<Dropdown
						label="Service type"
						options={serviceTypes}
						styles="flex-col gap-1 w-56"
						margin={"0"}
						value={filters.serviceType}
						onChange={(value) => setFilters((prev) => ({ ...prev, serviceType: value }))}
					/>

					<button
						onClick={handleFilter}
						style={{ width: "150px", height: "36px" }}
						className="bg-lightBlue text-white rounded-full hover:bg-primaryBlue hover:border-[#666666] transition-all duration-300"
					>
						View
					</button>
				</div>
				<div className="text-lg font-medium px-8 py-2">Results</div>
				<Wastetable
					loading={loading}
					data={data}
					handleRowClick={handleRowClick}
					handleDelete={handleDeleteRow}
					handleSaveAsPDF={handleSaveAsPDF}
				/>

				<div className="flex justify-end p-4 px-6 mb-12 mt-12">
					<button
						onClick={handlePreviousBtn}
						style={{ width: "184px", height: "44px" }}
						className="bg-[#007AFF] text-white px-4 py-2 rounded-full mr-4 border border-[#666666] hover:bg-white hover:text-black hover:border-[#666666] transition-opacity duration-300"
					>
						Previous
					</button>
					<button
						onClick={handleNextClick}
						style={{ width: "184px", height: "44px" }}
						className="bg-[#007AFF] text-white px-4 py-2 rounded-full border border-[#666666] hover:bg-white hover:text-black hover:border-[#666666] transition-opacity duration-300"
					>
						Next
					</button>
				</div>
			</div>
		</div>
	);
};

export default WasteManagement;
