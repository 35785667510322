import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { deleteBatchItemsInFirestore, getAllGenerators } from "../../utils/firebaseOperations";
import { toast } from "react-toastify";

const initialState = {
	list: [],
	loading: false,
	error: null,
};

//Async Thunks

export const fetchGenerator = createAsyncThunk("generator/fetchGenerator", async () => {
	try {
		const generators = await getAllGenerators();
		return generators;
	} catch (error) {
		console.log(error);
		return [];
	}
});

export const deleteGenerator = createAsyncThunk("generator/deleteGenerator", async (generatorsIds) => {
	try {
		await deleteBatchItemsInFirestore("generators", generatorsIds);
		return generatorsIds;
	} catch (error) {
		console.log(error);
	}
});

export const generatorSlice = createSlice({
	name: "generator",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(fetchGenerator.fulfilled, (state, action) => {
				state.loading = false;
				state.list = action.payload;
			})
			.addCase(fetchGenerator.pending, (state) => {
				state.loading = true;
			})
			.addCase(fetchGenerator.rejected, (state, action) => {
				state.loading = false;
				state.error = action.error.message;
			})
			.addCase(deleteGenerator.pending, () => {
				toast.loading("Deleting..");
			})
			.addCase(deleteGenerator.fulfilled, (state, action) => {
				console.log({ actionPayload: action.payload });
				toast.dismiss();
				toast.success("Deleted successfully!");
				state.list = state.list.filter((generator) => !action.payload.includes(generator.id));
			});
	},
});

export const allGenerators = (state) => state.generator.list;

export default generatorSlice.reducer;
