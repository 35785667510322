import React, { useState } from "react";
import Dropdown from "../../../components/UI/dropdowns/Dropdown";
import WeightTable from "./components/WeightTable";
import { useNavigate } from "react-router-dom";

const WeighingInterface = () => {
	const [serialNumber, setSerialNumber] = useState("");
	const [Weight, setWeight] = useState("");
	const navigate = useNavigate();

	const [data, setData] = useState([
		{ id: 1, date: "2024-10-05", time: "10:30 AM", scanContainer: "Container 1", weight: "10kg", number: "123" },
		{ id: 2, date: "2024-10-06", time: "11:45 AM", scanContainer: "Container 2", weight: "15kg", number: "124" },
		{ id: 3, date: "2024-10-07", time: "02:15 PM", scanContainer: "Container 3", weight: "20kg", number: "125" },
		{ id: 4, date: "2024-10-08", time: "01:00 PM", scanContainer: "Container 4", weight: "12kg", number: "126" },
	]);

	const [loading, setLoading] = useState(false);

	const handleRowClick = (id) => {
		console.log(`Row with ID ${id} clicked!`);
	};

	const octoOptions = [
		{ value: 1, label: "78" },
		{ value: 2, label: "79" },
		{ value: 3, label: "80" },
	];

	const generatorOptions = [
		{ value: "generator1", label: "Generator 1" },
		{ value: "generator2", label: "Generator 2" },
		{ value: "generator3", label: "Generator 3" },
	];

	const handleClear = () => {
		setSerialNumber("");
		setWeight("");
	};

	const handleSearch = () => console.log("handle search");
	const handlePreviousBtn = () => {
		navigate("/admin/wastes/boxlocations");
	};
	const handleNextClick = () => {
		navigate("/admin/wastes/service-ticket");
	};

	return (
		<div className="p-6">
			<div className="main-container flex flex-col border border-cardBorderCol bg-white rounded-cardRadii h-full">
				<div className="text-lg font-medium mx-8 pt-6 pb-2 border-b border-cardTextGray border-opacity-40">
					Container Weighing Interface
				</div>
				<div className="grid gap-x-10 gap-y-2 grid-cols-2 py-4 px-8 items-center justify-center">
					<Dropdown
						options={octoOptions}
						label={"Serial Number"}
						margin="1"
						value={serialNumber}
						onChange={setSerialNumber}
					/>
					<Dropdown options={generatorOptions} label={"State"} margin="1" value={Weight} onChange={setWeight} />
				</div>

				<div className="flex justify-end px-8 py-4 gap-2 text-sm">
					<button
						onClick={handleClear}
						style={{ width: "130px", height: "28px" }}
						className="bg-whiteBlue px-8 rounded-full hover:bg-lightBlue hover:text-white transition-all duration-300"
					>
						Clear All
					</button>
					<button
						onClick={handleSearch}
						style={{ width: "130px", height: "28px" }}
						className="bg-lightBlue text-white px-4 rounded-full hover:bg-primaryBlue transition-all duration-300"
					>
						Search
					</button>
				</div>

				<div className="text-lg font-medium px-8 py-2">Scan Log</div>

				<WeightTable loading={loading} data={data} handleRowClick={handleRowClick} />

				<div className="flex justify-end p-4 mb-12 mt-12">
					<button
						onClick={handlePreviousBtn}
						style={{ width: "184px", height: "44px" }}
						className="bg-[#007AFF] text-white px-4 py-2 rounded-full mr-4 border border-[#666666] hover:bg-white hover:text-black hover:border-[#666666] transition-opacity duration-300"
					>
						Previous
					</button>
					<button
						onClick={handleNextClick}
						style={{ width: "184px", height: "44px" }}
						className="bg-[#007AFF] text-white px-4 py-2 rounded-full border border-[#666666] hover:bg-white hover:text-black hover:border-[#666666] transition-opacity duration-300"
					>
						Next
					</button>
				</div>
			</div>
		</div>
	);
};

export default WeighingInterface;
