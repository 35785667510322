import React, { useEffect, useRef, useState } from "react";
import { BiPlus } from "react-icons/bi";

const UrlInput = ({
  id,
  label = "Website URL",
  value,
  placeholder = "https://www.example.com",
  onChange,
  margin = "mt-4",
  style = null,
  isDisabled = false,
  type = "",
  onClick,
  IsRequired = false
}) => {
  const inputRef = useRef(null);
  const [isCtrlPressed, setIsCtrlPressed] = useState(false);

  useEffect(() => {
    const input = inputRef.current;
    if (input) {
      input.addEventListener("animationstart", (e) => {
        if (e.animationName === "onAutoFillStart") {
          e.target.classList.add("auto-filled");
        }
      });
      input.addEventListener("animationcancel", (e) => {
        if (e.animationName === "onAutoFillCancel") {
          e.target.classList.remove("auto-filled");
        }
      });
    }
  }, []);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "Control") {
        setIsCtrlPressed(true);
      }
    };

    const handleKeyUp = (e) => {
      if (e.key === "Control") {
        setIsCtrlPressed(false);
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  const handleChange = (e) => {
    const url = e.target.value;
    onChange(url);
  };

  const clickHandler = (e) => {
    if (isCtrlPressed && value) {
      setIsCtrlPressed(false);
      window.open(value.startsWith('http://') || value.startsWith('https://') ? value : `http://${value}`, '_blank');
    }
  };

  return (
    <div className={`relative flex ${style ?? "items-center justify-between"} ${margin}`}>
      {label && (
        <label htmlFor={id} className={`${style ? "w-full" : label ? "w-1/3" : "w-full"} text-inputLabel font-normal`}>
          {label} {IsRequired ? '*' : ""}
        </label>
      )}
      <input
        type="url"
        ref={inputRef}
        id={id}
        value={value}
        onChange={handleChange}
        placeholder={placeholder}
        disabled={isDisabled}
        onClick={clickHandler}
        className={`
          ${isCtrlPressed && value ? "hover:cursor-pointer hover:underline hover:text-blueText" : "hover:cursor-text"}
          ${style ? "w-full" : label ? "w-2/3" : "w-full"}
          disabled:bg-white 
          text-cardTextGray 
          bg-inputBg 
          border-none 
          rounded-full 
          py-2 
          h-9 
          px-4 
          leading-tight 
          focus:outline-none  
          focus:ring-1 
          focus:ring-dashInActiveBtnText
          [&:-webkit-autofill]:bg-inputBg!
          [&:-webkit-autofill:hover]:bg-inputBg!
          [&:-webkit-autofill:focus]:bg-inputBg!
          [&:-webkit-autofill:active]:bg-inputBg!
          [&.auto-filled]:bg-inputBg!
          [-webkit-text-fill-color:inherit]!
        `}
      />
      {type === "multi" && <BiPlus className="absolute top-2 cursor-pointer right-4 text-cardTextGray" size={20} onClick={onClick} />}
    </div>
  );
};

export default UrlInput;
