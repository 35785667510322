import React from "react";

const NotificationItem = ({ allNotification, markAsRead }) => {
	return (
		<ul className="divide-y divide-gray-200">
			{allNotification.map((notif, i) => (
				<li key={notif.id} className={`py-2 px-4 ${i % 2 === 0 ? "bg-primaryGray" : "bg-white"}`}>
					<p onClick={() => markAsRead(notif.id)} className={`cursor-pointer ${notif.unread ? "font-bold text-gray-800" : "font-normal text-gray-600"} hover:text-blue-500`}>
						{notif.message}
					</p>
				</li>
			))}
		</ul>
	);
};

export default NotificationItem;
