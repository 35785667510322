import { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import Input from "../../../../../../components/UI/Input";
import Dropdown from "../../../../../../components/UI/dropdowns/Dropdown";
import Button from "../../../../../../components/UI/Button";
import { useNavigate, useParams } from "react-router-dom";
import RouteSetupMap from "./RouteSetupMap";
import {
	showErrorToastMessage,
	showLoadingToastMessage,
	showSuccessToastMessage,
	splitArrayIntoChunks,
} from "../../../../../../utils/helpers";
import {
	addDoc,
	collection,
	deleteDoc,
	doc,
	documentId,
	FieldPath,
	FieldValue,
	getDoc,
	getDocs,
	onSnapshot,
	orderBy,
	query,
	serverTimestamp,
	setDoc,
	where,
} from "firebase/firestore";
import { COLLECTIONS, db } from "../../../../../../config/firebase";
import Loader from "../../../../../../components/UI/loaders/Loader";
import { getAllGeneratorsLocationSnapshot, getAllRoutesPathData } from "../../../../../../utils/firebaseOperations";
import Checkbox from "../../../../../../components/UI/Checkbox";
import MultiSelectRounded from "../../../../../../components/UI/dropdowns/MultiSelectRounded";
import { serviceTypes } from "../../../../../../utils/constants";
import { Unsubscribe } from "@mui/icons-material";

const AddRouteForm = () => {
	const { id } = useParams();
	const [isedit, setIsEdit] = useState(false);
	const [loading, setLoading] = useState(false);
	const [routeTypes, setRouteTypes] = useState([{ label: "Dummy Type", value: "dummy" }]);
	const [title, setTitle] = useState("New Route Form");
	const [start, setStart] = useState(null);
	const [end, setEnd] = useState(null);
	const [path, setPath] = useState([]);
	const [otherPaths, setOtherPaths] = useState([]);
	const [pastService, setPastService] = useState([]);
	const [upcomingService, setUpcomingService] = useState([]);
	const [waypoints, setWaypoints] = useState([]);
	const [otherGeneratorMarkers, setOtherGeneratorMarkers] = useState([]);
	useEffect(() => {
		const unsubscribe = getAllGeneratorsLocationSnapshot(setOtherGeneratorMarkers);

		return () => {
			if (unsubscribe) unsubscribe();
		};
	}, []);

	useEffect(() => {
		console.log({ otherGeneratorMarkers });
	}, [otherGeneratorMarkers]);

	useEffect(() => {
		if (id) {
			setTitle("Edit Route");
			fetchData();
		} else {
			reset({
				routeLabel: "",
				type: [],
				defaultStartLocation: "",
				defaultEndLocation: "",
				truckCostPerMile: "",
				isActive: false,
			});
		}
	}, [id, setTitle]);
	const fetchData = async () => {
		try {
			setLoading(true);
			const dataRes = await getDoc(doc(db, COLLECTIONS.routes, id));

			if (!dataRes.exists()) {
				throw new Error("Data Not found");
			}
			console.log(dataRes.data());

			const { defaultEndLocation, defaultStartLocation, routeLabel, type, truckCostPerMile, isActive, waypoints } =
				dataRes.data();
			setValue("routeLabel", routeLabel);
			setValue("truckCostPerMile", truckCostPerMile);
			setValue("truckCostPerMile", truckCostPerMile);
			setEnd(defaultEndLocation);
			setStart(defaultStartLocation);
			setWaypoints(waypoints);
			setValue("isActive", Boolean(isActive));
			if (Array.isArray(type)) {
				setValue("type", type);
			} else {
				setValue("type", []);
			}
			setLoading(false);
			showSuccessToastMessage("Form data loaded.Plotting routes on map.(It may take a while please hold on.)");
			const pathDataRes = await getDocs(query(collection(db, COLLECTIONS.routes, id, "path"), orderBy("index", "asc")));
			if (pathDataRes.docs.length > 0) {
				let tempPaths = [];
				for (const el of pathDataRes.docs) {
					const { path, index } = el.data();

					tempPaths.push(...path);
				}

				setPath(tempPaths);
			} else {
				throw new Error("No path found for this route. Please setup path for this route.");
			}
			const allRoutePathData = await getAllRoutesPathData(id);
			console.log({ allRoutePathData });
			setOtherPaths(allRoutePathData);
		} catch (error) {
			console.log({ error });
			showErrorToastMessage(error.message);
		} finally {
			setLoading(false);
		}
	};
	useEffect(() => {
		setRouteTypes({});
	}, [id, setTitle]);

	const {
		handleSubmit,
		control,
		setValue,
		watch,
		reset,
		formState: { errors },
	} = useForm({
		defaultValues: {
			routeLabel: "",
			type: [],
			defaultStartLocation: {
				name: "",
			},
			defaultEndLocation: {
				name: "",
			},
			truckCostPerMile: "",
			isActive: false,
		},
	});

	useEffect(() => {
		if (end) {
			setValue("defaultEndLocation", end);
		} else {
			setValue("defaultEndLocation", {
				name: "",
			});
		}
	}, [end, setValue]);
	useEffect(() => {
		if (start) {
			setValue("defaultStartLocation", start);
		} else {
			setValue("defaultStartLocation", {
				name: "",
			});
		}
	}, [start, setValue]);

	const navigate = useNavigate();

	const onSubmit = async (data) => {
		console.log({ submitData: data });

		if (!start) {
			showErrorToastMessage("Please set up start location.");
			return;
		}
		if (!end) {
			showErrorToastMessage("Please set up end location.");
			return;
		}
		if (!path.length) {
			showErrorToastMessage("Please plot a route from start to end.");
			return;
		}
		showLoadingToastMessage("Saving...");
		try {
			setLoading(true);
			if (!id) {
				const routeDocREf = await addDoc(collection(db, "routes"), {
					...data,
					createdAt: serverTimestamp(),
					updatedAt: serverTimestamp(),
				});
				if (path.length > 0) {
					const pathChunks = splitArrayIntoChunks(path, 10000);
					pathChunks.forEach((chunk, i) => {
						addDoc(collection(db, "routes", routeDocREf.id, "path"), {
							index: i,
							path: chunk,
						});
					});
				}

				showSuccessToastMessage("Route created successfully.");
			} else {
				await setDoc(
					doc(db, COLLECTIONS.routes, id),
					{
						...data,
						updatedAt: serverTimestamp(),
					},
					{ merge: true }
				);
				const pathRes = await getDocs(collection(db, COLLECTIONS.routes, id, "path"));
				for (const el of pathRes.docs) {
					await deleteDoc(el.ref);
				}
				if (path.length > 0) {
					const pathChunks = splitArrayIntoChunks(path, 10000);

					pathChunks.forEach((chunk, i) => {
						let data = {
							index: i,
							path: chunk,
						};

						addDoc(collection(db, COLLECTIONS.routes, id, "path"), data);
					});
				}
				showSuccessToastMessage("Route updated successfully.");
			}
		} catch (error) {
			console.error("Error during submission:", error);
			showErrorToastMessage("An error occurred during submission. Internal Server Error.");
		} finally {
			setLoading(false);
			handleBack();
		}
	};

	const handleBack = () => navigate("/admin/assets/routes");
	if (loading) {
		return <Loader />;
	}
	return (
		<form onSubmit={handleSubmit(onSubmit)} className="bg-white p-8 m-6 py-2 flex flex-col rounded-cardRadii flex-grow">
			<div className="font-medium flex items-center gap-4 text-lg py-2 border-b border-cardTextGray border-opacity-40 text-black">
				<h6>{title}</h6>
				<Controller
					name="isActive"
					control={control}
					render={({ field: { onChange, value } }) => (
						<Checkbox label="" type="secondary" isChecked={value} setIsChecked={(isChecked) => onChange(isChecked)} />
					)}
				/>{" "}
				(Route Active)
			</div>

			<div className="w-full h-96 my-4">
				<RouteSetupMap
					setStart={setStart}
					setEnd={setEnd}
					start={start}
					end={end}
					path={path}
					setPath={setPath}
					otherPaths={otherPaths}
					waypoints={waypoints}
					otherMarkers={otherGeneratorMarkers}
				/>
			</div>

			<div className="grid gap-x-8 gap-y-2 grid-cols-2 mb-8">
				<div className="flex flex-col space-y-4">
					<div className="flex items-center justify-between col-span-1">
						<label htmlFor={"name"} className="text-inputLabel font-normal mt-3 w-32">
							Route Name*
						</label>

						<div className="w-2/3">
							<Controller
								name="routeLabel"
								control={control}
								rules={{ required: "Route Name is required" }}
								render={({ field }) => {
									return <Input {...field} margin={"mt-4 mb-0"} />;
								}}
							/>
							{errors.routeLabel && <p className="text-red-500 text-sm mt-1">{errors.routeLabel.message}</p>}
						</div>
					</div>
					<Controller
						name="defaultStartLocation.name"
						control={control}
						rules={{ required: "Default Start Location is required" }}
						render={({ field: { onChange, value } }) => {
							return <Input label="Default Start Location" value={value} onChange={() => {}} isRequired={true} />;
						}}
					/>
					{errors.defaultStartLocation?.name && (
						<p className="text-red-500 text-sm mt-1">{errors.defaultStartLocation?.name?.message}</p>
					)}
					<Controller
						name="truckCostPerMile"
						control={control}
						rules={{ required: "Vehicle Cost Per Mile is required" }}
						render={({ field: { onChange, value } }) => {
							return <Input label="Vehicle Cost Per Mile" value={value} onChange={onChange} isRequired={true} />;
						}}
					/>
					{errors.truckCostPerMile && <p className="text-red-500 text-sm mt-1">{errors.truckCostPerMile?.message}</p>}
				</div>
				<div className="flex flex-col space-y-4">
					<Controller
						name={`type`}
						control={control}
						rules={{ required: "Route Type is required" }}
						render={({ field: { onChange, value } }) => (
							<MultiSelectRounded
								value={value}
								onChange={onChange}
								options={serviceTypes}
								isRequired={true}
								label="Route Type"
								id={`route-type`}
							/>
						)}
					/>
					{errors.type && <p className="text-red-500 text-sm mt-1">{errors.type?.message}</p>}
					<Controller
						name="defaultEndLocation.name"
						control={control}
						rules={{ required: "Default End Location is required" }}
						render={({ field: { onChange, value } }) => {
							return <Input label="Default End Location" value={value} onChange={() => {}} isRequired={true} />;
						}}
					/>
					{errors.defaultEndLocation?.name && (
						<p className="text-red-500 text-sm mt-1">{errors.defaultEndLocation?.name?.message}</p>
					)}
				</div>
			</div>
			<div className="pt-4">
				<h6 className="font-medium text-lg pb-2 border-b border-cardTextGray border-opacity-40">Stats For Nerds</h6>
				<div className="py-4 flex flex-col gap-8">
					<div>
						<h6 className="text-lg">Number of services performed on this route over the last 30 days</h6>
						{pastService?.length > 0 ? (
							pastService.map((service, index) => (
								<div
									key={service?.id ?? index}
									className={`grid grid-cols-5 gap-4 border-b border-[##CCCCCC] px-8 font-base text-cardTextGray py-4`}
								>
									<div className="flex items-center gap-2">{formattedDate(service.date)}</div>
									<div>{service.route?.routeLabel}</div>
									<div>Stops</div>
									<div>{service.serviceSchedule?.notes}</div>
									<div>{service.serviceSchedule?.serviceType?.toString()}</div>
								</div>
							))
						) : (
							<div className="w-full text-cardTextGray">No data</div>
						)}
					</div>
					<div>
						<h6 className="text-lg">Number of scheduled services in the next 30 days</h6>
						{upcomingService?.length > 0 ? (
							upcomingService.map((service, index) => (
								<div
									key={service?.id ?? index}
									className={`grid grid-cols-5 gap-4 border-b border-[##CCCCCC] px-8 font-base text-cardTextGray py-4`}
								>
									<div className="flex items-center gap-2">{formattedDate(service.date)}</div>
									<div>{service.route?.routeLabel}</div>
									<div>Stops</div>
									<div>{service.serviceSchedule?.notes}</div>
									<div>{service.serviceSchedule?.serviceType?.toString()}</div>
								</div>
							))
						) : (
							<div className="w-full text-cardTextGray">No data</div>
						)}
					</div>
				</div>
			</div>
			<div className="flex justify-end gap-8 py-3">
				<Button btnStyle="form_nav_secondary" text={"Back"} onClick={handleBack} />
				<Button btnStyle="form_nav_primary" text={loading ? " Saving..." : "Save"} type="submit" />
			</div>
		</form>
	);
};

export default AddRouteForm;
