import { useState } from "react";
import AdminLayout from "./pages/admin/AdminLayout";
import { createBrowserRouter, useNavigate } from "react-router-dom";
import Generators from "./pages/admin/generator-management/components/generators/Generators";
import Reports from "./pages/admin/generator-management/components/reports/Reports";
import GeneratorDetails from "./pages/admin/generator-management/components/generators/components/detail-forms/GeneratorDetails";
import Audit from "./pages/admin/audit/Audit";
import Routes from "./pages/admin/assets/components/routes/index";
import Vehicles from "./pages/admin/vehicles/Vehicles";
import Settings from "./pages/admin/settings/Settings";
import Test from "./Test";
import Auth from "./pages/auth/Auth";
import WasteManagement from "./pages/admin/wastes/WasteManagement";
import Billing from "./pages/admin/billings/Billing";
import Users from "./pages/admin/users/Users";
import NotFound from "./components/NotFound";
import ServiceReporting from "./pages/admin/generator-management/components/reports/ServiceReporting";
import BoxLocation from "./pages/admin/generator-management/components/reports/BoxLocation";
import WeighingContainer from "./pages/admin/generator-management/components/reports/WeighingContainer";
import Gate_Waste_Finder from "./pages/admin/generator-management/components/reports/Gate_Waste_Finder";
import ServiceTicket from "./pages/admin/generator-management/components/reports/ServiceTicket";
import Assets from "./pages/admin/assets/Assets";
import DriverManagement from "./pages/admin/assets/components/drivers/DriverManagement";
import AddDriverForm from "./pages/admin/assets/components/drivers/components/AddDriver";
import AddTransporter from "./pages/admin/Assets/components/transporters/AddTransporter";
import TransportersMng from "./pages/admin/Assets/components/transporters/TransportersMng";
import BoxLocations from "./pages/admin/wastes/BoxLocations";
import WeighingInterface from "./pages/admin/wastes/WeighingInterface";
import WasteFinder from "./pages/admin/wastes/WasteFinder";
import ManageWastes from "./pages/admin/wastes/ManageWastes";
import DropOffReport from "./pages/admin/generator-management/components/reports/DropOffReport";

import AddNewVendor from "./pages/admin/Assets/components/vendors/AddNewVendor";
import VendorFacilites from "./pages/admin/Assets/components/vendors/VendorFacilites";

import AddTreatment from "./pages/admin/Assets/components/treatment-facilities/AddTreatment";
import TreatmentFacilities from "./pages/admin/assets/components/treatment-facilities/TreatmentFacilities";

import AddSatellite from "./pages/transporters/AddSatellite";
import SatelliteMng from "./pages/transporters/SatelliteMng";

import Subcontractor from "./pages/admin/octo-connect/Subcontractor";
import Subcontractor_Finder from "./pages/admin/octo-connect/Subcontractor_Finder";
import Conflict_Handling from "./pages/admin/octo-connect/Conflict_Handling";
import Match_Request from "./pages/admin/octo-connect/Match_Request";
import ContractorManagement from "./pages/admin/octo-connect/ContractorManagement";
import Subcontractor_Management from "./pages/admin/octo-connect/Subcontractor_Management";
import Subcontractor_Schedules from "./pages/admin/octo-connect/Subcontractor_Schedules";
import AddRouteForm from "./pages/admin/Assets/components/routes/components/AddRouteForm";
import ServiceTickets from "./pages/admin/wastes/ServiceTickets";
import ServiceLocationList from "./pages/admin/octo-connect/ServiceLocationList";

function App() {
	const navigate = useNavigate();
	return (
		<div className="h-screen w-full flex flex-col gap-4 justify-center items-center">
			<button
				className="py-2 px-4 w-full max-w-sm bg-cardTextBlue text-white rounded-md"
				onClick={() => navigate("/admin")}
			>
				Go to admin dashboard
			</button>
			<button
				className="py-2 px-4 w-full max-w-sm bg-dashBtnGradient text-white rounded-md"
				onClick={() => navigate("/auth")}
			>
				Sign Up/Log In
			</button>
			<button
				className="py-2 px-4 w-full max-w-sm bg-dashBtnGradient text-white rounded-md"
				onClick={() => navigate("/satellites")}
			>
				Satellite Location
			</button>
		</div>
	);
}

export const AppRouter = createBrowserRouter([
	{
		path: "/",
		element: <App />,
	},
	{
		path: "/test",
		element: <Test />,
	},
	{
		path: "/auth",
		element: <Auth />,
	},
	{
		path: "/satellite",
		element: <AddSatellite />,
		children: [
			{
				path: "add-satellite",
				element: <AddSatellite />,
			},
			{
				path: "add-satellite/:id/edit",
				element: <AddSatellite />,
			}
		],
	},
	{
		path: "satellites",
		element: <SatelliteMng />,
	},

	{
		path: "/admin",
		element: <AdminLayout />,
		children: [
			{
				path: "wastes",
				// element: <WasteManagement />,
				children: [
					{
						index: "",
						element: <ManageWastes />,
					},
					{
						path: "manage",
						element: <WasteManagement />,
					},
					{
						path: "wastefinder",
						element: <WasteFinder />,
					},
					{
						path: "boxlocations",
						element: <BoxLocations />,
					},
					{
						path: "weights",
						element: <WeighingInterface />,
					},
					{
						path: "service-ticket",
						element: <ServiceTickets />,
					},
				],
			},
			{
				path: "generators",
				children: [
					{
						index: true,
						element: <Generators />,
					},
					{
						path: "map",
						element: <Generators viewOption="map" />,
					},
					{
						path: "list",
						element: <Generators viewOption="list" />,
					},
					{
						path: "reports",
						children: [
							{
								index: true,
								element: <Reports />,
							},
							{
								path: "servicereporting",
								children: [
									{
										index: true,
										element: <ServiceReporting />,
									},
									{
										path: "drop-off-report",
										children: [
											{
												index: true,
												element: <DropOffReport />,
											},
										],
									},
									{
										path: "drop-off-report/boxlocation",
										children: [
											{
												index: true,
												element: <BoxLocation />,
											},
											{
												path: "weighingcontainer",
												children: [
													{
														index: true,
														element: <WeighingContainer />,
													},
													{
														path: "gate-waste-finder",
														children: [
															{
																index: true,
																element: <Gate_Waste_Finder />,
															},
															{
																path: "serviceticket",
																element: <ServiceTicket />,
															},
														],
													},
												],
											},
										],
									},
								],
							},
						],
					},
					{
						path: "new",
						element: <GeneratorDetails />,
					},
					{
						path: "new/generator-profile",
						element: <GeneratorDetails />,
					},
					{
						path: "new/generator-contacts",
						element: <GeneratorDetails />,
					},
					{
						path: "new/service-notes",
						element: <GeneratorDetails />,
					},
					{
						path: "new/routes",
						element: <GeneratorDetails />,
					},
					// {
					// 	path: "history",
					// 	element: <ServiceHistory />,
					// },
					{
						path: ":id",
						element: <GeneratorDetails />,
					},
					{
						path: ":id/generator-profile",
						element: <GeneratorDetails />,
					},
					{
						path: ":id/generator-contacts",
						element: <GeneratorDetails />,
					},
					{
						path: ":id/service-notes",
						element: <GeneratorDetails />,
					},
					{
						path: ":id/routes",
						element: <GeneratorDetails />,
					},
				],
			},
			{
				path: "assets",
				element: <Assets />,
				children: [
					{
						path: "drivers",
						children: [
							{
								path: "",
								element: <DriverManagement />,
							},
							{
								path: "add",
								element: <AddDriverForm mode="add" />,
							},
							{
								path: ":id/edit",
								element: <AddDriverForm mode="edit" />,
							},
						],
					},
					{
						path: "routes",
						children: [
							{
								path: "",
								element: <Routes />,
							},
							{
								path: "add",
								element: <AddRouteForm />,
							},
							{
								path: ":id/edit",
								element: <AddRouteForm />,
							},
						],
					},
					{
						path: "transporters",
						element: <AddTransporter />,
						children: [
							{
								path: "add-transporter",
								element: <AddTransporter />,
							},
							{
								path: "add-transporter/:id/edit",
								element: <AddTransporter />,
							},
						],
					},

					{
						path: "vendors",
						element: <AddNewVendor />,
						children: [
							{
								path: "add-vendors",
								element: <AddNewVendor />,
							},
							{
								path: "add-vendors/:id/edit",
								element: <AddNewVendor />,
							},
						],
					},

					{
						path: "manage-vendor",
						element: <VendorFacilites />,
					},
					{
						path: "treatment",
						element: <AddTreatment />,
						children: [
							{
								path: "add-treatment",
								element: <AddTreatment />,
							},
							{
								path: "add-treatment/:id/edit",
								element: <AddTreatment />,
							},
						],
					},

					{
						path: "treatments-facilities",
						element: <TreatmentFacilities />,
					},

					{
						path: "transportersmng",
						element: <TransportersMng />,
					},
				],
			},

			{
				path: "octo-connect",
				element: <Subcontractor />,
				children: [
					{
						path: "subcontractor-finder",
						element: <Subcontractor_Finder />,
					},

					{
						path: "subcontractor-management",
						element: <Subcontractor_Management />,
					},
					{
						path: "service-locations",
						element: <ServiceLocationList />,
					},
					{
						path: "contractor-management",
						element: <ContractorManagement />,
					},
					{
						path: "match-request",
						element: <Match_Request />,
					},
					{
						path: "linked-generators",
						element: <Conflict_Handling />,
					},
					{
						path: "subcontractor-schedules",
						element: <Subcontractor_Schedules />,
					},
				],
			},

			{
				path: "audit",
				element: <Audit />,
			},
			{
				path: "routes",
				element: <Routes />,
			},
			{
				path: "settings",
				element: <Settings />,
			},
			{
				path: "vehicles",
				element: <Vehicles />,
			},
			{
				path: "billings",
				element: <Billing />,
			},
			{
				path: "users",
				element: <Users />,
			},
		],
	},
	{
		path: "*",
		element: <NotFound />,
	},
]);

export default App;
