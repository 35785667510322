import React from "react";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import Dropdown from "../../../../../components/UI/dropdowns/Dropdown";

const Gate_Waste_Finder = () => {
    const navigate = useNavigate();
    const { control, handleSubmit, reset } = useForm({
        defaultValues: {
            address: "",
            state: "",
            city: "",
            zipCode: "",
        },
    });

    const handleNextClick = () => {
         navigate('ServiceTicket');
    };

    const handleSearch = (data) => {
        console.log("Search values:", data);
     };

    const handleClearAll = () => {
        reset(); 
    };

    const handlePerviousbtn = () => {
        console.log("Save button clicked"); 
        navigate(-1);  
    };

    return (
        <div className="main-container flex flex-col border border-cardBorderCol bg-white rounded-cardRadii h-full">
            <div className="ml-4 mt-4">
				<div className="font-semibold text-2xl">Generator Name:</div>
				<div>OCTO Number:</div>
				<div>Customer Since:</div>
				<div>Service Address:</div>
			</div>
			<div className="border-b border-gray-500 mt-4"></div>
            <div className="text-lg font-normal ml-10 mt-4 text-black pb-2 mb-2">Gate Waste Finder</div>
            <div className="border-b border-gray-300"></div>

            <div>
                <div className="w-full flex flex-col md:flex-row">
                    <div className="w-1/2 ml-4 mr-10">
                        <Controller
                            name="address"
                            control={control}
                            render={({ field: { value, onChange } }) => (
                                <Dropdown
                                     label="Address"
                                    value={value}
                                    onChange={onChange}
                                    options={[{ label: "Florida", value: "Florida" }]}
                                />
                            )}
                        />
                    </div>

                    <div className="w-1/2 mr-10">
                        <Controller
                            name="state"
                            control={control}
                            render={({ field: { value, onChange } }) => (
                                <Dropdown
                                    label="State"
                                    value={value}
                                    onChange={onChange}
                                    options={[{ label: "Florida", value: "Florida" }]}
                                />
                            )}
                        />
                    </div>
                </div>

                <div className="flex justify-around">
                    <div className="w-1/2 ml-4 mr-10">
                        <Controller
                            name="city"
                            control={control}
                            render={({ field: { value, onChange } }) => (
                                <Dropdown
                                    label="City"
                                    value={value}
                                    onChange={onChange}
                                    options={[{ label: "Florida", value: "Florida" }]}
                                />
                            )}
                        />
                    </div>

                    <div className="w-1/2 mr-10">
                        <Controller
                            name="zipCode"
                            control={control}
                            render={({ field: { value, onChange } }) => (
                                <Dropdown
                                    label="Zip Code"
                                    value={value}
                                    onChange={onChange}
                                    options={[{ label: "+10", value: "Ten" }]}
                                />
                            )}
                        />
                    </div>
                </div>
            </div>

            {/* Button Section for Clear All and Search */}
            <div className="flex justify-end mt-4 mr-12 gap-6">
                <button
                    onClick={handleClearAll}
                    style={{ width: '124px', height: '30px' }}

                    className="bg-[#007AFF] text-white text-xs rounded-full border border-[#666666] hover:bg-white hover:text-black hover:border-[#666666] transition-opacity duration-300"
                >
                    Clear All
                </button>
                <button
                    onClick={handleSubmit(handleSearch)}
                    style={{ width: '124px', height: '30px' }}

                    className="bg-[#007AFF] text-white text-xs rounded-full border border-[#666666] hover:bg-white hover:text-black hover:border-[#666666] transition-opacity duration-300"
                >
                    Search
                </button>
            </div>

            {/* Buttons Section */}
            <div className="flex justify-end p-4 mb-12 mt-10 space-x-4">
                <button
                onClick={handlePerviousbtn}
                    style={{ width: '184px', height: '44px' }}
                    className="bg-[#007AFF] text-white px-4 py-2 rounded-full border border-[#666666] hover:bg-white hover:text-black hover:border-[#666666] transition-opacity duration-300"
                 >
                    Previous
                </button>
                <button
                    onClick={handleSubmit(handleNextClick)}
                    style={{ width: '184px', height: '44px' }}
                    className="bg-[#007AFF] text-white px-4 py-2 rounded-full border border-[#666666] hover:bg-white hover:text-black hover:border-[#666666] transition-opacity duration-300"
                >
                    Next
                </button>
            </div>
        </div>
    );
}

export default Gate_Waste_Finder;
