import React, { useEffect, useState } from "react";
import { IoIosArrowForward } from "react-icons/io";
import { RiHome6Line } from "react-icons/ri";
import { useBreadcrumb } from "../../context/BreadcrumbContext";

const Breadcrumb = ({ items = [], activeIndex, navigate }) => {
	const [lastActivatedBtn, setLastActivatedBtn] = useState(2);
	const { breadcrumbData } = useBreadcrumb();
	const { steps, type } = breadcrumbData;
	let activeItemIndex = -1;
	for (let i = items.length - 1; i >= 0; i--) {
		if (location.pathname === items[i].path) {
			activeItemIndex = i;
			break;
		}
	}
	const getVisibleItems = () => {
		if (items.length <= 5) {
			return items;
		}

		let visibleItems = [];
		let leftCount = Math.min(1, activeItemIndex);
		let rightCount = Math.min(1, items.length - activeItemIndex - 1);

		// Adjust left and right counts to ensure at least 2 on each side if possible
		while (leftCount + rightCount < 2 && leftCount + rightCount < items.length - 1) {
			if (activeItemIndex - leftCount > 0) {
				leftCount++;
			} else if (activeItemIndex + rightCount < items.length - 1) {
				rightCount++;
			} else {
				break;
			}
		}

		// Add left items
		if (activeItemIndex - leftCount > 0) {
			visibleItems.push(items[0]);
			if (activeItemIndex - leftCount > 1) {
				visibleItems.push({ label: "...", path: null });
			}
		}
		for (let i = activeItemIndex - leftCount; i < activeItemIndex; i++) {
			visibleItems.push(items[i]);
		}

		// Add active item
		visibleItems.push(items[activeItemIndex]);

		// Add right items
		for (let i = activeItemIndex + 1; i <= activeItemIndex + rightCount; i++) {
			visibleItems.push(items[i]);
		}
		if (activeItemIndex + rightCount < items.length - 1) {
			if (activeItemIndex + rightCount < items.length - 2) {
				visibleItems.push({ label: "...", path: null });
			}
			visibleItems.push(items[items.length - 1]);
		}

		return visibleItems;
	};

	useEffect(() => {
		setLastActivatedBtn(1);
		Object.keys(steps).map((item) => {
			if (steps[item]) {
				setLastActivatedBtn((prev) => prev + 1);
			}
		});
	}, [steps]);
	const visibleItems = getVisibleItems();

	return (
		<div className="flex items-center text-cardTextBlue  text-sm xl:text-base">
			<RiHome6Line className="hover:cursor-pointer mx-2" onClick={() => navigate("/admin")} color="#878787" size={20} />
			<IoIosArrowForward color="#878787" size={16} />
			<nav aria-label="Breadcrumb">
				<ol className="flex flex-wrap items-center p-0 m-0 list-none">
					{type == "generators"
						? items.map((item, index) => (
								<GeneratorBreadcrumbItem
									key={index}
									label={item?.label}
									isActive={location.pathname === item?.path}
									isCompleted={steps[item?.label] || false}
									onClick={item?.path ? () => navigate(item?.path) : null}
									length={items.length}
									index={index}
									lastActivatedBtn={lastActivatedBtn}
								/>
						  ))
						: visibleItems.map((item, index) => (
								<BreadcrumbItem
									key={index}
									label={item?.label}
									isActive={location.pathname === item?.path}
									isCompleted={steps[item?.label] || false}
									onClick={item?.path ? () => navigate(item?.path) : null}
									length={visibleItems.length}
									index={index}
								/>
						  ))}
				</ol>
			</nav>
		</div>
	);
};

const BreadcrumbItem = ({ label, isActive, isCompleted, onClick, index, length }) => {
	const textColor = isCompleted || index < 2 ? "cardTextBlue" : "text-gray-500";
	const fontWeight = isActive ? "font-bold" : "font-normal";

	return (
		<>
			<p className={`p-1 ${onClick ? "cursor-pointer" : ""} ${fontWeight} ${textColor} bg-[#F9FAFB] px-2 rounded-md`} onClick={onClick}>
				{label ? (
					label
				) : (
					<svg className="animate-spin h-5 w-5 text-black inline-block" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
						<circle className="opacity-25" cx="12" cy="12" r="10" stroke="#848884" strokeWidth="4"></circle>
						<path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
					</svg>
				)}
			</p>
			{index < length - 1 && <IoIosArrowForward color="#878787" size={16} />}
		</>
	);
};

const GeneratorBreadcrumbItem = ({ label, isActive, isCompleted, onClick, index, length, lastActivatedBtn }) => {
	const textColor = isCompleted || index < 2 || index < lastActivatedBtn ? "text-cardTextBlue" : "text-gray-500";
	const fontWeight = isActive ? "font-bold" : "font-normal";

	return (
		<>
			<p
				className={`p-1 ${onClick && index <= lastActivatedBtn + 1 ? "cursor-pointer" : ""} ${fontWeight} ${textColor} bg-[#F9FAFB] px-2 rounded-md`}
				onClick={() => (index <= lastActivatedBtn + 1 ? onClick() : {})}
			>
				{label ? (
					label
				) : (
					<svg className="animate-spin h-5 w-5 text-black inline-block" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
						<circle className="opacity-25" cx="12" cy="12" r="10" stroke="#848884" strokeWidth="4"></circle>
						<path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
					</svg>
				)}
			</p>
			{index < length - 1 && <IoIosArrowForward color="#878787" size={16} />}
		</>
	);
};

export default Breadcrumb;
