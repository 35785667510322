import PropTypes from "prop-types";
import { useEffect, useMemo, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { BiPlus } from "react-icons/bi";
import Dropdown from "../../../../../../../../components/UI/dropdowns/Dropdown";
import CustomDatePicker from "../../../../../../../../components/UI/CustomDatePicker";
import Textarea from "../../../../../../../../components/UI/Textarea";
import MultiSelectRounded from "../../../../../../../../components/UI/dropdowns/MultiSelectRounded";
import GeneratorInfoHeader from "./components/GeneratorInfoHeader";
import {
	frequencyPrimaryOptions,
	frequencySecondaryOptions,
	itemsOptions,
	serviceTypes,
	weekdayOptions,
} from "../../../../../../../../utils/constants";
import {
	getAllGeneratorsLocationSnapshot,
	getAllRoutes,
	getAllRoutesPathData,
	getServiceSchedules,
} from "../../../../../../../../utils/firebaseOperations";
import {
	formattedDate,
	getUpcomingDates,
	getUpcomingWeekdays,
	showErrorToastMessage,
	splitArrayIntoChunks,
} from "../../../../../../../../utils/helpers";
import {
	addDoc,
	arrayUnion,
	collection,
	deleteDoc,
	doc,
	getDoc,
	getDocs,
	serverTimestamp,
	setDoc,
} from "firebase/firestore";
import {
	addOrUpdateGeneratorScheduleReq,
	deleteGeneratorScheduleReq,
	getGeneratorSchedules,
} from "../../../../../../../../utils/apiOps";
import GenRouteFooter from "./components/GenRouteFooter";
import FooterActionBtns from "./components/FooterActionBtns";
import RouteAssignment from "./components/RouteAssignment";
// import MapWithRoutes from "../../../../../../../../components/maps/MapWithRoutes";
import { FaCheck, FaExclamation } from "react-icons/fa";
import { useMap, useMapsLibrary } from "@vis.gl/react-google-maps";
import { COLLECTIONS, db } from "../../../../../../../../config/firebase";
const defaultOption = {
	serviceType: "",
	routeId: "",
	serviceFrequency: {
		type: "",
		days: [],
	},
	anchorDate: null,
	expectedItemOrService: [],
	notes: "",
	deliveryNotes: "",
	isWillCall: false,
	isSetUpService: false,
};

const GeneratorRoutes = ({ onClickBack, generatorData = {} }) => {
	const {
		control,
		handleSubmit,
		formState: { errors },
		watch,
		setValue,
		getValues,
	} = useForm({
		defaultValues: {
			serviceSchedules: [defaultOption],
		},
	});

	const [serviceTypeOptions, setServiceTypeOptions] = useState([]);
	const [routeOptions, setRouteOptions] = useState([]);
	const [allRoutesOptions, setAllRoutesOptions] = useState([]);
	const [isLoadingServices, setIsLoadingServices] = useState(true);
	const [generatorScheduledServices, setGeneratorScheduledServices] = useState([]);
	const [selectedRouteIds, setSelectedRouteIds] = useState([]);
	const [routePaths, setRoutePaths] = useState([]);
	const map = useMap();
	const routesLibrary = useMapsLibrary("routes");
	const [directionsService, setDirectionsService] = useState();
	const [prevServiceSchedules, setPrevServiceSchedules] = useState([]);
	const [updatedRoutesId, setUpdatedRoutesId] = useState([]);
	const [allGeneratorsData, setAllGeneratorsData] = useState([]);
	useEffect(() => {
		console.log({ generatorData });
	}, [generatorData]);

	useEffect(() => {
		if (!routesLibrary || !map) return;
		setDirectionsService(new routesLibrary.DirectionsService());
	}, [routesLibrary, map]);
	useEffect(() => {
		console.log("Route Assignment");

		getAllOtherRoutes();
	}, []);
	const getAllOtherRoutes = async () => {
		const allRoutePathData = await getAllRoutesPathData();
		console.log({ allRoutePathData });
		setRoutePaths(allRoutePathData);
	};
	const { fields, append, remove } = useFieldArray({
		control,
		name: "serviceSchedules",
	});
	useEffect(() => {
		let unsubscribe = getAllGeneratorsLocationSnapshot(setAllGeneratorsData);
		return () => {
			if (unsubscribe) unsubscribe();
		};
	}, []);

	const watchServiceSchedules = watch("serviceSchedules");

	const fetchAllServiceSchedules = async () => {
		if (Array.isArray(generatorData.serviceSchedules) && generatorData.serviceSchedules?.length > 0) {
			try {
				let resp = await getServiceSchedules(generatorData.serviceSchedules);
				setValue("serviceSchedules", resp);
				setPrevServiceSchedules(resp);
				resp.forEach((service, index) => {
					setSelectedRouteIds((prev) => {
						prev[index] = service.routeId;
						return prev;
					});
				});
			} catch (error) {
				console.log(error);
			}
		} else {
			setValue("serviceSchedules", []);
		}
	};

	const fetchScheduledServices = async () => {
		try {
			let resp = await getGeneratorSchedules(generatorData.id, "all");
			if (resp.success) {
				setGeneratorScheduledServices(resp.data?.scheduled ?? []);
				setIsLoadingServices(false);
			}
		} catch (error) {
			console.error(error);
			toast.error("Error during loading scheduled services");
		}
	};
	useEffect(() => {
		const fetchAllRoutesOptions = async () => {
			try {
				let resp = await getAllRoutes();
				setAllRoutesOptions(resp);
				let options = resp.map((item) => ({
					label: item.routeLabel,
					value: item.id,
				}));
				setRouteOptions(options);
			} catch (error) {
				console.log("Error fetching routes", error);
			}
		};
		fetchAllRoutesOptions();
		fetchAllServiceSchedules();
		fetchScheduledServices();
	}, []);

	useEffect(() => {
		if (generatorData?.serviceType) {
			setServiceTypeOptions(serviceTypes.filter((item) => generatorData.serviceType.includes(item.value)));
		}
	}, [generatorData]);

	// Effect for initial data loading and subsequent updates
	useEffect(() => {
		if (watchServiceSchedules) {
			watchServiceSchedules.forEach((schedule, index) => {
				const calculatedDates = calculateUpcomingDates(schedule);
				setValue(`serviceSchedules.${index}.upcomingDates`, calculatedDates, { shouldValidate: true });
			});
		}
	}, [watchServiceSchedules, setValue]);
	useEffect(() => {
		const subscription = watch((value, { name }) => {
			if (
				name &&
				name.startsWith("serviceSchedules") &&
				(name.endsWith("anchorDate") || name.includes("serviceFrequency"))
			) {
				const index = parseInt(name.split(".")[1]);
				const schedule = value.serviceSchedules[index];

				if (schedule && schedule.anchorDate && schedule.serviceFrequency) {
					let calculatedDates = [];
					if (schedule.serviceFrequency.type === "WC") {
						calculatedDates = [];
					} else if (schedule.serviceFrequency.type === "MTWM") {
						if (schedule.serviceFrequency.days.length > 0) {
							calculatedDates = getUpcomingWeekdays(new Date(schedule.anchorDate), schedule.serviceFrequency.days, 6);
						}
					} else {
						calculatedDates = getUpcomingDates(new Date(schedule.anchorDate), schedule.serviceFrequency.type, 6);
					}
					let dates = calculatedDates.filter((date) => date > new Date());
					setValue(`serviceSchedules.${index}.upcomingDates`, dates);
				}
			}
		});

		return () => subscription.unsubscribe();
	}, [watch, setValue]);
	const addWillCallService = () => {
		append({
			...defaultOption,
			serviceFrequency: {
				type: "WC",
				days: [],
			},
			isWillCall: true,
		});
	};
	const addSetUpService = () => {
		append({
			...defaultOption,
			isSetUpService: true,
		});
	};
	const serviceFrequencyOptions = [...frequencyPrimaryOptions, ...frequencySecondaryOptions];
	const onSubmitForm = async (data) => {
		try {
			data.serviceSchedules = data.serviceSchedules.map((item) => {
				let { ...rest } = item;
				return {
					...rest,
					generatorId: generatorData.id,
					priceBook: "",
				};
			});
			data.serviceSchedules.forEach((el) => {
				if (!el.isOptimized) {
					showErrorToastMessage(
						"Please optimize all the new services routes before saving (click on yellow exclamation mark)."
					);
					throw new Error(
						"Please optimize all the new services routes before saving (click on yellow exclamation mark).",
						{ cause: "customError" }
					);
				}
			});
			toast.dismiss();
			toast.loading("Saving...");
			const response = await addOrUpdateGeneratorScheduleReq(generatorData.id, data.serviceSchedules);
			console.log({ response });

			const jobs = data.serviceSchedules.map((service) => {
				const matchingPrevService = prevServiceSchedules.find((prevService) => prevService.id === service.id);
				if (matchingPrevService) {
					console.log("Service Already exist");
					if (service.routeId !== matchingPrevService.routeId) {
						console.log({ routeId: service.routeId });
						console.log({ prevRouteId: matchingPrevService.routeId });
						updateCurrentRoute(service.routeId, {
							location: generatorData.serviceAddCoordinates,
							name: generatorData.generatorName,
							id: generatorData.id,
							octoNumber: generatorData.octoNumber,
							serviceId: service.id,
						});
						updatePreviousRoute(matchingPrevService.routeId, {
							location: generatorData.serviceAddCoordinates,
							name: generatorData.generatorName,
							id: generatorData.id,
							octoNumber: generatorData.octoNumber,
							serviceId: service.id,
						});
					} else {
						updateCurrentRoute(service.routeId, {
							location: generatorData.serviceAddCoordinates,
							name: generatorData.generatorName,
							id: generatorData.id,
							octoNumber: generatorData.octoNumber,
							serviceId: service.id,
						});
						updatePreviousRoute(matchingPrevService.routeId, {
							location: generatorData.serviceAddCoordinates,
							name: generatorData.generatorName,
							id: generatorData.id,
							octoNumber: generatorData.octoNumber,
							serviceId: service.id,
						});
					}
				} else {
					console.log({ routeId: service.routeId });
					updateCurrentRoute(service.routeId, {
						location: generatorData.serviceAddCoordinates,
						name: generatorData.generatorName,
						id: generatorData.id,
						octoNumber: generatorData.octoNumber,
						serviceId: "TBD",
					});
				}
			});
			console.log({ jobs });
			await Promise.all(jobs);
			console.log("Schedule updated successfully:", response.data);
			// let resp = await updateServiceSchedules(data.serviceSchedules, generatorData.id);
			// console.log(resp);
			toast.dismiss();
			toast.success("Saved successfully!");
			await fetchScheduledServices();
			// await fetchAllServiceSchedules();
			// onNextClick();
		} catch (error) {
			if (error.cause === "customError") {
				showErrorToastMessage(error.message);
			} else {
				toast.dismiss();
				console.error("Error saving schedules:", error);
				toast.error("Error saving schedules. Please try again.");
			}
		}
	};
	const updateCurrentRoute = async (routeId, waypoint) => {
		console.log({ routeId });
		console.log({ newWaypoint: waypoint });

		await setDoc(
			doc(db, COLLECTIONS.routes, routeId),
			{
				waypoints: arrayUnion(waypoint),
				updatedAt: serverTimestamp(),
			},
			{ merge: true }
		);
		const pathRes = await getDocs(collection(db, COLLECTIONS.routes, routeId, "path"));
		for (const el of pathRes.docs) {
			console.log({ prevPathid: el });

			await deleteDoc(el.ref);
		}
		const currentRoute = routePaths.find((path) => path.id === routeId);
		const path = currentRoute.path;
		if (path.length > 0) {
			const pathChunks = splitArrayIntoChunks(path, 10000);
			console.log({ pathChunks });
			pathChunks.forEach((chunk, i) => {
				let data = {
					index: i,
					path: chunk,
				};
				console.log({ data });
				addDoc(collection(db, COLLECTIONS.routes, routeId, "path"), data);
			});
		}
	};
	const updatePreviousRoute = async (routeId, waypoint) => {
		console.log({ routeId });
		console.log({ wayPointToRemove: waypoint });

		const routeRes = await getDoc(doc(db, COLLECTIONS.routes, routeId));
		if (!routeRes.exists) {
			showErrorToastMessage("Internal Server Error. Previous data not found.");
			return;
		}
		const routeData = routeRes.data();
		console.log({ routeData });

		const newWaypoints =
			routeData.waypoints.length < 1
				? []
				: routeData.waypoints.filter((el) => !(el.id === waypoint.id && el.serviceId === waypoint.serviceId));
		console.log({ newWaypoints });

		await setDoc(
			doc(db, COLLECTIONS.routes, routeId),
			{
				waypoints: newWaypoints,
				updatedAt: serverTimestamp(),
			},
			{ merge: true }
		);

		const pathRes = await getDocs(collection(db, COLLECTIONS.routes, routeId, "path"));
		for (const el of pathRes.docs) {
			await deleteDoc(el.ref);
		}
		console.log({ "Route Data just before calling route api": routeData });

		const response = await directionsService.route({
			origin: { lat: routeData.defaultStartLocation.lat, lng: routeData.defaultStartLocation.lng },
			destination: { lat: routeData.defaultEndLocation.lat, lng: routeData.defaultEndLocation.lng },
			waypoints: newWaypoints.map((el) => {
				return { location: el.location, stopover: true };
			}),
			optimizeWaypoints: true,
			travelMode: "DRIVING",
		});
		console.log({ newRouteResponse: response });
		console.log(response.routes[0]);

		if (response?.routes?.length < 1) {
			showErrorToastMessage(
				"No route found for the removed route. Please go back to Routes Listing and update the invalid route."
			);
			return;
		}
		const routes = response.routes;
		const routeIndex = 0;
		const steps = routes[routeIndex]?.legs.flatMap((el) => el.steps);
		let path = steps.flatMap((el) => el.path);
		path = path.map((el) => {
			return { lat: el.lat(), lng: el.lng() };
		});
		setRoutePaths((prev) => {
			if (prev.id === routeId) return { id: routeId, path: path };
			return prev;
		});
		if (path.length > 0) {
			const pathChunks = splitArrayIntoChunks(path, 10000);
			console.log({ pathChunks });
			pathChunks.forEach((chunk, i) => {
				let data = {
					index: i,
					path: chunk,
				};
				addDoc(collection(db, COLLECTIONS.routes, routeId, "path"), data);
			});
		}
	};
	const deleteSchedule = async (field, index) => {
		console.log({ field });
		toast.dismiss();
		toast.loading("Deleting");
		let allSchedules = getValues("serviceSchedules");

		let dataToDelete = allSchedules.find((_, i) => index == i);

		let theData = {
			...field,
			id: dataToDelete.id,
		};

		if (theData.id) {
			try {
				const response = await deleteGeneratorScheduleReq(generatorData.id, theData);
				console.log("Schedule deleted successfully:", response.data);
				await fetchScheduledServices();
			} catch (error) {
				// Handle error appropriately
				console.error("Failed to delete schedule:", error);
			}
			// let resp = await deleteServiceSchedule(theData, generatorData.id);
			// console.log({ resp });
		}
		toast.dismiss();
		toast.success("Deleted successfully!");
		remove(index);
	};

	const pendingScheduledService = useMemo(
		() => generatorScheduledServices?.filter((scheduledService) => scheduledService.status == "PENDING"),
		[generatorScheduledServices]
	);
	const completedScheduledService = useMemo(
		() => generatorScheduledServices?.filter((scheduledService) => scheduledService.status == "COMPLETED"),
		[generatorScheduledServices]
	);
	const routeChangeHandler = (index, e) => {
		if (prevServiceSchedules[index].routeId === e) {
			setValue(`serviceSchedules.${index}.isOptimized`, true);
		} else {
			setValue(`serviceSchedules.${index}.isOptimized`, false);
		}
		console.log({ index, e });
		setSelectedRouteIds((prev) => {
			prev[index] = e;
			return prev;
		});
	};
	useEffect(() => {
		console.log({ selectedRouteIds });
	}, [selectedRouteIds]);
	const routeOptimizeHandler = async (index) => {
		const value = getValues(`serviceSchedules.${index}.routeId`);
		console.log({ value });
		if (!value) {
			showErrorToastMessage("Please select route to optimize.");
			return;
		}
		await calculateAndDisplayRoute(routePaths.find((el) => el.id === value));
		setValue(`serviceSchedules.${index}.isOptimized`, true);
		setUpdatedRoutesId((prev) => [...prev, value]);
	};
	const calculateAndDisplayRoute = async (route) => {
		console.log({ route });

		if (!directionsService) {
			showErrorToastMessage("Internal Server error. Google Direction is not working.");
			return;
		}

		const waypts = [
			{
				location: generatorData.serviceAddCoordinates,
				stopover: true,
			},
		];
		if (route?.waypoints?.length) {
			route.waypoints.forEach((el) => {
				waypts.push({
					location: el.location,
					stopover: true,
				});
			});
		}
		console.log({ waypts });

		const response = await directionsService.route({
			origin: route.path[0],
			destination: route.path[route.path.length - 1],
			waypoints: waypts,
			optimizeWaypoints: true,
			travelMode: "DRIVING",
		});
		console.log({ response });
		console.log(response.routes[0]);

		if (response?.routes?.length < 1) {
			showErrorToastMessage("No route found for the generator to be placed on.");
			return;
		}
		const routes = response.routes;
		const routeIndex = 0;
		if (routes[routeIndex]?.legs) {
			const steps = routes[routeIndex]?.legs.flatMap((el) => el.steps);
			let path = steps.flatMap((el) => el.path);
			path = path.map((el) => {
				return { lat: el.lat(), lng: el.lng() };
			});
			// console.log({ path });
			setRoutePaths((prev) =>
				prev.map((el) => {
					if (el.id === route.id) {
						return {
							...el,
							path,
						};
					}
					return el;
				})
			);
		}
	};
	return (
		<form onSubmit={handleSubmit(onSubmitForm)} className="grid bg-white p-8 py-6 mb-6 rounded-cardRadii">
			<GeneratorInfoHeader generatorData={generatorData} />
			<RouteAssignment
				allRoutes={routePaths}
				selectedRoutes={selectedRouteIds}
				generatorData={generatorData}
				getValues={getValues}
				allGeneratorsData={allGeneratorsData}
			/>
			<h6 className="font-medium py-2 text-lg border-b border-[#CCCCCC]">Generator Service Requirements</h6>
			{fields.map((field, index) => (
				<div
					key={field.id || Date.now() + Math.random()}
					className={`${fields.length > 1 ? "border-b border-borderCol pb-4" : "pb-4"} pb-4`}
				>
					{/* <h6 className="font-medium py-2 text-lg">{`Service Schedule ${index + 1}`}</h6> */}
					<div className="flex gap-8 w-full">
						<div className="w-1/2">
							<div className="grid grid-cols-12 gap-2">
								<div className="col-span-11">
									<Controller
										name={`serviceSchedules.${index}.routeId`}
										control={control}
										rules={{ required: "Route is required" }}
										render={({ field: { onChange, value } }) => (
											<Dropdown
												label="Route"
												options={routeOptions}
												value={value}
												onChange={(e) => {
													onChange(e);
													routeChangeHandler(index, e);
												}}
												isRequired={true}
											/>
										)}
									/>
									{errors.serviceSchedules?.[index]?.routeId && (
										<p className="text-red-500 text-sm mt-1">{errors.serviceSchedules[index].routeId.message}</p>
									)}
								</div>
								<div className="self-center">
									<Controller
										name={`serviceSchedules.${index}.routeId`}
										control={control}
										rules={{ required: "Route is required" }}
										render={() => (
											<div
												className="cursor-pointer p-2 rounded-full border border-gray-300 max-w-8 max-h-8 flex justify-center items-center"
												onClick={() => routeOptimizeHandler(index)}
											>
												{watch(`serviceSchedules.${index}.isOptimized`) ? (
													<FaCheck className="text-green-500" />
												) : (
													<FaExclamation className="animate-bounce text-yellow-500" />
												)}
											</div>
										)}
									/>
								</div>
							</div>
							<Controller
								name={`serviceSchedules.${index}.serviceFrequency.type`}
								control={control}
								rules={{ required: "Service Frequency is required" }}
								render={({ field: { onChange, value } }) => (
									<Dropdown
										label="Service Frequency"
										options={serviceFrequencyOptions}
										value={value}
										onChange={onChange}
										isRequired={true}
										noCursor={field?.isWillCall}
									/>
								)}
							/>
							{errors.serviceSchedules?.[index]?.serviceFrequency?.type && (
								<p className="text-red-500 text-sm mt-1">
									{errors.serviceSchedules[index].serviceFrequency.type.message}
								</p>
							)}
							{watchServiceSchedules[index]?.serviceFrequency?.type === "MTWM" && (
								<Controller
									name={`serviceSchedules.${index}.serviceFrequency.days`}
									control={control}
									rules={{ required: "Weekdays are required for multiple times weekly" }}
									render={({ field: { onChange, value } }) => (
										<MultiSelectRounded
											value={value}
											onChange={onChange}
											options={weekdayOptions}
											label="Select Weekdays"
											id={`weekdays-input-${index}`}
										/>
									)}
								/>
							)}
							<div className="flex items-center justify-between my-4">
								<label htmlFor={`anchorDate-${index}`} className="truncate text-inputLabel font-normal">
									{field?.isWillCall ? "Will Call Date" : "Anchor Date"}
								</label>
								<div className="w-2/3">
									<Controller
										name={`serviceSchedules.${index}.anchorDate`}
										control={control}
										render={({ field: { value, onChange } }) => (
											<CustomDatePicker selectedDate={value} setSelectedDate={onChange} label={"Anchor Date"} />
										)}
									/>
								</div>
							</div>
						</div>
						<div className="w-1/2">
							<Controller
								name={`serviceSchedules.${index}.serviceType`}
								control={control}
								rules={{ required: "Service Type is required" }}
								render={({ field: { onChange, value } }) => (
									<MultiSelectRounded
										value={value}
										onChange={onChange}
										options={serviceTypeOptions}
										isRequired={true}
										label="Service Type"
										id={`service-input-${index}`}
									/>
								)}
							/>
							{errors.serviceSchedules?.[index]?.serviceType && (
								<p className="text-red-500 text-sm mt-1">{errors.serviceSchedules[index].serviceType.message}</p>
							)}
							<Controller
								name={`serviceSchedules.${index}.expectedItemOrService`}
								control={control}
								rules={{ required: "Expected Item/Service is required" }}
								render={({ field: { value, onChange } }) => (
									<MultiSelectRounded
										value={value}
										onChange={onChange}
										options={itemsOptions}
										isRequired={true}
										label="Expected Items/Services"
										id={`expected-items-services-${index}`}
									/>
								)}
							/>
							{errors.serviceSchedules?.[index]?.expectedItemOrService && (
								<p className="text-red-500 text-sm mt-1">
									{errors.serviceSchedules[index].expectedItemOrService?.message}
								</p>
							)}
							<Controller
								name={`serviceSchedules.${index}.priceBook`}
								control={control}
								render={({ field }) => (
									<div>
										<Dropdown label={"Select A Price Book"} options={[]} margin="my-0" {...field} />
										{errors.serviceSchedules?.[index]?.priceBook && (
											<p className="text-red-500 text-sm mt-1">{errors.serviceSchedules[index].priceBook.message}</p>
										)}
									</div>
								)}
							/>
						</div>
					</div>
					{watchServiceSchedules[index].upcomingDates?.length > 0 && (
						<div className="mb-4">
							<h6 className="font-medium pb-2">Upcoming Schedules</h6>
							<div className="flex gap-2 flex-wrap">
								{watchServiceSchedules[index].upcomingDates.map((date, dateIndex) => (
									<div
										key={dateIndex}
										className="inline-flex items-center bg-gray-100 rounded-full px-3 py-1 text-sm text-gray-700 mr-2 mb-2"
									>
										<span>{formattedDate(date)}</span>
									</div>
								))}
							</div>
						</div>
					)}
					<div className="w-full flex gap-8">
						<div className="w-1/2">
							<Controller
								name={`serviceSchedules.${index}.notes`}
								control={control}
								render={({ field: { onChange, value } }) => (
									<Textarea value={value} onChange={onChange} label="Service Note" margin="mt-0" />
								)}
							/>
							<Controller
								name={`serviceSchedules.${index}.deliveryNotes`}
								control={control}
								render={({ field: { onChange, value } }) => (
									<Textarea value={value} onChange={onChange} label="Delivery Note" />
								)}
							/>
						</div>
						<div className="w-1/2"></div>
					</div>
					{index > 0 && (
						<div className="w-full flex justify-end">
							<button
								type="button"
								className="rounded-full px-4 py-1 text-sm border border-black hover:bg-cardTextGray hover:bg-opacity-10"
								onClick={() => deleteSchedule(field, index)}
							>
								Remove service schedule
							</button>
						</div>
					)}
				</div>
			))}
			<AddMoreServiceButtons addSetUpService={addSetUpService} addWillCallService={addWillCallService} />
			<FooterActionBtns nextBtnText={"Submit"} onClickBack={onClickBack} />
			<GenRouteFooter
				isLoadingServices={isLoadingServices}
				pendingScheduledService={pendingScheduledService}
				completedScheduledService={completedScheduledService}
			/>
		</form>
	);
};

GeneratorRoutes.propTypes = {
	generatorData: PropTypes.object,
	onClickBack: PropTypes.func,
	onNextClick: PropTypes.func,
};

export default GeneratorRoutes;

function calculateUpcomingDates(schedule) {
	if (schedule.anchorDate && schedule.serviceFrequency) {
		let calculatedDates = [];
		if (schedule.serviceFrequency.type === "WC") {
			calculatedDates = [];
		} else if (schedule.serviceFrequency.type === "MTWM") {
			if (schedule.serviceFrequency.days.length > 0) {
				calculatedDates = getUpcomingWeekdays(new Date(schedule.anchorDate), schedule.serviceFrequency.days, 6);
			}
		} else {
			calculatedDates = getUpcomingDates(new Date(schedule.anchorDate), schedule.serviceFrequency.type, 6);
		}
		return calculatedDates?.filter((date) => date > Date.now());
	}
	return [];
}

const AddMoreServiceButtons = ({ addSetUpService, addWillCallService }) => {
	return (
		<div className="text-sm py-8 flex gap-4">
			<button
				type="button"
				className={`text-cardTextGray border-borderCol flex items-center justify-between gap-1 px-4 py-2 rounded-lg bg-creamWhite hover:bg-cardTextGray hover:bg-opacity-10 border transition-colors duration-200 ease-in-out`}
				onClick={addSetUpService}
			>
				<span>Add Set Up Service</span>
				<BiPlus size={16} />
			</button>
			<button
				type="button"
				className={`text-cardTextGray border-borderCol flex items-center justify-between gap-1 px-4 py-2 rounded-lg bg-creamWhite hover:bg-cardTextGray hover:bg-opacity-10 border transition-colors duration-200 ease-in-out`}
				onClick={addWillCallService}
			>
				<span>Add Will Call/ Off-Cycle Service</span>
				<BiPlus size={16} />
			</button>
		</div>
	);
};

AddMoreServiceButtons.propTypes = {
	addSetUpService: PropTypes.any,
	addWillCallService: PropTypes.any,
};
