import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import ServiceDropdown from "../generator-management/components/reports/ServiceDropdown";
import Dropdown from "../../../components/UI/dropdowns/Dropdown";

const Match_Request = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [searchQuery, setSearchQuery] = useState("");
	const navigate = useNavigate();
	const {
		control,
		formState: { errors },
	} = useForm({ defaultValues: { subContractor: "" } });

	const generatorData = Array.from({ length: 3 }, (_, index) => ({
		id: index + 1,
		Customer: `${index + 1} Sub-Contractor Name`,
	}));
	const dropdownOptions = ["Listed", "Unlisted"];
	const subcontractoroption = [
		{ label: "One", value: "one" },
		{ label: "Two", value: "two" },
		{ label: "Three", value: "three" },
	];

	const handleNextClick = () => {
		console.log("Next button clicked");
		navigate("/admin/octo-connect/linked-generators");
	};

	const handlePreviousBtn = () => {
		console.log("Previous button clicked");
		navigate("/admin/octo-connect/contractor-management");
	};
	const sendrequest = () => {};

	const handleSearchChange = (e) => {
		setSearchQuery(e.target.value);
	};

	return (
		<div className="p-6">
			<div className="main-container flex flex-col border border-cardBorderCol bg-white rounded-cardRadii h-full">
				<div className="pt-6 font-medium text-lg pl-8"> Search Generator </div>
				<div className="p-4 pl-8">
					<div className="flex items-center bg-white rounded-full p-3 border border-gray-200 w-2/5 ">
						<input
							type="text"
							placeholder="Search by Generator Name or OCTO number"
							value={searchQuery}
							onChange={handleSearchChange}
							className="ml-2 bg-transparent focus:outline-none text-gray-500 flex-1 w-full "
						/>
					</div>
				</div>
				<div className="px-8 my-2 font-medium text-lg text-black">Result</div>
				<div className="overflow-x-scroll overflow-y-hidden">
					<div className="bg-[#E5F2FF] flex font-medium min-w-fit py-4 px-8">
						<div className="flex items-center">
							<div className="truncate w-60">OCTO Number</div>
							<div className="truncate w-80">Generator Name</div>
							<div className="truncate w-80">Contractor Name</div>
							<div className="truncate w-80">Subcontractor Name</div>
							<div className="truncate w-60">Status</div>
							<div className="truncate w-60 text-center">Options</div>
							<div className="truncate w-60">Pricebook</div>
						</div>
					</div>

					<div className={`min-w-fit overflow-y-scroll h-[40%] overflow-x-hidden`}>
						{isLoading ? (
							<Loader height="h-12 pt-4" />
						) : generatorData.length > 0 ? (
							generatorData.map((generator, index) => (
								<div
									className={`flex w-full items-center px-8 transition-colors duration-300 ${
										index < generatorData.length - 1 ? "border-b border-gray-300" : ""
									} `}
									style={{ height: "45px" }}
								>
									<div className={`w-full flex items-center`}>
										<div className="w-60 h-full text-cardTextBlue truncate">{generator.octoNumber ?? "--"}</div>
										<div className="truncate w-80 h-full">{generator.name ?? "--"}</div>
										<div className="truncate w-80 h-full">{generator.contractorName ?? "--"}</div>
										<div className="truncate w-80 h-full">{generator.subContractorName ?? "--"}</div>
										<div className="truncate h-full w-60">{generator.status ?? "--"}</div>
										<div className="flex justify-center w-60 text-xs font-medium">
											<ServiceDropdown buttonText="Options" options={dropdownOptions} />
										</div>
										<div className="truncate h-full w-60">{generator.pricebook ?? "--"}</div>
									</div>
								</div>
							))
						) : (
							<div className="flex min-h-40 text-cardTextGray text-lg justify-center items-center">
								<p>No result found</p>
							</div>
						)}
					</div>
				</div>
				<div className="mt-12 px-8">
					<div className="text-lg py-2 font-medium">Partner Selection</div>
					<div className="border"></div>
					<div className="w-1/2">
						<Controller
							name={`subContractor`}
							control={control}
							rules={{ required: "subcontractor is required" }}
							render={({ field: { value, onChange } }) => (
								<div>
									<Dropdown
										value={value}
										onChange={onChange}
										options={subcontractoroption}
										label="Select A Sub-Contractor"
									/>
									{errors.subcontractor?.state && (
										<p className="text-red-500 text-sm mt-1">{errors.subcontractor.state.message}</p>
									)}
								</div>
							)}
						/>
					</div>
					<div className="w-1/2 flex py-2 justify-end">
						<button
							onClick={sendrequest}
							style={{ width: "184px", height: "44px" }}
							className="bg-whiteBlue hover:bg-blue-300 px-4 py-2 rounded-full transition-opacity duration-300"
						>
							Send Request
						</button>
					</div>
				</div>
				{/* Buttons Section */}
				<div className="flex justify-end p-4 mb-12 mt-12">
					<button
						onClick={handlePreviousBtn}
						style={{ width: "184px", height: "44px" }}
						className="bg-[#007AFF] text-white px-4 py-2 rounded-full mr-4 border border-[#666666] hover:bg-white hover:text-black hover:border-[#666666] transition-opacity duration-300"
					>
						Previous
					</button>
					<button
						onClick={handleNextClick}
						style={{ width: "184px", height: "44px" }}
						className="bg-[#007AFF] text-white px-4 py-2 rounded-full border border-[#666666] hover:bg-white hover:text-black hover:border-[#666666] transition-opacity duration-300"
					>
						Next
					</button>
				</div>
			</div>
		</div>
	);
};

export default Match_Request;
