import React, { useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { IoMdClose } from "react-icons/io";

const HamburgerMenu = () => {
	const [isOpen, setIsOpen] = useState(false);

	const toggleMenu = () => {
		setIsOpen(!isOpen);
	};

	return (
		<div className="relative">
			{/* Hamburger Icon */}
			<button className="p-2 rounded-full md:hidden" onClick={toggleMenu}>
				{isOpen ? <IoMdClose size={24} /> : <GiHamburgerMenu size={24} />}
			</button>

			{/* Slide-out Menu */}
			<nav className={`fixed top-0 right-0 h-full w-64 bg-white shadow-lg transform transition-transform duration-300 ease-in-out ${isOpen ? "translate-x-0" : "translate-x-full"} z-50`}>
				<div className="p-4">
					<ul className="space-y-4">
						<li className="hover:bg-gray-100 p-2 rounded">Home</li>
						<li className="hover:bg-gray-100 p-2 rounded">About</li>
						<li className="hover:bg-gray-100 p-2 rounded">Services</li>
						<li className="hover:bg-gray-100 p-2 rounded">Contact</li>
					</ul>
				</div>
			</nav>

			{/* Overlay for closing the menu */}
			{isOpen && <div className="fixed inset-0 bg-black opacity-50 z-40" onClick={toggleMenu}></div>}
		</div>
	);
};

export default HamburgerMenu;
