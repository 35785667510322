import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import { LuUpload } from "react-icons/lu";

const ServiceTicket = () => {
	const [files, setFiles] = useState([]);

	const onDrop = (acceptedFiles) => {
		setFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
		console.log(acceptedFiles);
	};

	const handleSubmit = () => {
		console.log("Submitting files:", files);
		setFiles([]);
	};

	const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

	return (
		<div className="main-container flex flex-col border border-cardBorderCol bg-white rounded-cardRadii h-full p-4">
			<div className="ml-4 mt-4">
				<div className="font-semibold text-2xl">Generator Name:</div>
				<div>OCTO Number:</div>
				<div>Customer Since:</div>
				<div>Service Address:</div>
			</div>
			<div className="border-b border-gray-500 mt-4"></div>
			<div className="mt-20">
				<div className="text-center text-lg font-semibold mb-4">Import Service Ticket</div>
				<div className="flex justify-center">
					<div {...getRootProps()} className={`border-2 border-dashed rounded-lg p-12 text-center w-1/2 ${isDragActive ? "border-blue-500" : "border-gray-300"}`}>
						<div className="flex justify-center">
							<LuUpload />
						</div>

						<input {...getInputProps()} />
						{isDragActive ? (
							<p className="text-blue-500">Drop the files here ...</p>
						) : (
							<div>
								<p className="font-semibold my-2">
									Drag & drop or <span className="text-[#007AFF]">Choose file</span> to upload
								</p>
								<div className="text-sm">Select zip, image, PDF, or MS Word files</div>
							</div>
						)}
					</div>
				</div>
				<div className="flex justify-end mt-40">
					<button
						style={{ width: "134px", height: "34px" }}
						onClick={handleSubmit}
						className="bg-[#007AFF] text-white   rounded-full border border-[#666666] hover:bg-white hover:text-black hover:border-[#666666] transition-opacity duration-300"
					>
						Submit
					</button>
				</div>
			</div>
		</div>
	);
};

export default ServiceTicket;
