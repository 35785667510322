import React from "react";

const GoogleAuthButton = ({ handleGoogleAuth }) => {
	return (
		<button
			onClick={handleGoogleAuth}
			className="w-full mt-4 flex items-center justify-center bg-white border border-gray-300 text-gray-700 py-2 rounded hover:bg-gray-100 transition duration-200"
		>
			<img src="https://developers.google.com/identity/images/g-logo.png" alt="Google Logo" className="w-5 h-5 mr-2" />
			<span className="font-medium">Sign in with Google</span>
		</button>
	);
};

export default GoogleAuthButton;
