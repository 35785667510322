import React, { useState } from "react";
import { format, startOfMonth, endOfMonth, addMonths, subMonths, eachDayOfInterval, getDay, parse } from "date-fns";

const eventsData = [
	{ date: "2023-09-05", type: "stops", count: 7, color: "bg-yellow-400" },
	{ date: "2023-09-07", type: "route", count: 7, color: "bg-green-400" },
	{ date: "2023-09-10", type: "stops", count: 7, color: "bg-blue-400" },
	{ date: "2023-09-10", type: "route", count: 7, color: "bg-purple-500" },
	{ date: "2023-09-15", type: "stops", count: 7, color: "bg-red-400" },
];

function Test() {
	const [currentDate, setCurrentDate] = useState(new Date());

	const handlePrevMonth = () => setCurrentDate(subMonths(currentDate, 1));
	const handleNextMonth = () => setCurrentDate(addMonths(currentDate, 1));

	const monthStart = startOfMonth(currentDate);
	const monthEnd = endOfMonth(currentDate);
	const days = eachDayOfInterval({ start: monthStart, end: monthEnd });
	const startDayOfWeek = getDay(monthStart);

	// Helper function to find events on a specific date
	const getEventsForDate = (date) => {
		const dateString = format(date, "yyyy-MM-dd");
		return eventsData.filter((event) => event.date === dateString);
	};

	return (
		<div className="p-4 max-w-lg mx-auto">
			{/* Header */}
			<div className="flex justify-between items-center mb-4">
				<button onClick={handlePrevMonth} className="px-4 py-2 bg-gray-200 rounded">
					Prev
				</button>
				<h2 className="text-lg font-semibold">{format(currentDate, "MMMM yyyy")}</h2>
				<button onClick={handleNextMonth} className="px-4 py-2 bg-gray-200 rounded">
					Next
				</button>
			</div>

			{/* Day Labels */}
			<div className="grid grid-cols-7 text-center font-semibold text-gray-600">
				<div>Sun</div>
				<div>Mon</div>
				<div>Tue</div>
				<div>Wed</div>
				<div>Thu</div>
				<div>Fri</div>
				<div>Sat</div>
			</div>

			{/* Days Grid */}
			<div className="grid grid-cols-7 gap-2 mt-2">
				{/* Empty cells for days before the start of the month */}
				{[...Array(startDayOfWeek)].map((_, i) => (
					<div key={`empty-${i}`} className="p-4"></div>
				))}

				{/* Days in the month */}
				{days.map((day) => (
					<div key={day} className="p-2 border rounded-lg relative">
						<span className="absolute top-1 right-1 text-sm font-bold">{format(day, "d")}</span>

						{/* Display events for the day */}
						<div className="mt-4 space-y-1">
							{getEventsForDate(day).map((event, index) => (
								<div key={index} className={`text-xs text-white px-2 py-1 rounded ${event.color}`}>
									{event.type === "stops" ? `Stops: ${event.count}` : `Route: ${event.count}`}
								</div>
							))}
						</div>
					</div>
				))}
			</div>
		</div>
	);
}

export default Test;
