import React from "react";
import { useNavigate } from "react-router-dom";

const NotFound = ({ text = "Page Not Found" }) => {
	const navigate = useNavigate();

	return (
		<div className="flex flex-col items-center justify-center h-screen">
			{text ? (
				<h1 className="text-4xl font-bold">404 - {text}</h1>
			) : (
				<>
					<h1 className="text-4xl font-bold">404 - Page Not Found</h1>
					<p className="mt-4 text-lg">The page you are looking for doesn't exist.</p>
					<button className="mt-6 px-4 py-2 bg-blue-500 text-white rounded" onClick={() => navigate(-1)}>
						Go Back
					</button>
				</>
			)}
		</div>
	);
};

export default NotFound;
