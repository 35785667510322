const PickupFilters = ({ Name }) => {
    return (
        <div className="flex">
            <div className="border border-gray-300 rounded-l p-2 bg-gray-100">
                {Name}
            </div>
            <input
                type="text"
                className="border border-gray-300 rounded-r p-2 w-20"
            />
        </div>
    );
};

export default PickupFilters;


export const FilterDropdown = ({ name, options }) => {
    return (
        <div className="flex">
            <div className="border border-gray-300 bg-gray-100 rounded-l p-2">
                {name}
            </div>
            <select className="border border-gray-300 rounded-r p-2 w-24">
                <option value="" disabled></option>
                {options.map(option => (
                    <option key={option.value} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </select>
        </div>
    );
};

