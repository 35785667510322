import React, { useState, useEffect, useRef } from "react";
import { ChevronDown } from "lucide-react";

const CrudDropdown = ({ onEdit, onDelete, onSaveAsPDF }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleOptionClick = (action) => {
    if (action === "edit") {
      onEdit();
    } else if (action === "delete") {
      onDelete();
    } else if (action === "saveAsPDF") {
      onSaveAsPDF();
    }
    setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <div className="relative inline-block" ref={dropdownRef}>
      <button
        className="bg-gray-300 py-1 rounded-cardRadii flex items-center justify-between min-w-[120px] px-7"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span>Options</span>
        <ChevronDown className="ml-2 h-4 w-4" />
      </button>
      {isOpen && (
        <div className="fixed mt-2 w-[120px] rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5" style={{ zIndex: 9999, top: "auto", left: "auto" }}>
          <button
            className="block w-full text-left px-4 py-2 hover:bg-gray-100"
            onClick={() => handleOptionClick("edit")}
          >
            Edit
          </button>
          <button
            className="block w-full text-left px-4 py-2 hover:bg-gray-100"
            onClick={() => handleOptionClick("delete")}
          >
            Delete
          </button>
          <button
            className="block w-full text-left px-4 py-2 hover:bg-gray-100"
            onClick={() => handleOptionClick("saveAsPDF")}
          >
            Save as PDF
          </button>
		  <button
            className="block w-full text-left px-4 py-2 hover:bg-gray-100"
            onClick={() => handleOptionClick("saveAsPDF")}
          >
            Share
          </button>
        </div>
      )}
    </div>
  );
};

export default CrudDropdown;