import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ServiceDropdown from "../generator-management/components/reports/ServiceDropdown";
import Loader from "../../../components/UI/loaders/Loader";

const Subcontrator_Management = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [hoveredRow, setHoveredRow] = useState(null);
	const [searchQuery, setSearchQuery] = useState("");
	const navigate = useNavigate();

	// Dummy data for contractors
	const dummyContractorData = Array.from({ length: 5 }, (_, index) => ({
		id: index + 1,
		Customer: `${index + 1} Generator Name details`,
	}));

	// Dummy data for sub-contractors
	const dummySubContractorData = Array.from({ length: 5 }, (_, index) => ({
		id: index + 1,
		Customer: `${index + 1} Sub-Contractor Name`,
	}));

	const [data, setData] = useState(dummyContractorData);
	const [subContractorData, setSubContractorData] = useState(dummySubContractorData);
	const dropdownOptions = ["Listed", "Unlisted"];

	const handleNextClick = () => {
		console.log("Next button clicked");
		navigate("/admin/octo-connect/contractor-management");
	};

	const handlePreviousBtn = () => {
		console.log("Previous button clicked");
		navigate("/admin/octo-connect/subcontractor-finder");
	};

	const handlePhoneClick = (event, phone) => {
		event.stopPropagation();
		window.location.href = `tel:${phone}`;
	};

	return (
		<div className="p-6">
			<div className="main-container flex flex-col border text-sm xl:text-base border-cardBorderCol bg-white rounded-cardRadii h-full">
				<div className="text-xl xl:text-2xl font-semibold mx-8 mt-6 text-black">Sub-Contractor Management</div>
				<div className="text-base mx-8 mb-3 text-black">Current Partners Management</div>
				<div className="overflow-x-scroll overflow-y-hidden">
					<div className="bg-[#E5F2FF] flex font-medium min-w-fit py-4 px-8">
						<div className="flex items-center">
							<div className="truncate w-80">Sub-Contractor Name</div>
							<div className="truncate w-60">OCTO Number</div>
							<div className="truncate w-60">Sub-Contractor Address</div>
							<div className="truncate w-60">Sub-Contractor Email</div>
							<div className="truncate w-60">Sub-Contractor Phone</div>
							<div className="truncate w-60">Sub-Contractor Website</div>
							<div className="truncate w-60 text-center">Number of Service Locations</div>
						</div>
					</div>

					<div className={`min-w-fit overflow-y-scroll h-[40%] overflow-x-hidden`}>
						{isLoading ? (
							<Loader height="h-12 pt-4" />
						) : subContractorData.length > 0 ? (
							subContractorData.map((subContractor, index) => (
								<div
									className={`flex w-full items-center px-8 transition-colors duration-300 ${
										index < subContractorData.length - 1 ? "border-b border-gray-300" : ""
									} `}
									style={{ height: "45px" }}
								>
									<div className={`w-full flex items-center`}>
										<div className="truncate w-80 h-full">{subContractor.name ?? "--"}</div>
										<div className="w-60 h-full text-cardTextBlue truncate">{subContractor.octoNumber ?? "--"}</div>
										<div className="truncate h-full w-60">{subContractor.address ?? "--"}</div>
										<div className="truncate h-full w-60">{subContractor.generalEmail ?? "--"}</div>
										<div className={`w-60 h-full`}>
											<span
												className={`truncate ${
													subContractor.phoneNumber?.length > 0 ? "hover:text-blueText hover:underline" : ""
												}`}
												onClick={(e) =>
													subContractor.phoneNumber ? handlePhoneClick(e, subContractor.phoneNumber) : ""
												}
											>
												{subContractor.phoneNumber?.length > 0 ? subContractor.phoneNumber : "--"}
											</span>
										</div>
										<div className="truncate h-full w-60">{subContractor.website ?? "--"}</div>
										<div className="truncate h-full w-60 text-center">
											<Link
												to={"/admin/octo-connect/service-locations"}
												className={`${
													subContractor.numOfServicelocations ? "hover:text-blueText hover:underline" : "cursor-default"
												}`}
											>
												{subContractor.numOfServicelocations?.toString() ?? "--"}
											</Link>
										</div>
									</div>
								</div>
							))
						) : (
							<div className="flex min-h-40 text-cardTextGray text-lg justify-center items-center">
								<p>No result found</p>
							</div>
						)}
					</div>
				</div>
				{/* Buttons Section */}
				<div className="flex justify-end p-4 mb-12 mt-12">
					<button
						onClick={handlePreviousBtn}
						className="bg-[#007AFF] text-white px-4 py-2 rounded-full mr-4 border border-[#666666] hover:bg-white hover:text-black hover:border-[#666666] transition-opacity duration-300"
						style={{ width: "184px", height: "44px" }}
					>
						Previous
					</button>
					<button
						onClick={handleNextClick}
						className="bg-[#007AFF] text-white px-4 py-2 rounded-full border border-[#666666] hover:bg-white hover:text-black hover:border-[#666666] transition-opacity duration-300"
						style={{ width: "184px", height: "44px" }}
					>
						Next
					</button>
				</div>
			</div>
		</div>
	);
};

export default Subcontrator_Management;
