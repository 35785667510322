import React from "react";
import { TbDrone, TbReportMoney, TbChartArcs, TbLogout, TbMist, TbNews } from "react-icons/tb";
import { IoSettingsOutline } from "react-icons/io5";
import { PiNotepad, PiTrashBold } from "react-icons/pi";
import { BiChart } from "react-icons/bi";
import { LuUsers } from "react-icons/lu";
import { RiArtboard2Line } from "react-icons/ri";
import { TbTruckDelivery } from "react-icons/tb";
import { FcBiohazard } from "react-icons/fc";
import { LiaBiohazardSolid } from "react-icons/lia";
import { TbFileDollar } from "react-icons/tb";
import { TfiBarChart } from "react-icons/tfi";
import { BsSend } from "react-icons/bs";
import { TbSwitch3 } from "react-icons/tb";
import { FaDribbble } from "react-icons/fa";
import { IoEyeOutline } from "react-icons/io5";
import { TbRouteX2 } from "react-icons/tb";
import { TbUserStar } from "react-icons/tb";
import { TbReplace } from "react-icons/tb";
import { TbReport } from "react-icons/tb";
import { TbFidgetSpinner } from "react-icons/tb";
import { TbTrolley } from "react-icons/tb";

const iconMap = {
	dashboard: TbDrone,
	generator: TbChartArcs,
	submenu_drivers: RiArtboard2Line,
	submenu_treatments: TbReportMoney,
	submenu_routes: TbChartArcs,
	report: TfiBarChart,
	waste: LiaBiohazardSolid,
	gps: BsSend,
	connect: TbSwitch3,
	market: FaDribbble,
	billing: TbFileDollar,
	submenu_transporters: PiNotepad,
	submenu_vendors: PiNotepad,
	user: RiArtboard2Line,
	submenu_users: LuUsers,
	submenu_reports: TbMist,
	submenu_osha: TbNews,
	asset: TbTruckDelivery,
	audit: IoEyeOutline,
	setting: IoSettingsOutline,
	logout: TbLogout,
	submenu_Subcontrator_Management: TbTruckDelivery,
	submenu_Subcontrator_Finder: TbTruckDelivery,
	submenu_ContractorManagement: TbTruckDelivery,
	submenu_Match_Request: TbTruckDelivery,
	submenu_Conflict_Handling: TbTruckDelivery,
	submenu_Subcontrator_Schedules: TbTruckDelivery,
	submenu_octo_mngmt: TbRouteX2,
	submenu_octo_finder: TbUserStar,
	submenu_match_req: TbReplace,
	submenu_schedules: TbReport,
	submenu_handling: TbFidgetSpinner,
	submenu_subcont_mngmt: TbTrolley,
	satelliteLoc: PiNotepad,
};

const iconFillMap = {
	TbDrone: false,
	TbChartArcs: false,
	RiArtboard2Line: true,
	TbReportMoney: false,
	PiTrashBold: true,
	PiNotepad: true,
	LuUsers: false,
	IoSettingsOutline: false,
	TbLogout: false,
	TbMist: false,
	TbSwitch3: false,
	FaDribbble: true,
	TbNews: false,
	BsSend: true,
	BiChart: true,
	TfiBarChart: true,
	TbTruckDelivery: false,
	LiaBiohazardSolid: true,
	TbFileDollar: false,
	TbRouteX2: false,
	TbUserStar: false,
	TbReplace: false,
	TbReport: false,
	TbFidgetSpinner: true,
	TbTrolley: false,
};

const Icon = ({ name, active, type = "", level = 0 }) => {
	const IconComponent = iconMap[name];

	if (!IconComponent) {
		console.warn(`Icon not found for name: ${name}`);
		return null;
	}

	const size = type || level > 0 ? 20 : 24;
	const useFill = iconFillMap[IconComponent.name] ?? false;
	const gradientClass = level === 0 ? "group-hover:fill-[url(#gradient)]" : "";
	const gradientStrokeClass = level === 0 ? "group-hover:stroke-[url(#gradient)]" : "";

	const iconProps = {
		size,
		className: type ? "" : useFill ? gradientClass : gradientStrokeClass,
	};

	if (active) {
		return level === 0 ? <IconComponent {...iconProps} {...(useFill ? { fill: "url(#gradient)" } : { stroke: "url(#gradient)" })} /> : <IconComponent {...iconProps} />;
	}

	return <IconComponent {...iconProps} />;
};

export default Icon;
