import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ProgressBar from "./ProgressBar";
import DropdownReport from "./ReportDropDown";
import ReportCheckbox from "./ReportCheckBox";
import { collection, doc, getDoc, onSnapshot, query, Timestamp, where } from "firebase/firestore";
import { COLLECTIONS, db } from "../../../../../config/firebase";
import { SERVICE_STATUS, serviceTypes } from "../../../../../utils/constants";
import Loader from "../../../../../components/UI/loaders/Loader";
import { toast } from "react-toastify";

// Handler functions
const handleCheckboxChange = (data, setData, index, field) => {
    const newData = [...data];
    newData[index][field] = !newData[index][field];
    setData(newData);
};

const handleInputChange = (data, setData, index, value) => {
    const newData = [...data];
    newData[index].paidValue = value;
    setData(newData);
};

const Reports = () => {
    const [isScrollable, setIsScrollable] = useState(false);
    const [hoveredRow, setHoveredRow] = useState(null);
    const navigate = useNavigate();
    const [completeServices, setCompleteServices] = useState([]);
    const dropdownOptions = ["Option 1", "Option 2", "Option 3"];
    const [isLoading, setIsLoading] = useState(true);
    const [totalServices, setTotalServices] = useState(0);
    const [totalPendingServices, setTotalPendingServices] = useState(0);
    const [totalInprogressServices, setTotalInprogressServices] = useState(0);
    const handleNextClick = () => {
        navigate('servicereporting');
    };

    const handlePerviousbtn = () => {
        console.log("Save button clicked");
        navigate(-1);
    };

    const currentDate = new Date().toLocaleDateString("en-US", {
        weekday: "short",
        month: "numeric",
        day: "numeric",
        year: "numeric"
    });

    const getStringOfServiceTypes = (scheduleData) => {
        if (!scheduleData || !scheduleData.serviceType || !serviceTypes) {
            return "N/A"; // Handle missing data gracefully
        }

        return scheduleData.serviceType.reduce((accumulator, serviceType) => {
            const matchingService = serviceTypes.find(service => service.value === serviceType);
            return accumulator.concat(matchingService ? matchingService.label : "N/A");
        }, []); // Use an empty array as the initial accumulator
    };

    useEffect(() => {
        console.log("getting all servies");
        const startDate = new Date("12-03-2024");
        startDate.setHours(0);
        startDate.setMinutes(0);
        startDate.setSeconds(0);
        const endDate = new Date("12-03-2024");
        endDate.setHours(23);
        endDate.setMinutes(59);
        endDate.setSeconds(59);
        console.log(startDate.toString());
        console.log(endDate.toString());
        const fromDate = Timestamp.fromDate(new Date(startDate));
        const toDate = Timestamp.fromDate(new Date(endDate));
        const q = query(collection(db, "scheduledServices"), where("date", ">=", fromDate),
            where("date", "<=", toDate));
        let unsubscribe = onSnapshot(q, async (snap) => {
            try {
                setIsLoading(true);
                const allCompletedServices = []
                if (snap.docs.length === 0) {
                    toast.info("No complete service found for today.")
                    return
                }
                setTotalServices(snap.docs.length);
                let localPending = 0
                let localInProgress = 0
                const jobs = snap.docs.map(async (serviceSnap) => {

                    const serviceData = serviceSnap.data();
                    console.log({ serviceData });

                    if (serviceData.status !== SERVICE_STATUS.COMPLETE) {
                        if (serviceData.status === SERVICE_STATUS.PENDING) {
                            localPending++;
                        }
                        if (serviceData.status === SERVICE_STATUS.INPROGRESS) {
                            localInProgress++;
                        }
                        return;
                    }
                    const scheduleDataRef = doc(db, `${COLLECTIONS.serviceSchedules}/${serviceData.serviceScheduleId}`);
                    const scheduleRes = await getDoc(scheduleDataRef);
                    if (!scheduleRes.exists()) {
                        return;
                    }
                    const scheduleData = scheduleRes.data();
                    console.log({ scheduleData });
                    const serviceTypeString = getStringOfServiceTypes(scheduleData)
                    const generatorDataRef = doc(db, `${COLLECTIONS.generators}/${serviceData.generatorId}`);
                    const generatorRes = await getDoc(generatorDataRef);
                    if (!generatorRes.exists()) {
                        return;
                    }
                    const generatorData = generatorRes.data();
                    console.log({ generatorData });

                    const routeDataRef = doc(db, `${COLLECTIONS.routes}/${serviceData.routeId}`);
                    const routeRes = await getDoc(routeDataRef);
                    if (!routeRes.exists()) {
                        return;
                    }
                    const routeData = routeRes.data();
                    console.log({ routeData });

                    const driverDataRef = doc(db, `${COLLECTIONS.drivers}/${routeData.driverId}`);
                    const driverRes = await getDoc(driverDataRef);
                    if (!driverRes.exists()) {
                        return;
                    }
                    const driverData = driverRes.data();
                    console.log({ driverData });

                    allCompletedServices.push({
                        id: serviceSnap.id,
                        date: serviceData.date.toDate().toLocaleDateString(),
                        time: serviceData.date.toDate().toLocaleTimeString(),
                        customer: `${generatorData?.generatorName ?? "dummy name"} `,
                        octoNumber: `${generatorData.octoNumber ?? "dummy octo number"}`,
                        email: `${"dummy email"}`,
                        address: `${"dummy address"}`,
                        billed: serviceData?.isBilled ?? false,
                        billedData: serviceData?.billedDate ?? null,
                        paidData: serviceData?.paidDate ?? null,
                        paid: serviceData?.isPaid ?? false,
                        paidAmount: serviceData?.paidAmount ?? "dummy amount",
                        optionChecked: false,
                        driverName: `${driverData?.firstName ?? ""} ${driverData?.lastName ?? ""}`,
                        pickUpTruck: driverData?.truckNumber ?? "dummy number",
                        serviceType: serviceTypeString ?? "dummy service",
                        routeName: routeData?.routeLabel ?? "dummy route name"
                    });
                })
                await Promise.all(jobs);
                console.log({
                    allCompletedServices
                });
                setTotalInprogressServices(localInProgress);
                setTotalPendingServices(localPending);
                setCompleteServices(allCompletedServices);
            } catch (error) {
                console.log(error);

            } finally {
                setIsLoading(false);
            }

        })
        return () => {
            if (unsubscribe) unsubscribe();
        }
    }, [])
    if (isLoading) {
        return <Loader />
    }

    return (
        <>
            <div className="main-container flex flex-col border border-cardBorderCol bg-white rounded-cardRadii h-full">
                <div className="flex justify-between  mx-8 my-3">
                    <div className="text-lg font-normal text-black">Today&apos;s Service Overview</div>
                    <div>{currentDate}</div>
                </div>

                <div className="mx-8 mt-3">
                    <p>Total Service(s): <span>{totalServices}</span></p>
                </div>
                <div className="flex flex-col justify-center items-center mt-10 px-8">

                    <ProgressBar completed={completeServices.length} scheduled={totalInprogressServices} incompleted={totalPendingServices} />
                </div>

                <div className="mt-10">
                    <div className="text-lg font-normal ml-8 mb-2 text-black">Today&apos;s Completed Service Report </div>
                    <div className="flex flex-col md:flex-row justify-between text-sm font-medium pl-8 py-4 bg-indigo-50">
                        <div className="w-full md:w-1/5">Date and Time</div>
                        <div className="w-full md:w-1/5">Generator & Items Details</div>
                        <div className="w-full md:w-1/5">Billed & Paid</div>
                        <div className="w-full md:w-1/5">Pickup Details</div>
                        <div className="w-full md:w-1/5">Options</div>
                    </div>
                </div>

                <div
                    className={`second-child overflow-y-scroll ${isScrollable ? "shadow-inner" : ""}`}
                    onScroll={(e) => setIsScrollable(e.target.scrollTop > 0)}
                >
                    {completeServices.map((item, index) => (
                        <div
                            key={index}
                            className={`flex flex-col md:flex-row items-start py-4 border-b pl-8 border-gray-300 ${hoveredRow === index ? "bg-gray-200" : ""}`}
                            onMouseEnter={() => setHoveredRow(index)}
                            onMouseLeave={() => setHoveredRow(null)}
                            style={{ minHeight: "70px" }}
                        >

                            <div className="w-full md:w-1/5 text-sm font-medium">
                                <div>{item.date}</div>
                                <div className="text-sm font-medium">{item.time}</div>
                            </div>

                            <div className="w-full md:w-1/5 text-sm font-medium">
                                <div className="text-sm font-medium">{item.customer}</div>
                                <div>
                                    <span className="font-medium">Octo Number:</span>{item.octoNumber}
                                </div>
                            </div>

                            <div className="w-full md:w-1/5 text-sm font-medium">
                                <div className="flex mt-1">
                                    <div className="text-sm font-medium items-center">Billed:</div>
                                    <div className="ml-4">
                                        <ReportCheckbox checked={item.billed} onChange={() => handleCheckboxChange(completeServices, setCompleteServices, index, 'billed')} />
                                    </div>
                                    <input
                                        type="text"
                                        disabled
                                        value={item.paidValue}
                                        onChange={(e) => handleInputChange(completeServices, setCompleteServices, index, e.target.value)}
                                        className="ml-2 border border-gray-300 px-2 py-1 w-16 h-6"
                                    />
                                </div>

                                <div className="flex mt-1">
                                    <div className="text-sm font-medium items-center">Paid:</div>
                                    <div className="ml-6">
                                        <ReportCheckbox checked={item.paid} onChange={() => handleCheckboxChange(completeServices, setCompleteServices, index, 'paid')} />
                                    </div>
                                    <input
                                        type="text"
                                        disabled
                                        value={item.paidValue}
                                        onChange={(e) => handleInputChange(completeServices, setCompleteServices, index, e.target.value)}
                                        className="ml-2 border border-gray-300 px-2 py-1 w-16 h-6"
                                    />
                                </div>
                            </div>

                            <div className="w-full md:w-1/5 text-sm font-medium">
                                <div>{item.serviceType}</div>
                                <div>
                                    <span className="text-blue-500">Route:</span>
                                    <span className="ml-4">{item.routeName}</span>
                                </div>
                                <div>
                                    <span className="text-blue-500">Driver:</span>
                                    <span className="ml-4">{item.driverName}</span>
                                </div>
                                <div>
                                    <span className="text-blue-500">Pickup Truck:</span>
                                    <span className="ml-4">{item.pickUpTruck}</span>
                                </div>

                            </div>

                            <div className="w-full md:w-1/5 text-sm font-medium">
                                <div className="mt-2">
                                    <div className="flex text-sm font-medium items-center">
                                        <ReportCheckbox checked={item.optionChecked} onChange={() => handleCheckboxChange(completeServices, setCompleteServices, index, 'optionChecked')} className="ml-4" />
                                        <div className="ml-2">Combined Pdf</div>
                                    </div>
                                    <div className="flex items-center mt-2 text-xs font-medium">
                                        <DropdownReport buttonText="Options" options={dropdownOptions} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            {/* Buttons Section */}
            <div className="flex justify-end p-4 mb-12 mt-12">
                <button
                    onClick={handlePerviousbtn}
                    style={{ width: '184px', height: '44px' }}
                    className="bg-[#007AFF] text-white px-4 py-2 rounded-full mr-4 border border-[#666666] hover:bg-white hover:text-black hover:border-[#666666] transition-opacity duration-300"
                >
                    Previous
                </button>
                <button
                    onClick={handleNextClick}
                    style={{ width: '184px', height: '44px' }}
                    className="bg-[#007AFF] text-white px-4 py-2 rounded-full border border-[#666666] hover:bg-white hover:text-black hover:border-[#666666] transition-opacity duration-300"
                >
                    Next
                </button>
            </div>
        </>
    );
};

export default Reports;
