import { ChevronLeft, ChevronRight } from "lucide-react";
import React, { useState } from "react";
import DatePicker from "react-datepicker";
import { PiCalendarDotsLight } from "react-icons/pi";
import "react-datepicker/dist/react-datepicker.css";
import CustomDobHeader from '../../../src/components/UI/CustomDobHeader';

const DOBPicker = ({ selectedDate, setSelectedDate, lastDatetoday = false }) => {
	const CustomInputForCalendar = React.forwardRef(({ value, onClick }, ref) => (
		<div
			className="bg-inputBg rounded-full hover:cursor-pointer text-cardTextGray py-2 px-4 h-9 justify-between gap-4 flex items-center"
			onClick={onClick}
			ref={ref}
		>
			<span className="text-cardTextGray">{value}</span>
			<PiCalendarDotsLight className="text-cardTextGray" size={20} />
		</div>
	));

	const handleMonthChange = (month) => {
		const newDate = new Date(selectedDate);
		newDate.setMonth(month);
		setSelectedDate(newDate);
	};

	const handleYearChange = (year) => {
		const newDate = new Date(selectedDate);
		newDate.setFullYear(year);
		setSelectedDate(newDate);
	};

	// Year scrolling logic
	const currentYear = new Date().getFullYear();
	const maxYear = 2044; // Set to your desired maximum year
	const totalYears = maxYear - currentYear + 1;
	const [startYearIndex, setStartYearIndex] = useState(0);

	const displayedYears = Array.from({ length: 10 }, (_, index) => currentYear + startYearIndex + index).slice(0, totalYears - startYearIndex < 10 ? totalYears - startYearIndex : 10);

	const handleNextYears = () => {
		if (startYearIndex + 10 < totalYears) {
			setStartYearIndex(startYearIndex + 10);
		}
	};

	const handlePrevYears = () => {
		if (startYearIndex - 10 >= 0) {
			setStartYearIndex(startYearIndex - 10);
		}
	};

	return (
		<DatePicker
			selected={selectedDate}
			onChange={(date) => setSelectedDate(date)}
			customInput={<CustomInputForCalendar />}
			renderCustomHeader={(headerProps) => (
				<CustomDobHeader
					{...headerProps}
					onMonthChange={handleMonthChange}
					onYearChange={handleYearChange}
					yearScrollButtons={
						<div className="flex justify-between">
							<button
								className="p-2"
								onClick={handlePrevYears}
								disabled={startYearIndex === 0}
							>
								<ChevronLeft />
							</button>
							<button
								className="p-2"
								onClick={handleNextYears}
								disabled={startYearIndex + 10 >= totalYears}
							>
								<ChevronRight />
							</button>
						</div>
					}
				/>
			)}
			dateFormat="MM/dd/yyyy"
			showPopperArrow={false}
			maxDate={lastDatetoday ? new Date() : null}
			showMonthDropdown
			showYearDropdown
			dropdownMode="select"
			scrollableYearDropdown
			yearDropdownItemNumber={10}
		/>
	);
};

export default DOBPicker;
