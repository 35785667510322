import { AnimatePresence, motion } from "framer-motion";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { FiSearch } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import debounce from "lodash.debounce";

const SearchBox = () => {
	const [query, setQuery] = useState("");
	const [results, setResults] = useState({});
	const [isModalOpen, setIsModalOpen] = useState(false);
	const navigate = useNavigate();
	const inputRef = useRef(null);

	// Data from Redux store
	const { list: generators } = useSelector((state) => state.generator);

	const searchFields = [
		"generatorName",
		"generatorEmail",
		"generatorPhone",
		"octoNumber",
		"internalAccountNumber",
		"serviceAddress",
		"billingAddress",
		"customerType",
		"serviceType",
		"generatorStatus",
	];

	const useDebouncedSearch = (searchFunction, delay = 300) => {
		return useCallback(
			debounce((query) => {
				searchFunction(query);
			}, delay),
			[searchFunction]
		);
	};

	const debouncedSearch = useDebouncedSearch((query) => {
		if (query.trim() !== "") {
			const searchResults = {
				generators: searchItems(generators, query),
			};
			setResults(searchResults);
		} else {
			setResults({});
		}
	});

	useEffect(() => {
		debouncedSearch(query);
	}, [query, generators]);

	const searchItems = (items, searchQuery) => {
		const lowerQuery = searchQuery.toLowerCase().trim();
		return items
			.map((item) => {
				const matches = {};
				let isMatch = false;

				searchFields.forEach((field) => {
					const value = item[field];
					if (value !== undefined && value !== null) {
						if (field === "serviceType") {
							// ServiceType specifically
							if (Array.isArray(value)) {
								const matchedItemsAreOrNot = value.some((v) => v.toLowerCase()?.includes(lowerQuery));
								if (matchedItemsAreOrNot) {
									matches[field] = highlightMatch(`[${value}]`, lowerQuery);
									console.log({ field, value, matched: matches[field] });
									isMatch = true;
								}
							}
							console.log(matches[field]);
							// Ignoring if it's a string (incorrect data)
						} else if (Array.isArray(value)) {
							const matchedItemsAreOrNot = value.some((v) => v.toLowerCase()?.includes(lowerQuery));
							if (matchedItemsAreOrNot) {
								matches[field] = highlightMatch(`[${value}]`, lowerQuery);
								console.log({ field, value, matched: matches[field] });
								isMatch = true;
							}
						} else if (typeof value === "string" || typeof value === "number") {
							const stringValue = value.toString().toLowerCase();
							if (stringValue.includes(lowerQuery)) {
								matches[field] = highlightMatch(value.toString(), lowerQuery);
								isMatch = true;
							}
						} else if (typeof value === "object") {
							Object.entries(value).forEach(([subKey, subValue]) => {
								if ((typeof subValue === "string" || typeof subValue === "number") && subValue.toString().toLowerCase().includes(lowerQuery)) {
									matches[`${field}.${subKey}`] = highlightMatch(subValue.toString(), lowerQuery);
									isMatch = true;
								}
							});
						}
					}
				});

				return isMatch ? { ...item, matches } : null;
			})
			.filter(Boolean)
			.slice(0, 10);
	};

	const highlightMatch = (text, query) => {
		const parts = text.split(new RegExp(`(${query})`, "gi"));
		return (
			<span>
				{parts.map((part, index) =>
					part.toLowerCase() === query.toLowerCase() ? (
						<span key={index} className="bg-blue-200">
							{part}
						</span>
					) : (
						part
					)
				)}
			</span>
		);
	};

	const handleSearch = (e) => {
		setQuery(e.target.value);
	};

	const handleKeyDown = (e) => {
		if (e.key === "Escape") {
			setIsModalOpen(false);
			inputRef.current.blur();
		}
	};

	const itemClickHandler = (item, category) => {
		setIsModalOpen(false);
		navigate(`/admin/${category}/${item.id}`);
	};

	return (
		<div className="relative z-50">
			<div className="flex items-center py-2 z-50 gap-4 w-full bg-white rounded-full px-5 border border-gray-200 h-12 relative">
				<FiSearch className="text-cardTextGray" size={20} />
				<input
					type="text"
					placeholder="Global search..."
					onKeyDown={handleKeyDown}
					ref={inputRef}
					value={query}
					onChange={handleSearch}
					onFocus={() => setIsModalOpen(true)}
					className="bg-transparent focus:outline-none w-full text-cardTextGray h-full"
				/>
			</div>

			{isModalOpen && <div className="fixed inset-0 bg-[#CCCCCC87] z-40" onClick={() => setIsModalOpen(false)}></div>}

			{isModalOpen && (
				<div className="absolute top-full left-0 min-w-full w-fit bg-white shadow-lg mr-1 rounded-cardRadii mt-2 z-50">
					<AnimatePresence>
						<motion.div
							className="max-h-[85vh] overflow-auto"
							initial={{ height: 0, opacity: 0 }}
							animate={{ height: "auto", opacity: 1 }}
							exit={{ height: 0, opacity: 0 }}
							transition={{ duration: 0.25 }}
						>
							{Object.entries(results).map(([category, items]) => (
								<div key={category}>
									{items.length > 0 && (
										<>
											<h3 className="px-4 py-2 font-semibold rounded-cardRadii bg-gray-100">{category.charAt(0).toUpperCase() + category.slice(1)}</h3>
											<ul>
												{items.map((item, index) => (
													<li
														key={index}
														className="pl-8 pr-4 py-2 hover:bg-[#BBBBBB3B] cursor-pointer transition-colors duration-150"
														onClick={() => itemClickHandler(item, category)}
													>
														<div>{item.generatorName || item.generatorEmail}</div>
														<div className="text-sm text-gray-500">
															{Object.entries(item.matches).map(([key, value], i) => {
																console.log({ key, value });

																return (
																	<div key={i}>
																		<span className="font-semibold">{key}: </span>
																		{Array.isArray(value) ? value.join(", ") : value}
																	</div>
																);
															})}
														</div>
													</li>
												))}
											</ul>
										</>
									)}
								</div>
							))}
							{query.length > 0 && Object.values(results).every((items) => items.length === 0) && <li className="px-4 py-2 text-cardTextGray">No results found</li>}
							{query.length === 0 && <li className="px-4 py-2 text-cardTextGray">Start typing...</li>}
						</motion.div>
					</AnimatePresence>
				</div>
			)}
		</div>
	);
};

export default SearchBox;
