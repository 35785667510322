import React, { useState, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { RxCross2 } from "react-icons/rx";
import Input from "./UI/Input";
import Dropdown from "./UI/dropdowns/Dropdown";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Loader from "./UI/loaders/Loader";
import { PostDriverDetails, GetNewDriverId, getAllTransporters } from "../utils/firebaseOperations";

const driverStatuses = [
    { label: "Active", value: "active" },
    { label: "Inactive", value: "inactive" }
];

const driverLocations = [
    { label: "Location 1", value: "location1" },
    { label: "Location 2", value: "location2" },
    { label: "Location 3", value: "location3" }
];



const MiniDriverRegistrationForm = ({ onClose, activeSubmenu }) => {
    const {
        control,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm({
        defaultValues: {
            firstName: "",
            middleInitial: "",
            lastName: "",
            OctoDriverId: "",
            email: "",
            driverLocation: "",
            driverStatus: "active",
        },
    });

    const [TransPorterOptions, setTransportorOptions] = useState([])
    const [inProgress, setInProgress] = useState(false);
    const navigate = useNavigate();
    const [DriverOctoDriverId, setDriverOctoDriverId] = useState('');



    useEffect(() => {
        getTransportordataOptions()
    }, [])

    const getTransportordataOptions = async () => {
        const dataWeGot = await getAllTransporters()
        console.log('datawegot', dataWeGot)
        const OptionsForDropdown = dataWeGot.map(({ name }) => ({ label: name, value: name }))
        setTransportorOptions(OptionsForDropdown)
    }


    useEffect(() => {
        const fetchDriverId = async () => {
            try {
                const driverIdavailable = await GetNewDriverId();
                setDriverOctoDriverId(driverIdavailable);
                setValue('OctoDriverId', driverIdavailable);
            } catch (error) {
                console.error('Error fetching driver ID:', error);
            }
        };

        fetchDriverId();
    }, [setValue]);

    const onSubmitForm = async (data) => {
        const fullData = { ...data, OctoDriverId: DriverOctoDriverId };

        if (activeSubmenu === "driver") {
            toast.loading("Saving...");
            setInProgress(true);
            try {
                console.log('fullData', fullData);
                let result = await PostDriverDetails(fullData);
                console.log('saved', result)
                toast.dismiss();
                onClose();
                navigate(`/admin/assets/drivers/${data.OctoDriverId}/edit`);
            } catch (error) {
                toast.dismiss();
                console.error('Error saving driver details:', error);
                toast.error("Failed to save driver details. Please try again.");
                setInProgress(false);
            }
        }
    };

    return (
        <div className="z-40 fixed inset-0 bg-[#CCCCCC87] bg-opacity-50 flex justify-center items-center ">
            <div className="bg-white z-20 rounded-cardRadii max-w-md w-full min-h-fit">
                <form onSubmit={handleSubmit(onSubmitForm)} className="flex flex-col justify-between min-h-80 p-6">
                    <div className="flex justify-between pb-2">
                        <div className="flex items-center gap-4">
                            <h6 className="font-bold text-xl text-black">Add New Driver</h6>
                        </div>
                        <button
                            type="button"
                            disabled={inProgress}
                            className={`flex justify-center items-center h-fit w-fit p-1 rounded-full border shadow-md ${inProgress ? "" : "hover:bg-gray-200"}`}
                            onClick={onClose}
                        >
                            <RxCross2 size={20} className={`${!inProgress ? "hover:cursor-pointer" : ""}`} />
                        </button>
                    </div>

                    <div className='overflow-auto max-h-[60vh] px-2'>
                        <Controller
                            name="firstName"
                            control={control}
                            rules={{ required: "First name is required" }}
                            render={({ field: { onChange, value } }) => <Input label="First Name" value={value} onChange={onChange} style="flex-col" isRequired />}
                        />
                        {errors.firstName && <p className="text-red-500 text-sm mt-1">{errors.firstName.message}</p>}

                        <Controller
                            name="middleInitial"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <Input
                                    label="Middle Initial"
                                    value={value.toUpperCase()}
                                    onChange={(e) => onChange(e.target.value.slice(0, 1).toUpperCase())}
                                    style="flex-col"
                                />
                            )}
                        />

                        <Controller
                            name="lastName"
                            control={control}
                            rules={{ required: "Last name is required" }}
                            render={({ field: { onChange, value } }) => <Input label="Last Name" value={value} onChange={onChange} style="flex-col" isRequired />}
                        />
                        {errors.lastName && <p className="text-red-500 text-sm mt-1">{errors.lastName.message}</p>}
                        <Controller
                            name="OctoDriverId"
                            control={control}
                            render={({ field: { value } }) => (
                                <Input label="OCTO ID #" value={DriverOctoDriverId} style="flex-col" />
                                // readOnly={true}
                            )}
                        />


                        <Controller
                            name="email"
                            control={control}
                            rules={{
                                required: "Email is required",
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                    message: "Invalid email address",
                                },
                            }}
                            render={({ field: { onChange, value } }) => <Input label="Email" value={value} onChange={onChange} style="flex-col" isRequired />}
                        />
                        {errors.email && <p className="text-red-500 text-sm mt-1">{errors.email.message}</p>}

                        <Controller
                            name="driverLocation"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <Dropdown label="Driver Location Assignment" options={TransPorterOptions ? TransPorterOptions : driverLocations} value={value} onChange={onChange} styles="flex-col min-w-full" />
                            )}
                        />

                        <Controller
                            name="driverStatus"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <Dropdown label="Driver Status" options={driverStatuses} value={value} onChange={onChange} styles="flex-col min-w-full" />
                            )}
                        />
                    </div>

                    <button
                        type="submit"
                        className={`capitalize py-2 my-5 text-center px-5 gap-2 hover:opacity-90 ${inProgress ? "bg-cardTextGray bg-opacity-20" : "bg-dashBtnGradient"} w-full rounded-full text-white`}
                    >
                        {inProgress ? <Loader height="h-auto" /> : `Add New Driver`}
                    </button>
                </form>
            </div>
        </div>
    );
};

export default MiniDriverRegistrationForm;
