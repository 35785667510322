import React, { useEffect, useState } from "react";
import { MdCheck, MdContentCopy } from "react-icons/md";
import { formattedDate } from "../../../../../../../../../utils/helpers";
import { updatePriorityOfScheduledService } from "../../../../../../../../../utils/firebaseOperations";
import { toast } from "react-toastify";
import Loader from "../../../../../../../../../components/UI/loaders/Loader";

const GenRouteFooter = ({ isLoadingServices, pendingScheduledService, completedScheduledService }) => {
	const [copied, setCopied] = useState(false);
	const [priorityMap, setPriorityMap] = useState({});

	const handleCopyToClipboard = async () => {
		try {
			let dates = pendingScheduledService.map((scheduledService) => formattedDate(scheduledService.date));
			await navigator.clipboard.writeText(dates?.join("; "));
			setCopied(true);
			setTimeout(() => {
				setCopied(false);
			}, 1000);
		} catch (err) {
			console.error("Failed to copy:", err);
		}
	};

	useEffect(() => {
		let map = {};
		pendingScheduledService.map((item) => {
			map[item.id] = item.isPriority ?? false;
		});
		setPriorityMap(map);
	}, [pendingScheduledService]);

	const markAsPriorityHandler = async (scheduledServiceId, e) => {
		const isChecked = e.target.checked;
		setPriorityMap((prevState) => ({
			...prevState,
			[scheduledServiceId]: isChecked,
		}));

		let resp = await updatePriorityOfScheduledService(scheduledServiceId, isChecked);
		if (resp.success) toast.success(resp.message);
		else toast.error(resp.message);
	};

	return (
		<div>
			<div className="">
				<h6 className="font-medium py-2 text-lg">Scheduled Services</h6>
				<div className="grid grid-cols-5 gap-4 bg-[#E5F2FF] px-8 font-medium py-4">
					<div>Date</div>
					<div>Route</div>
					<div className="text-center">Stops</div>
					<div>Service Note</div>
					<div>Type</div>
				</div>
				<div className="max-h-[65vh] overflow-y-scroll">
					{isLoadingServices ? (
						<Loader height="h-12 pt-4" />
					) : pendingScheduledService?.length > 0 ? (
						pendingScheduledService.map((scheduledService, index) => (
							<div key={scheduledService?.id ?? index} className={`grid grid-cols-5 gap-4 border-b border-[##CCCCCC] px-8 font-base text-cardTextGray py-4`}>
								<div className="flex items-center gap-2">
									<div className="relative group flex items-center">
										<input
											type="checkbox"
											className="w-4 h-4 bg-white"
											checked={priorityMap[scheduledService?.id] || false}
											onChange={(e) => markAsPriorityHandler(scheduledService?.id, e)}
										/>
										<div className="absolute hidden group-hover:flex top-full ml-2 mt-1 min-w-[120px] text-center left-0 bg-gray-700 text-white text-sm rounded px-2 py-1 z-10">
											Mark as priority
										</div>
									</div>
									<h6>{formattedDate(new Date(scheduledService.date))}</h6>
								</div>
								<div>{scheduledService.route?.routeLabel ?? "--"}</div>
								<div className="text-center">{scheduledService.numberOfStops ?? "--"}</div>
								<div>{scheduledService.serviceSchedule?.serviceNote ?? "--"}</div>
								<div>{scheduledService.serviceSchedule?.serviceType?.toString()}</div>
							</div>
						))
					) : (
						<div className="w-full text-center py-4 text-cardTextGray">No upcoming services</div>
					)}
				</div>
			</div>
			<div className="flex justify-end pt-6 px-8">
				<button
					type="button"
					className="group bg-[#E5F2FF] px-8 py-2.5 w-96 justify-center hover:bg-blue-300 transition-colors duration-200 rounded-full flex gap-2"
					onClick={() => !copied && handleCopyToClipboard()}
				>
					{copied ? (
						<>
							<MdCheck size={24} className="group-hover:fill-cardTextBlue hover:cursor-pointer" />
							<h6 className="group-hover-">Copied to Clipboard!</h6>
						</>
					) : (
						<>
							<MdContentCopy size={24} className="group-hover:fill-cardTextBlue hover:cursor-pointer" />
							<h6 className="group-hover-">Copy Service Dates to Clipboard</h6>
						</>
					)}
				</button>
			</div>
			<div className="py-4">
				<h6 className="font-medium py-2 text-lg">Completed Services</h6>
				<div className="grid grid-cols-6 gap-4 overflow-auto min-w-fit bg-[#E5F2FF] px-8 font-medium py-4">
					<div className="min-w-60">Date</div>
					<div className="min-w-60">Route</div>
					<div className="min-w-60">Driver</div>
					<div className="min-w-60">Quantity</div>
					<div className="min-w-60">Service</div>
					<div className="min-w-60">Manifest</div>
				</div>
				<div className="max-h-[65vh] overflow-y-scroll">
					{isLoadingServices ? (
						<Loader height="h-12 pt-4" />
					) : completedScheduledService?.length > 0 ? (
						completedScheduledService.map((scheduledService, index) => (
							<div key={scheduledService?.id ?? index} className={`grid grid-cols-5 gap-4 border-b border-[##CCCCCC] px-8 font-base text-cardTextGray py-4`}>
								<div className="flex items-center gap-2">{formattedDate(scheduledService.date)}</div>
								<div>{scheduledService.route?.routeLabel}</div>
								<div>Stops</div>
								<div>{scheduledService.serviceSchedule?.notes}</div>
								<div>{scheduledService.serviceSchedule?.serviceType?.toString()}</div>
							</div>
						))
					) : (
						<div className="w-full text-center py-4 text-cardTextGray">No completed services</div>
					)}
				</div>
			</div>
			<div className="pt-8">
				<h6 className="font-medium text-lg">Stats For Nerds</h6>
				<h6 className="font-medium text-lg">The Last 25 Services</h6>
				{isLoadingServices ? (
					<Loader height="h-12 pt-4" />
				) : []?.length > 0 ? (
					[].map((stats, index) => (
						<div key={scheduledService?.id ?? index} className={`grid grid-cols-5 gap-4 border-b border-[##CCCCCC] px-8 font-base text-cardTextGray py-4`}>
							<div className="flex items-center gap-2">{formattedDate(scheduledService.date)}</div>
							<div>{scheduledService.route?.routeLabel}</div>
							<div>Stops</div>
							<div>{scheduledService.serviceSchedule?.notes}</div>
							<div>{scheduledService.serviceSchedule?.serviceType?.toString()}</div>
						</div>
					))
				) : (
					<div className="w-full py-2 text-cardTextGray">No data</div>
				)}
			</div>
		</div>
	);
};

export default GenRouteFooter;
