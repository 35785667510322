import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ReportCheckbox from "./ReportCheckBox";
import CustomDatePicker from "../../../../../components/UI/CustomDatePicker";
import Loader from "../../../../../components/UI/loaders/Loader";
import { collection, doc, getDoc, getDocs, onSnapshot, orderBy, query, Timestamp, where } from "firebase/firestore";
import { COLLECTIONS, db } from "../../../../../config/firebase";
import { SERVICE_STATUS, serviceTypes } from "../../../../../utils/constants";
import DropdownReport from "./ReportDropDown";
import { showErrorToastMessage } from "../../../../../utils/helpers";
import MultiselectDropdown from "../../../../../components/UI/dropdowns/MultiselectDropdown";


const sortByOptions = [{ label: "Any", value: "any" }, { label: "By Date (asc)", value: "date_asc" }, { label: "By Date (desc)", value: "date_desc" }, { label: "By Generator(A-Z)", value: "generator_asc" }, { label: "By Generator(Z-A)", value: "generator_desc" }, { label: "By Route(A-Z)", value: "route" }, { label: "By Route(Z-A)", value: "route_desc" }]


const handleCheckboxChange = (data, setData, index, field) => {
    const newData = [...data];
    newData[index][field] = !newData[index][field];
    setData(newData);
};

const handleInputChange = (data, setData, index, value) => {
    const newData = [...data];
    newData[index].paidValue = value;
    setData(newData);
};

const ServiceReporting = () => {
    const [hoveredRow, setHoveredRow] = useState(null);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [completeServices, setCompleteServices] = useState([]);

    const [from, setFrom] = useState(() => new Date("12-01-2023"));
    const [to, setTo] = useState(new Date("12-01-2024"));
    const dropdownOptions = ["Edit", "Delete", "Share"];
    const [selectedTreatments, setSelectedTreatments] = useState([]);
    const [selectedSort, setSelectedSort] = useState("any");
    const [allTreatments, setAllTreatments] = useState([{ label: "Loading", value: null }]);
    const handleNextClick = () => {
        navigate('drop-off-report');
    };

    const handlePerviousButton = () => {
        navigate(-1);
    };

    useEffect(() => {
        const treatmentRef = collection(db, COLLECTIONS.treatments);
        const unsubscribe = onSnapshot(treatmentRef, async (snap) => {
            const list = [{ label: "All", value: "all" }];
            snap.docs.forEach((treatmentSnap) => {
                if (!treatmentSnap.exists()) {
                    return;
                }
                const data = treatmentSnap.data();
                list.push({ label: data.name, value: treatmentSnap.id });
            })
            setAllTreatments(list);
        })

        return () => {
            if (unsubscribe) unsubscribe();
        }
    }, [])



    const getStringOfServiceTypes = (scheduleData) => {
        if (!scheduleData || !scheduleData.serviceType || !serviceTypes) {
            return "N/A"; // Handle missing data gracefully
        }

        return scheduleData.serviceType.reduce((accumulator, serviceType) => {
            const matchingService = serviceTypes.find(service => service.value === serviceType);
            return accumulator.concat(matchingService ? matchingService.label : "N/A");
        }, []); // Use an empty array as the initial accumulator
    };


    const viewButtonHandler = async () => {
        console.log("getting all services");
        if (!from) {
            showErrorToastMessage("Please select from date.")
        }
        if (!to) {
            showErrorToastMessage("Please select to date.")
        }
        const fromDate = Timestamp.fromDate(new Date(from));
        const toDate = Timestamp.fromDate(new Date(to));
        let q = query(collection(db, "scheduledServices"), where("date", ">=", fromDate),
            where("date", "<=", toDate));
        q = query(q, where("status", "==", SERVICE_STATUS.COMPLETE));
        if (selectedSort.length > 0 && selectedSort === "date_desc") {
            q = query(q, orderBy("date", "desc"))
        }
        if (selectedSort.length > 0 && selectedSort === "date_asc") {
            q = query(q, orderBy("date", "asc"))
        }

        try {

            const snap = await getDocs(q);
            if (snap.docs.length < 1) {
                showErrorToastMessage("No completed services found in given range.")
            }
            setIsLoading(true);
            console.log({ "items": snap.docs.length });

            const allCompletedServices = []
            const jobs = snap.docs.map(async (serviceSnap) => {

                const serviceData = serviceSnap.data();
                // console.log({ serviceData });

                const routeDataRef = doc(db, `${COLLECTIONS.routes}/${serviceData.routeId}`);
                const routeRes = await getDoc(routeDataRef);
                if (!routeRes.exists()) {
                    return;
                }
                const routeData = routeRes.data();
                // console.log({ routeData });
                let isExist = false;
                if (selectedTreatments.length > 0) {
                    selectedTreatments.forEach(value => {
                        if (value === routeData.treatmentId) {
                            isExist = true;
                        }
                    })
                }
                if (selectedTreatments.length > 0 && !isExist) {
                    return;
                }
                const scheduleDataRef = doc(db, `${COLLECTIONS.serviceSchedules}/${serviceData.serviceScheduleId}`);
                const scheduleRes = await getDoc(scheduleDataRef);
                if (!scheduleRes.exists()) {
                    return;
                }
                const scheduleData = scheduleRes.data();
                // console.log({ scheduleData });
                const serviceTypeString = getStringOfServiceTypes(scheduleData)
                const generatorDataRef = doc(db, `${COLLECTIONS.generators}/${serviceData.generatorId}`);
                const generatorRes = await getDoc(generatorDataRef);
                if (!generatorRes.exists()) {
                    return;
                }
                const generatorData = generatorRes.data();
                // console.log({ generatorData });

                const driverDataRef = doc(db, `${COLLECTIONS.drivers}/${routeData.driverId}`);
                const driverRes = await getDoc(driverDataRef);
                if (!driverRes.exists()) {
                    return;
                }
                const driverData = driverRes.data();
                // console.log({ driverData });


                allCompletedServices.push({
                    id: serviceSnap.id,
                    date: serviceData.date.toDate().toLocaleDateString(),
                    time: serviceData.date.toDate().toLocaleTimeString(),
                    customer: `${generatorData?.generatorName ?? "dummy name"} `,
                    octoNumber: `${generatorData.octoNumber ?? "dummy octo number"}`,
                    email: `${"dummy email"}`,
                    address: `${"dummy address"}`,
                    billed: serviceData?.isBilled ?? false,
                    billedData: serviceData?.billedDate ?? null,
                    paidData: serviceData?.paidDate ?? null,
                    paid: serviceData?.isPaid ?? false,
                    paidAmount: serviceData?.paidAmount ?? "dummy amount",
                    optionChecked: false,
                    driverName: `${driverData?.firstName ?? ""} ${driverData?.lastName ?? ""}`,
                    pickUpTruck: driverData?.truckNumber ?? "dummy number",
                    serviceType: serviceTypeString ?? "dummy service",
                    routeName: routeData?.routeLabel ?? "dummy route name"
                });
            })
            await Promise.all(jobs);
            if (selectedSort.length > 0 && selectedSort === "generator_asc") {
                allCompletedServices.sort((a, b) => a.customer.localeCompare(b.customer));
            }
            if (selectedSort.length > 0 && selectedSort === "generator_desc") {
                allCompletedServices.sort((a, b) => b.customer.localeCompare(a.customer));
            }
            if (selectedSort.length > 0 && selectedSort === "route_asc") {
                allCompletedServices.sort((a, b) => a.routeName.localeCompare(b.customer));
            }
            if (selectedSort.length > 0 && selectedSort === "route_desc") {
                allCompletedServices.sort((a, b) => b.routeName.localeCompare(a.customer));
            }
            console.log({
                allCompletedServices
            });
            setCompleteServices(allCompletedServices);
        } catch (error) {
            console.log(error);

        } finally {
            setIsLoading(false);
        }

    }



    if (isLoading) {
        return <Loader />

    }

    return (
        <div className="flex flex-col border-cardBorderCol bg-white rounded-cardRadii h-full">
            <div className="flex-grow overflow-y-auto">
                <div className="text-lg font-normal mx-8 my-3 text-black">Service Reporting</div>
                <hr />
                <div className="flex mx-8 my-5">
                    <div className="flex basis-[80%] gap-5 items-end">
                        <div className="flex flex-col min-w-48">
                            <p className="text-sm text-gray-500 px-2">From</p>
                            <CustomDatePicker selectedDate={from} setSelectedDate={(value) => setFrom(new Date(value))
                            } label={"From Date"} />
                        </div>
                        <div className="flex flex-col min-w-48">
                            <p className="text-sm text-gray-500 px-2">To</p>
                            <CustomDatePicker selectedDate={to} setSelectedDate={(value) => setTo(new Date(value))} label={"To Date"} />
                        </div>
                        <div className="flex flex-col min-w-80 ">

                            <MultiselectDropdown
                                width="w-80"
                                buttonText="Treatment"
                                options={allTreatments}
                                selectedOptions={selectedTreatments}
                                onSelectedOptionsChange={(values) => setSelectedTreatments(values)
                                }
                            />
                        </div>
                        <div className="flex min-w-48 ">
                            <div className="border border-gray-300 bg-gray-100 rounded-l-lg py-2 px-4 ">
                                Sort
                            </div>
                            <select className="border border-gray-300 rounded-r-lg p-2 w-full" onChange={(e) => setSelectedSort(e.currentTarget.value)} value={selectedSort}>
                                {sortByOptions.map(option => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </select>
                        </div>

                    </div>
                    <div className="self-end ml-auto">
                        <button onClick={viewButtonHandler} className="bg-[#007AFF] text-white px-4 py-2 rounded-full mr-4 border border-[#666666] hover:bg-white hover:text-black hover:border-[#666666] transition-opacity duration-300 min-w-36">
                            View
                        </button>
                    </div>
                </div>
                <div className="flex flex-col md:flex-row justify-between text-sm font-medium pl-8 py-4 bg-indigo-50">
                    <div className="w-full md:w-1/5">Date and Time</div>
                    <div className="w-full md:w-1/5">Generator & Items Details</div>
                    <div className="w-full md:w-1/5">Billed & Paid</div>
                    <div className="w-full md:w-1/5">Pickup Details</div>
                    <div className="w-full md:w-1/5">Options</div>
                </div>
                {completeServices.map((item, index) => (
                    <div
                        key={index}
                        className={`flex flex-col md:flex-row items-start py-4 border-b pl-8 border-gray-300 ${hoveredRow === index ? "bg-gray-200" : ""}`}
                        onMouseEnter={() => setHoveredRow(index)}
                        onMouseLeave={() => setHoveredRow(null)}
                        style={{ minHeight: "70px" }}
                    >

                        <div className="w-full md:w-1/5 text-sm font-medium">
                            <div>{item.date}</div>
                            <div className="text-sm font-medium">{item.time}</div>
                        </div>

                        <div className="w-full md:w-1/5 text-sm font-medium">
                            <div className="text-sm font-medium">{item.customer}</div>
                            <div>
                                <span className="font-medium">Octo Number:</span>{item.octoNumber}
                            </div>
                        </div>

                        <div className="w-full md:w-1/5 text-sm font-medium">
                            <div className="flex mt-1">
                                <div className="text-sm font-medium items-center">Billed:</div>
                                <div className="ml-4">
                                    <ReportCheckbox checked={item.billed} onChange={() => handleCheckboxChange(completeServices, setCompleteServices, index, 'billed')} />
                                </div>
                                <input
                                    type="text"
                                    disabled
                                    value={item.paidValue}
                                    onChange={(e) => handleInputChange(completeServices, setCompleteServices, index, e.target.value)}
                                    className="ml-2 border border-gray-300 px-2 py-1 w-16 h-6"
                                />
                            </div>

                            <div className="flex mt-1">
                                <div className="text-sm font-medium items-center">Paid:</div>
                                <div className="ml-6">
                                    <ReportCheckbox checked={item.paid} onChange={() => handleCheckboxChange(completeServices, setCompleteServices, index, 'paid')} />
                                </div>
                                <input
                                    type="text"
                                    disabled
                                    value={item.paidValue}
                                    onChange={(e) => handleInputChange(completeServices, setCompleteServices, index, e.target.value)}
                                    className="ml-2 border border-gray-300 px-2 py-1 w-16 h-6"
                                />
                            </div>
                        </div>

                        <div className="w-full md:w-1/5 text-sm font-medium">
                            <div>{item.serviceType}</div>
                            <div>
                                <span className="text-blue-500">Route:</span>
                                <span className="ml-4">{item.routeName}</span>
                            </div>
                            <div>
                                <span className="text-blue-500">Driver:</span>
                                <span className="ml-4">{item.driverName}</span>
                            </div>
                            <div>
                                <span className="text-blue-500">Pickup Truck:</span>
                                <span className="ml-4">{item.pickUpTruck}</span>
                            </div>

                        </div>

                        <div className="w-full md:w-1/5 text-sm font-medium">
                            <div className="mt-2">
                                <div className="flex text-sm font-medium items-center">
                                    <ReportCheckbox checked={item.optionChecked} onChange={() => handleCheckboxChange(completeServices, setCompleteServices, index, 'optionChecked')} className="ml-4" />
                                    <div className="ml-2">Combined Pdf</div>
                                </div>
                                <div className="flex items-center mt-2 text-xs font-medium">
                                    <DropdownReport buttonText="Options" options={dropdownOptions} />
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            {/* Buttons Section */}
            <div className="flex justify-end p-4 mb-12 mt-12">
                <button
                    onClick={handlePerviousButton}
                    style={{ width: '184px', height: '44px' }}
                    className="bg-[#007AFF] text-white px-4 py-2 rounded-full mr-4 border border-[#666666] hover:bg-white hover:text-black hover:border-[#666666] transition-opacity duration-300"
                >
                    Previous
                </button>
                {/* <button
                    onClick={handleNextClick}
                    style={{ width: '184px', height: '44px' }}
                    className="bg-[#007AFF] text-white px-4 py-2 rounded-full border border-[#666666] hover:bg-white hover:text-black hover:border-[#666666] transition-opacity duration-300"
                >
                    Next
                </button> */}
            </div>
        </div >
    );
};

export default ServiceReporting;


// useEffect(() => {
//     console.log("getting all servies");
//     // console.log();
//     // console.log(to.toLocaleDateString());

//     const fromDate = Timestamp.fromDate(new Date(from));
//     const toDate = Timestamp.fromDate(new Date(to));

//     const q = query(collection(db, "scheduledServices"), where("status", "==", SERVICE_STATUS.COMPLETE), where("date", ">=", fromDate),
//         where("date", "<=", toDate))
//     let unsubscribe = onSnapshot(q, async (snap) => {
//         try {
//             setIsLoading(true);
//             console.log({ "items": snap.docs.length });

//             const allCompletedServices = []
//             const jobs = snap.docs.map(async (serviceSnap) => {

//                 const serviceData = serviceSnap.data();
//                 // console.log({ serviceData });

//                 const scheduleDataRef = doc(db, `${COLLECTIONS.serviceSchedules}/${serviceData.serviceScheduleId}`);
//                 const scheduleRes = await getDoc(scheduleDataRef);
//                 if (!scheduleRes.exists()) {
//                     return;
//                 }
//                 const scheduleData = scheduleRes.data();
//                 // console.log({ scheduleData });
//                 const serviceTypeString = getStringOfServiceTypes(scheduleData)
//                 const generatorDataRef = doc(db, `${COLLECTIONS.generators}/${serviceData.generatorId}`);
//                 const generatorRes = await getDoc(generatorDataRef);
//                 if (!generatorRes.exists()) {
//                     return;
//                 }
//                 const generatorData = generatorRes.data();
//                 // console.log({ generatorData });

//                 const routeDataRef = doc(db, `${COLLECTIONS.routes}/${serviceData.routeId}`);
//                 const routeRes = await getDoc(routeDataRef);
//                 if (!routeRes.exists()) {
//                     return;
//                 }
//                 const routeData = routeRes.data();
//                 // console.log({ routeData });

//                 const driverDataRef = doc(db, `${COLLECTIONS.drivers}/${routeData.driverId}`);
//                 const driverRes = await getDoc(driverDataRef);
//                 if (!driverRes.exists()) {
//                     return;
//                 }
//                 const driverData = driverRes.data();
//                 // console.log({ driverData });

//                 allCompletedServices.push({
//                     id: serviceSnap.id,
//                     date: serviceData.date.toDate().toLocaleDateString(),
//                     time: serviceData.date.toDate().toLocaleTimeString(),
//                     customer: `${generatorData?.generatorName ?? "dummy name"} `,
//                     octoNumber: `${generatorData.octoNumber ?? "dummy octo number"}`,
//                     email: `${"dummy email"}`,
//                     address: `${"dummy address"}`,
//                     billed: serviceData?.isBilled ?? false,
//                     billedData: serviceData?.billedDate ?? null,
//                     paidData: serviceData?.paidDate ?? null,
//                     paid: serviceData?.isPaid ?? false,
//                     paidAmount: serviceData?.paidAmount ?? "dummy amount",
//                     optionChecked: false,
//                     driverName: `${driverData?.firstName ?? ""} ${driverData?.lastName ?? ""}`,
//                     pickUpTruck: driverData?.truckNumber ?? "dummy number",
//                     serviceType: serviceTypeString ?? "dummy service"
//                 });
//             })
//             await Promise.all(jobs);
//             console.log({
//                 allCompletedServices
//             });
//             setCompleteServices(allCompletedServices);
//         } catch (error) {
//             console.log(error);

//         } finally {
//             setIsLoading(false);
//         }

//     })
//     return () => {
//         if (unsubscribe) unsubscribe();
//     }
// }, [])