import React from 'react'
import { ChevronLeft, ChevronRight } from "lucide-react";

const CustomDobHeader = ({
    date,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
    changeMonth,
    changeYear,
}) => {
    const months = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    const years = [];
    const currentYear = new Date().getFullYear();
    for (let i = currentYear; i >= currentYear - 100; i--) {
        years.push(i);
    }

    const handleMonthChange = (e) => {
        const monthIndex = parseInt(e.target.value, 10);
        changeMonth(monthIndex);
    };

    const handleYearChange = (e) => {
        const selectedYear = parseInt(e.target.value, 10);
        changeYear(selectedYear);
    };

    return (
        <div className="flex justify-between items-center px-4 py-2">
            <button
                onClick={decreaseMonth}
                type="button"
                disabled={prevMonthButtonDisabled}
                className="nav-button"
            >
                <ChevronLeft size={18} />
            </button>
            <div className="flex items-center">
                <select
                    value={date.getMonth()}
                    onChange={handleMonthChange}
                    className="mx-2 border rounded text-black bg-white transition-colors py-2 px-4"
                >
                    {months.map((month, index) => (
                        <option key={index} value={index}>
                            {month}
                        </option>
                    ))}
                </select>
                <select
                    value={date.getFullYear()}
                    onChange={handleYearChange}
                    className="mx-2 border rounded text-black bg-white transition-colors py-2 px-4"
                >
                    {years.map((year) => (
                        <option key={year} value={year}>
                            {year}
                        </option>
                    ))}
                </select>
            </div>
            <button
                onClick={increaseMonth}
                type="button"
                disabled={nextMonthButtonDisabled}
                className="nav-button"
            >
                <ChevronRight size={18} />
            </button>
        </div>
    );
};

export default CustomDobHeader;