import { configureStore } from "@reduxjs/toolkit";
import formReducer from "./sliceReducers/formSlice";
import generatorReducer from "./sliceReducers/generatorSlice";

const store = configureStore({
	reducer: {
		form: formReducer,
		generator: generatorReducer,
	},
});

export default store;
