import React from "react";

const ProgressBar = ({ Labelone, Labeltwo, Labelthree, completed, scheduled, incompleted, onClick, isLoading }) => {
	const totalTasks = completed + scheduled + incompleted;
	const total = totalTasks || 1;

	const completedWidth = (completed / total) * 100;
	const scheduledWidth = (scheduled / total) * 100;
	const incompletedWidth = (incompleted / total) * 100;

	if (isLoading) {
		// Shimmer skeleton loader
		return (
			<div className="flex flex-col items-center w-full">
				<div className="relative w-full h-5 bg-gray-200 rounded-md overflow-hidden">
					<div className="h-full w-full bg-gray-300 animate-pulse" />
				</div>
			</div>
		);
	}

	return (
		<div className="flex flex-col items-center w-full">
			<div className="relative w-full mb-2">
				<div className="absolute -top-3.5" style={{ width: `${incompletedWidth}%`, left: "0%" }}>
					{Labelone}
				</div>

				<div className="absolute -top-3.5" style={{ width: `${scheduledWidth}%`, left: `${incompletedWidth}%` }}>
					{Labeltwo}
				</div>

				<div
					className="absolute -top-3.5"
					style={{ width: `${completedWidth}%`, left: `${incompletedWidth + scheduledWidth}%` }}
				>
					{Labelthree}
				</div>
			</div>

			<div className="relative w-full mb-2">
				<div className="absolute mt-8" style={{ width: `${incompletedWidth}%`, left: "0%" }}>
					<span className="absolute right-0">{incompleted}</span>
				</div>

				<div className="absolute mt-8" style={{ width: `${scheduledWidth}%`, left: `${incompletedWidth}%` }}>
					<span className="absolute right-0">{scheduled}</span>
				</div>

				<div
					className="absolute mt-8"
					style={{ width: `${completedWidth}%`, left: `${incompletedWidth + scheduledWidth}%` }}
				>
					<span className="absolute right-0">{completed}</span>
				</div>
			</div>

			{/* Clickable Progress Bar */}
			<div className="relative w-full h-5 bg-gray-200 rounded-full overflow-hidden cursor-pointer" onClick={onClick}>
				<div className="absolute top-0 left-0 h-full bg-red-500" style={{ width: `${incompletedWidth}%` }} />
				<div
					className="absolute top-0 h-full bg-green-400"
					style={{ width: `${scheduledWidth}%`, left: `${incompletedWidth}%` }}
				/>
				<div
					className="absolute top-0 h-full bg-blue-500"
					style={{ width: `${completedWidth}%`, left: `${incompletedWidth + scheduledWidth}%` }}
				/>
			</div>
		</div>
	);
};

export default ProgressBar;
