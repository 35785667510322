import PropTypes from "prop-types";
import { Map, Pin, useMapsLibrary } from "@vis.gl/react-google-maps";
import { useCallback, useEffect, useState } from "react";
import GeneratorSingleMarkerWithInfoWindow from "../../../../../../../../../components/maps/GeneratorSingleMarkerWithInfoWindow";
import { Polyline } from "../../../../../../../../../components/maps/PolyLine";
import { MarkerWithInfoWindow } from "../../../../../../../../../components/maps/MarkerWithInfoWindow";

function RouteAssignment({ generatorData = {}, allRoutes = [], selectedRoutes = [], allGeneratorsData = [] }) {
	const [markerPositions, setMarkerPositions] = useState([]);
	const geocoderLibrary = useMapsLibrary("geocoding");
	const [geocoderService, setGeocoderService] = useState(null);
	const [routesToShow, setRoutesToShow] = useState([]);
	useEffect(() => {
		if (!geocoderLibrary) return;
		setGeocoderService(new geocoderLibrary.Geocoder());
	}, [geocoderLibrary]);
	useEffect(() => {
		setRoutesToShow(() => allRoutes.map((el) => el.id));
	}, [allRoutes]);
	useEffect(() => {
		console.log({ allRoutes });
	}, [allRoutes]);
	const handleMapClick = useCallback(
		async (event) => {
			if (markerPositions.length <= 1) {
				let name = "";
				let placeId = event?.detail?.placeId ?? "";
				let formatted_address = "";
				try {
					if (!geocoderService) {
						throw new Error("Geocoder Service not found. You need to set location name manually.");
					}
					if (event?.detail?.placeId) {
						const res = await geocoderService.geocode({ placeId: event?.detail?.placeId });
						console.log({ "with placeid": res });
						name = res.results[0].formatted_address;
						formatted_address = res.results[0].formatted_address;
					} else {
						const res = await geocoderService.geocode({ location: event.detail.latLng });
						console.log({ "without placeid": res });
						name = res.results[0].formatted_address;
						placeId = res.results[0].place_id;
						formatted_address = res.results[0].formatted_address;
					}
				} catch (error) {
					console.log({ error });
				}

				setMarkerPositions((positions) => [
					...positions,
					{
						name,
						id: Math.floor(Math.random() * 10000).toString(),
						...event.detail.latLng,
						placeId,
						formatted_address,
					},
				]);
			}
		},
		[markerPositions, geocoderService]
	);

	return (
		<div className="h-[500px]">
			<Map
				reuseMaps
				mapId={`routeAssignmentMap`}
				defaultZoom={12}
				defaultCenter={generatorData.serviceAddCoordinates && { lat: 32.7364432, lng: -117.1460877 }}
				gestureHandling={"greedy"}
				onClick={handleMapClick}
				disableDefaultUI
				mapTypeControl
				zoomControl
				fullscreenControl
			>
				{allGeneratorsData.length > 0 &&
					allGeneratorsData.map((genData) => (
						<GeneratorSingleMarkerWithInfoWindow
							key={genData.id}
							isCurrent={generatorData.id === genData.id ? true : false}
							marker={{
								lat: genData?.serviceAddCoordinates.lat,
								lng: genData?.serviceAddCoordinates.lng,
								assignedRoutes: genData?.assignedRoutes,
								formattedAdd: `${genData.serviceAddress?.street}, ${genData.serviceAddress?.city}, ${genData.serviceAddress?.state} ${genData.serviceAddress?.zipCode}`,
								name: genData?.generatorName,
								octoNumber: genData?.octoNumber,
								serviceFrequency: genData?.serviceFrequency,
								serviceType: genData?.serviceType,
							}}
						/>
					))}
				{allRoutes.length > 0 &&
					allRoutes.map((path) => {
						const isSelected = selectedRoutes.findIndex((el) => el === path.id) > -1 ? true : false;
						return (
							routesToShow.findIndex((el) => el === path.id) > -1 && (
								<MarkerWithInfoWindow
									key={path.id}
									position={path.path[0]}
									name={path.startLocationName}
									pin={
										<Pin
											background={isSelected ? "#7E60BF" : "#22ccff"}
											borderColor={isSelected ? "#7E60BF" : "#22ccff"}
											glyphColor={"#0f677a"}
										>
											<p className={"text-white"}>Start</p>
										</Pin>
									}
								></MarkerWithInfoWindow>
							)
						);
					})}
				{allRoutes.length > 0 &&
					allRoutes.map((path) => {
						const isSelected = selectedRoutes.findIndex((el) => el === path.id) > -1 ? true : false;
						return (
							routesToShow.findIndex((el) => el === path.id) > -1 && (
								<MarkerWithInfoWindow
									key={path.id}
									position={path.path[path.path.length - 1]}
									name={path.endLocationName}
									pin={
										<Pin
											background={isSelected ? "#7E60BF" : "#22ccff"}
											borderColor={isSelected ? "#7E60BF" : "#22ccff"}
											glyphColor={"#0f677a"}
										>
											<p className={"text-white"}>End</p>
										</Pin>
									}
								></MarkerWithInfoWindow>
							)
						);
					})}
				{allRoutes.length > 0 &&
					allRoutes.map((path) => {
						const isSelected = selectedRoutes.findIndex((el) => el === path.id) > -1 ? true : false;
						return (
							routesToShow.findIndex((el) => el === path.id) > -1 && (
								<Polyline
									key={path.id}
									strokeWeight={5}
									strokeColor={isSelected ? "#7E60BF" : "#0000FF"}
									path={path.path}
								/>
							)
						);
					})}
				<div className="absolute bottom-5 left-5 bg-white shadow-xl rounded-xl">
					<div className="collapse collapse-arrow">
						<input type="checkbox" />
						<div className="collapse-title text-sm">Route List</div>
						<div className="collapse-content">
							{allRoutes.map((route, index) => (
								<div key={route.id} className="text-sm flex gap-2">
									<input
										type="checkbox"
										checked={routesToShow.findIndex((el) => el === route.id) > -1 ? true : false}
										onChange={(e) => {
											if (e.currentTarget.checked) {
												setRoutesToShow((prev) => [...prev, route.id]);
											} else {
												setRoutesToShow((prev) => prev.filter((el) => el !== route.id));
											}
										}}
									/>
									<div onClick={() => {}}>{`${route.routeLabel}`.substring(0, 20)}</div>
								</div>
							))}
						</div>
					</div>
				</div>
			</Map>
		</div>
	);
}

RouteAssignment.propTypes = {
	allGeneratorsData: PropTypes.array,
	allRoutes: PropTypes.array,
	generatorData: PropTypes.object,
	getValues: PropTypes.func,
	selectedRoutes: PropTypes.array,
};

export default RouteAssignment;
