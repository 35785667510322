import React, { useState } from "react";

const WeightTable = ({ loading, data, handleRowClick }) => {
	const [isScrollable, setIsScrollable] = useState(false);
	const [hoveredRow, setHoveredRow] = useState(null);

	return (
		<div className="">
			<div className="bg-[#E5F2FF] flex font-medium min-w-fit py-4 px-8">
				<div className="grid grid-cols-5 w-full">
					<div>Date and Time</div>
					<div>Scan Container</div>
					<div>Weight</div>
					<div>Manifest Number</div>
					<div>Company</div>
				</div>
			</div>

			{/* Skeleton */}
			{loading && (
				<div className="overflow-y-scroll">
					{Array.from({ length: 6 }).map((_, index) => (
						<div
							key={index}
							className="flex flex-col md:flex-row items-start pt-2 border-b pl-8 border-gray-300 animate-pulse"
							style={{ minHeight: "80px" }}
						>
							<div className="w-full md:w-1/4 text-sm font-normal">
								<div className="h-4 bg-gray-300 rounded mt-4 w-3/4"></div>
								<div className="h-4 bg-gray-300 rounded mt-2 w-1/2"></div>
							</div>
							<div className="w-full md:w-1/4 text-sm font-normal">
								<div className="h-4 bg-gray-300 rounded mt-4 w-3/4"></div>
							</div>
							<div className="w-full md:w-1/4 text-sm font-normal">
								<div className="h-4 bg-gray-300 rounded mt-4 w-3/4"></div>
							</div>
							<div className="w-full md:w-1/4 text-sm font-normal">
								<div className="h-4 bg-gray-300 rounded mt-4 w-3/4"></div>
							</div>
							<div className="w-full md:w-1/4 text-sm font-normal">
								<div className="h-4 bg-gray-300 rounded mt-4 w-3/4"></div>
							</div>
						</div>
					))}
				</div>
			)}

			{!loading && (
				<div
					className={`second-child overflow-y-auto ${isScrollable ? "shadow-inner" : ""}`}
					onScroll={(e) => setIsScrollable(e.target.scrollTop > 0)}
				>
					{data.map((item, index) => (
						<div
							key={item.id}
							className={`grid grid-cols-5 px-8 items-center text-sm border-b border-gray-300 ${
								hoveredRow === index ? "bg-gray-200" : ""
							} cursor-pointer`}
							onMouseEnter={() => setHoveredRow(index)}
							onMouseLeave={() => setHoveredRow(null)}
							style={{ minHeight: "80px" }}
							onClick={() => handleRowClick(item.id)}
						>
							{/* Date and Time */}
							<div>
								<div>{item.date || "Date not provided"}</div>
								<div>{item.time || "Time not provided"}</div>
							</div>

							{/* Scan Container */}
							<div>
								<div>{item.scanContainer || "Container not provided"}</div>
							</div>

							{/* Weight */}
							<div>
								<div>{item.weight || "Weight not provided"}</div>
							</div>

							{/* Number */}
							<div>
								<div>{item.number || "Mnifest number not provided"}</div>
							</div>

							{/* Weight */}
							<div>
								<div>{item.weight || "Weight not provided"}</div>
							</div>
						</div>
					))}
				</div>
			)}
		</div>
	);
};

export default WeightTable;
