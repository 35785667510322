import React, { useEffect, useState } from "react";
import { useFieldArray, Controller, useForm } from "react-hook-form";
import Input from "../../../../../components/UI/Input";
import { useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Button from "../../../../../components/UI/Button";
import CustomDatePicker from "../../../../../components/UI/CustomDatePicker";
import { createNewTransport, GetTransporterData, editTransportorDetails, getDrivers } from "../../../../../utils/firebaseOperations";
import Checkbox from "../../../../../components/UI/Checkbox";
import Dropdown from "../../../../../components/UI/dropdowns/Dropdown";
import PhoneInput from "../../../../../components/UI/PhoneInput";
import FileUpload from "../../../../../components/UI/FileUpload";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from "../../../../../config/firebase";
import { BsPlusSquareFill } from "react-icons/bs";
 import UrlInput from "../../../../../components/UI/UrlInput";
import UploadFiles from "../../../../transporters/UploadFiles";

const AddTransporter = () => {
	const { id } = useParams();
	const [shouldFetch, setShouldFetch] = useState(false);

	// States to track the Billing and Service department data
	const [billingData, setBillingData] = useState({
		name: "",
		street: "",
		suite: "",
		state: "",
		zip: "",
		email: "",
		phone: "",
	});

	const [serviceData, setServiceData] = useState({
		name: "",
		street: "",
		suite: "",
		state: "",
		zip: "",
		email: "",
		phone: "",
	});

	// States for checkbox management
	const [billingCopyChecked, setBillingCopyChecked] = useState(false);
	const [serviceCopyChecked, setServiceCopyChecked] = useState(false);


	const {
		control,
		handleSubmit,
		reset,
		setValue,
		formState: { errors },
		watch,
	} = useForm({
		defaultValues: {
			transportor: [
				{
					name: "",
					phoneNumber: "",
					registration1: "",
					website: "",
					dot: "",
					businessHours: "",
					transportorstatus: "",
					reviewLink: "",
					entityId: "",
					sic: "",
					cageCode: "",
					companyDisplayName: "",
					generalEmail: "",
					registration2: "",
					companyLogo: null,
					stateOfFiscalYear: "",
					ein: "",
					epaId: "",
					sos: "",
					naicsCode: "",
					companySlogan: "",
					companyMissionStatement: "",
					companyVisionStatement: "",
					aboutEmail: "",
					serviceOffered: {
						MedicalWasteTransporter: false,
						MedicalWasteTransferStation: false,
						MedicalWasteTreatmentFacilities: false,
						RollOffContainer: false,
						OffsitePaperShredding: false,
						PlantBasedPaperShredding: false,
						SolidWaste: false,
						Recycling: false,
					},
					octoConnect: {
						OptIn: false,
						OptOut: false,
					},
					createLink: {
						TreatmentFacility: false,
						TransferStation: false,
					},
					billingName: "",
					billingStreet: "",
					billingSuite: "",
					billingState: "",
					billingZip: "",
					billingEmail: "",
					billingPhone: "",
					serviceName: "",
					serviceStreet: "",
					serviceSuite: "",
					serviceState: "",
					serviceZip: "",
					serviceEmail: "",
					servicePhone: "",
					dispatchEmail: "",
					dispatchPhone: "",
					octoNumber: "",
				},
			],
		},
	});

	const { fields } = useFieldArray({
		control,
		name: "transportor",
	});

	const navigate = useNavigate();

	const handlePreviousBtn = () => {
		navigate("/admin/assets/transportersmng");
	};
	const onSubmit = async (data) => {
		try {
			let dataToBeSent = { id, ...data };
			const { companyLogo, transportor } = dataToBeSent;

			if (companyLogo) {
				const storageRef = ref(storage, `/admin1/companyLogo/${companyLogo.name}`);
				const snapshot = await uploadBytes(storageRef, companyLogo);
				const downloadURL = await getDownloadURL(snapshot.ref);
				const token = new URL(downloadURL).searchParams.get("token");

				transportor[0].companyLogo = {
					name: companyLogo.name,
					link: downloadURL,
					mime: companyLogo?.type,
					path: storageRef.fullPath,
					ref: "",
					token: token ?? "",
				};
			}

			// Copy Billing data to Service if Billing Copy checkbox is checked
			if (serviceCopyChecked) {
				const { billingName, billingStreet, billingSuite, billingState, billingZip, billingEmail, billingPhone } = transportor[0];
				transportor[0].serviceName = billingName;
				transportor[0].serviceStreet = billingStreet;
				transportor[0].serviceSuite = billingSuite;
				transportor[0].serviceState = billingState;
				transportor[0].serviceZip = billingZip;
				transportor[0].serviceEmail = billingEmail;
				transportor[0].servicePhone = billingPhone;
			}

			// Copy Service data to Billing if Service Copy checkbox is checked
			if (billingCopyChecked) {
				const { serviceName, serviceStreet, serviceSuite, serviceState, serviceZip, serviceEmail, servicePhone } = transportor[0];
				transportor[0].billingName = serviceName;
				transportor[0].billingStreet = serviceStreet;
				transportor[0].billingSuite = serviceSuite;
				transportor[0].billingState = serviceState;
				transportor[0].billingZip = serviceZip;
				transportor[0].billingEmail = serviceEmail;
				transportor[0].billingPhone = servicePhone;
			}

			// Save updated form data
			if (id) {
				await editTransportorDetails(transportor[0], id);
				toast.success("Satellite Location details updated successfully!");
				navigate("/admin/assets/transportersmng");
			} else {
				await createNewTransport(transportor[0]);
				toast.success("Satellite Location added successfully!");
			}

			reset();
			setShouldFetch(true);
		} catch (error) {
			console.error("Error saving Satellite Location:", error);
			toast.error("Failed to save Satellite Location.");
		}
	};

	useEffect(() => {
		const fetchTransporterData = async () => {
			if (id) {
				try {
					const transporterData = await GetTransporterData(id);
					console.log(transporterData);
					if (transporterData) {
						const initializedTransporterData = {
							...transporterData,
						};
						reset({ transportor: [initializedTransporterData] });

						// Set initial billing and service data
						setBillingData({
							name: transporterData.billingName,
							street: transporterData.billingStreet,
							suite: transporterData.billingSuite,
							state: transporterData.billingState,
							zip: transporterData.billingZip,
							email: transporterData.billingEmail,
							phone: transporterData.billingPhone,
						});
						setServiceData({
							name: transporterData.serviceName,
							street: transporterData.serviceStreet,
							suite: transporterData.serviceSuite,
							state: transporterData.serviceState,
							zip: transporterData.serviceZip,
							email: transporterData.serviceEmail,
							phone: transporterData.servicePhone,
						});
					}
				} catch (error) {
					console.error("Error fetching transporter data:", error);
					toast.error("Failed to fetch transporter data.");
				}
			} else {
				reset({
					transportor: [
						{
							name: "",
							phoneNumber: "",
							registration1: "",
							website: "",
							dot: "",
							businessHours: "",
							transportorstatus: "",
							reviewLink: "",
							entityId: "",
							sic: "",
							cageCode: "",
							companyDisplayName: "",
							generalEmail: "",
							registration2: "",
							companyLogo: null,
							stateOfFiscalYear: "",
							ein: "",
							epaId: "",
							sos: "",
							naicsCode: "",
							companySlogan: "",
							companyMissionStatement: "",
							companyVisionStatement: "",
							aboutEmail: "",
							serviceOffered: {
								MedicalWasteTransporter: false,
								MedicalWasteTransferStation: false,
								// Add more fields here as needed...
							},
							octoConnect: {
								OptIn: false,
								OptOut: false,
							},
							createLink: {
								TreatmentFacility: false,
								TransferStation: false,
							},
							billingName: "",
							billingStreet: "",
							billingSuite: "",
							billingState: "",
							billingZip: "",
							billingEmail: "",
							billingPhone: "",
							serviceName: "",
							serviceStreet: "",
							serviceSuite: "",
							serviceState: "",
							serviceZip: "",
							serviceEmail: "",
							servicePhone: "",
							dispatchEmail: "",
							dispatchPhone: "",
							octoNumber: "",
						},
					],
				});
			}
		};

		fetchTransporterData();
	}, [id, reset, shouldFetch]);

	// Handle checkbox toggles for "Billing Copy" and "Service Copy"
	const handleBillingCopyChange = () => {
		setBillingCopyChecked(!billingCopyChecked);
	};

	const handleServiceCopyChange = () => {
		setServiceCopyChecked(!serviceCopyChecked);
	};




	const [DriverOptions, setDriverOptions] = useState([])
	useEffect(() => {
		getDriversdata()
	}, [])

	const getDriversdata = async () => {
		const dataWeGot = await getDrivers()
		console.log({ dataWeGot })
		const OptionsForDropdown = dataWeGot.map(({ firstName, lastName, middleInitial }) => ({
			label: `${firstName} ${middleInitial} ${lastName}`,
			value: `${firstName} ${middleInitial} ${lastName}`
		}));
		setDriverOptions(OptionsForDropdown)
	}
	const statusOptions = [
		{ value: "Active", label: "Active" },
		{ value: "Inactive", label: "Inactive" },
	];

	const stateOptions = [
		{ value: 'AZ', label: 'AZ' },
		{ value: 'CA', label: 'CA' },
		{ value: 'FL', label: 'FL' },
		{ value: 'GA', label: 'GA' },
		{ value: 'IL', label: 'IL' },
		{ value: 'MI', label: 'MI' },
		{ value: 'MA', label: 'MA' },
		{ value: 'NC', label: 'NC' },
		{ value: 'NJ', label: 'NJ' },
		{ value: 'NY', label: 'NY' },
		{ value: 'OH', label: 'OH' },
		{ value: 'PA', label: 'PA' },
		{ value: 'TX', label: 'TX' },
		{ value: 'VA', label: 'VA' },
		{ value: 'WA', label: 'WA' }
	]


	return (
		<>

			<div className="p-6">
				<div className="flex flex-col border border-cardBorderCol bg-white rounded-cardRadii h-full">
					<ToastContainer />
					<div className="my-4 ml-6 text-xl font-semibold">Satellite Location Profile</div>
					<div className="my-2 ml-6">General Information</div>
					<div className="ml-6 text-gray-500">(Information appears in public profile)</div>
					<div className="border-b mb-2 border-[#CCCCCC]"></div>
					<div className="ml-6 mr-6">
						{fields.map((field, index) => (
							<div className="flex gap-8 w-full" key={field.id}>
								<div className="w-1/2">
									<Controller
										name={`transportor.${index}.name`}
										control={control}
										rules={{ required: "Name is required" }}
										render={({ field: { onChange, value } }) => (
											<div>
												<Input label="Company Name" value={value} onChange={onChange} />
												{errors.transportor?.[index]?.name && <p className="text-red-500 text-sm mt-1">{errors.transportor[index].name.message}</p>}
											</div>
										)}
									/>
									<Controller
										name={`transportor.${index}.companyDisplayName`}
										control={control}
										rules={{ required: "Company Display Name is required" }}
										render={({ field: { onChange, value } }) => (
											<div>
												<Input label="Company Display Name" value={value} onChange={onChange} />
												{errors.transportor?.[index]?.companyDisplayName && <p className="text-red-500 text-sm mt-1">{errors.transportor[index].companyDisplayName.message}</p>}
											</div>
										)}
									/>
									<Controller
										name={`transportor.${index}.octoNumber`}
										control={control}
										rules={{ required: "OCTO Number is required", pattern: { value: /^[0-9]+$/, message: "OCTO Number must be numeric" } }}
										render={({ field: { onChange, value } }) => (
											<div>
												<Input label="OCTO Number" value={value} onChange={onChange} />
												{errors.transportor?.[index]?.octoNumber && <p className="text-red-500 text-sm mt-1">{errors.transportor[index].octoNumber.message}</p>}
											</div>
										)}
									/>

									<Controller
										name={`transportor.${index}.phoneNumber`}
										control={control}
										rules={{ required: "Phone is required" }}
										render={({ field: { onChange, value } }) => (
											<div>
												<PhoneInput label="General Phone" value={value} onChange={onChange} IsRequired={true} />
												{errors.transportor?.[index]?.phoneNumber && <p className="text-red-500 text-sm mt-1">{errors.transportor[index].phoneNumber.message}</p>}
											</div>
										)}
									/>

									<Controller
										name={`transportor.${index}.generalEmail`}
										control={control}
										rules={{ required: "General Email is required" }}
										render={({ field: { onChange, value } }) => (
											<div>
												<Input label="General Email" value={value} onChange={onChange} />
												{errors.transportor?.[index]?.generalEmail && <p className="text-red-500 text-sm mt-1">{errors.transportor[index].generalEmail.message}</p>}
											</div>
										)}
									/>
									<Controller
										name={`transportor.${index}.website`}
										control={control}
										rules={{
											required: "Website is required",
											pattern: {
												value: /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/\S*)?$/,
												message: "Please enter a valid website URL",
											},
										}}
										render={({ field: { onChange, value } }) => (
											<div>
												<UrlInput
													label="Website URL"
													value={value}
													onChange={onChange} />
												{errors.transportor?.[index]?.website && <p className="text-red-500 text-sm mt-1">{errors.transportor[index].website.message}</p>}
											</div>
										)}
									/>
									<div className="flex items-center justify-between mt-3">
										<label htmlFor={"contractStartDate"} className="truncate text-inputLabel font-normal">
											Start Of Fiscal Year
										</label>
										<div className="w-2/3">
											<Controller
												name={`transportor.${index}.stateOfFiscalYear`}
												control={control}
												render={({ field: { value, onChange } }) => <CustomDatePicker selectedDate={value} setSelectedDate={onChange} label={"Contract Start Date"} />}
											/>
										</div>
									</div>

									<Controller
										name={`transportor.${index}.businessHours`}
										control={control}
										rules={{ required: "Business Hours are required" }}
										render={({ field: { onChange, value } }) => (
											<div>
												<Input label="Business Hours" value={value} onChange={onChange} />
												{errors.transportor?.[index]?.businessHours && <p className="text-red-500 text-sm mt-1">{errors.transportor[index].businessHours.message}</p>}
											</div>
										)}
									/>
									<Controller
										name={`transportor.${index}.reviewLink`}
										control={control}
										rules={{
											required: "Satellite Location ID is required",
											pattern: {
												value: /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/\S*)?$/,
												message: "Must be a valid link",
											},
										}}
										render={({ field: { onChange, value } }) => (
											<div>
												<UrlInput label="Google Review Link" value={value} onChange={onChange} />
												{errors.transportor?.[index]?.reviewLink && <p className="text-red-500 text-sm mt-1">{errors.transportor[index].reviewLink.message}</p>}
											</div>
										)}
									/>

									<div className="w-full">
										<UploadFiles
											control={control}
											errors={errors}
											label="Company Logo"
											name="companyLogo"
											existingFile={
												fields[0].companyLogo
													? {
														url: fields[0].companyLogo.url,
														name: fields[0].companyLogo.name || "Company Logo",
													}
													: null
											}
											isRequired={true}
										/>
									</div>
									<Controller
										name={`transportor.${index}.transportorstatus`}
										control={control}
										rules={{ required: "Satellite Location Status is required" }}
										render={({ field: { value, onChange } }) => (
											<div>
												<Dropdown value={value} onChange={onChange} options={statusOptions} label="Satellite Location Status" />
												{errors.transportor?.[index]?.transportorstatus && <p className="text-red-500 text-sm mt-1">{errors.transportor[index].transportorstatus.message}</p>}
											</div>
										)}
									/>
								</div>
								<div className="w-1/2">
									<Controller
										name={`transportor.${index}.registration1`}
										control={control}
										rules={{ required: "Registration Number 1 is required", pattern: { value: /^[0-9]+$/, message: "Registration #1 must be numeric" } }}
										render={({ field: { onChange, value } }) => (
											<div>
												<Input label="Registration Number 1" value={value} onChange={onChange} />
												{errors.transportor?.[index]?.registration1 && <p className="text-red-500 text-sm mt-1">{errors.transportor[index].registration1.message}</p>}
											</div>
										)}
									/>

									<Controller
										name={`transportor.${index}.registration2`}
										control={control}
										rules={{ required: "Registration Number 2 is required", pattern: { value: /^[0-9]+$/, message: "Registration #2 must be numeric" } }}
										render={({ field: { onChange, value } }) => (
											<div>
												<Input label="Registration Number 2" value={value} onChange={onChange} />
												{errors.transportor?.[index]?.registration2 && <p className="text-red-500 text-sm mt-1">{errors.transportor[index].registration2.message}</p>}
											</div>
										)}
									/>
									<Controller
										name={`transportor.${index}.epaId`}
										control={control}
										rules={{ required: "EPA ID is required" }}
										render={({ field: { onChange, value } }) => (
											<div>
												<Input label="EPA ID Number" value={value} onChange={onChange} />
												{errors.transportor?.[index]?.epaId && <p className="text-red-500 text-sm mt-1">{errors.transportor[index].epaId.message}</p>}
											</div>
										)}
									/>
									<Controller
										name={`transportor.${index}.dot`}
										control={control}
										rules={{ required: "DOT is required" }}
										render={({ field: { onChange, value } }) => (
											<div>
												<Input label="DOT Number" value={value} onChange={onChange} />
												{errors.transportor?.[index]?.dot && <p className="text-red-500 text-sm mt-1">{errors.transportor[index].dot.message}</p>}
											</div>
										)}
									/>
									<Controller
										name={`transportor.${index}.ein`}
										control={control}
										rules={{ required: "EIN is required" }}
										render={({ field: { onChange, value } }) => (
											<div>
												<Input label="EIN" value={value} onChange={onChange} />
												{errors.transportor?.[index]?.ein && <p className="text-red-500 text-sm mt-1">{errors.transportor[index].ein.message}</p>}
											</div>
										)}
									/>
									<Controller
										name={`transportor.${index}.entityId`}
										control={control}
										rules={{ required: "Entity ID is required" }}
										render={({ field: { onChange, value } }) => (
											<div>
												<Input label="Entity ID Number" value={value} onChange={onChange} />
												{errors.transportor?.[index]?.entityId && <p className="text-red-500 text-sm mt-1">{errors.transportor[index].entityId.message}</p>}
											</div>
										)}
									/>
									<Controller
										name={`transportor.${index}.sos`}
										control={control}
										rules={{ required: "SOS is required" }}
										render={({ field: { onChange, value } }) => (
											<div>
												<Input label="SOS" value={value} onChange={onChange} />
												{errors.transportor?.[index]?.sos && <p className="text-red-500 text-sm mt-1">{errors.transportor[index].sos.message}</p>}
											</div>
										)}
									/>

									<Controller
										name={`transportor.${index}.sic`}
										control={control}
										rules={{ required: "SIC is required" }}
										render={({ field: { onChange, value } }) => (
											<div>
												<Input label="SIC" value={value} onChange={onChange} />
												{errors.transportor?.[index]?.sic && <p className="text-red-500 text-sm mt-1">{errors.transportor[index].sic.message}</p>}
											</div>
										)}
									/>
									<Controller
										name={`transportor.${index}.naicsCode`}
										control={control}
										rules={{ required: "NAICS Code is required" }}
										render={({ field: { onChange, value } }) => (
											<div>
												<Input label="NAICS Code" value={value} onChange={onChange} />
												{errors.transportor?.[index]?.naicsCode && <p className="text-red-500 text-sm mt-1">{errors.transportor[index].naicsCode.message}</p>}
											</div>
										)}
									/>
									<Controller
										name={`transportor.${index}.cageCode`}
										control={control}
										rules={{ required: "CAGE Code is required" }}
										render={({ field: { onChange, value } }) => (
											<div>
												<Input label="CAGE Code" value={value} onChange={onChange} />
												{errors.transportor?.[index]?.cageCode && <p className="text-red-500 text-sm mt-1">{errors.transportor[index].cageCode.message}</p>}
											</div>
										)}
									/>
								</div>
							</div>
						))}
					</div>
					<div className="ml-6">
						<div className="mt-12 mb-4">Services Offered</div>
						<div className="border-t"></div>
						<div className="grid gap-5 grid-cols-3 py-4 mt-2">
							{Object.keys(control._formValues.transportor[0].serviceOffered).map((key) => (
								<Controller
									key={key}
									name={`transportor.${fields.length - 1}.serviceOffered.${key}`}
									control={control}
									render={({ field: { value, onChange } }) => <Checkbox label={key.replace(/([A-Z])/g, " $1")} isChecked={value} setIsChecked={onChange} />}
								/>
							))}
						</div>
						<div className="border-t"></div>
						<div className="grid gap-5 py-4 mt-2">
							<Controller
								name={`transportor.${fields.length - 1}.createLink.TreatmentFacility`}
								control={control}
								render={({ field: { value, onChange } }) => <Checkbox label="Create/Link To Treatment Facility" isChecked={value} setIsChecked={onChange} />}
							/>

							<Controller
								name={`transportor.${fields.length - 1}.createLink.TransferStation`}
								control={control}
								render={({ field: { value, onChange } }) => <Checkbox label="Create /Link To Transfer Station" isChecked={value} setIsChecked={onChange} />}
							/>
						</div>
					</div>


					<div className="flex gap-8 w-full mt-4 p-6">
						{/* Billing Department */}
						<div className="w-1/2">
							<div className="flex justify-between">
								<div className="pb-4">Billing Department</div>
								<div className="flex">
									<div className="pr-2">
										<Checkbox
											isChecked={billingCopyChecked}
											setIsChecked={handleBillingCopyChange}
										/>
									</div>
									<div className="text-gray-600">Copy from Service</div>
								</div>
							</div>
							<div className="border-t"></div>
							<Controller
								name={`transportor.${fields.length - 1}.billingName`}
								control={control}
								render={({ field: { onChange, value } }) => (
									<div>
										<Input
											label="Name"
											type="Text"
											value={billingCopyChecked ? serviceData.name : value}
											onChange={(e) => {
												onChange(e);
												setBillingData({ ...billingData, name: e.target.value });
											}}
										/>
									</div>
								)}
							/>
							{/* Repeat for other Billing fields */}
							<Controller
								name={`transportor.${fields.length - 1}.billingStreet`}
								control={control}
								render={({ field: { onChange, value } }) => (
									<div>
										<Input
											label="Street"
											type="Text"
											value={billingCopyChecked ? serviceData.street : value}
											onChange={(e) => {
												onChange(e);
												setBillingData({ ...billingData, street: e.target.value });
											}}
										/>
									</div>
								)}
							/>
							<Controller
								name={`transportor.${fields.length - 1}.billingSuite`}
								control={control}
								render={({ field: { onChange, value } }) => (
									<div>
										<Input
											label="Suite"
											type="Text"
											value={billingCopyChecked ? serviceData.suite : value}
											onChange={(e) => {
												onChange(e);
												setBillingData({ ...billingData, suite: e.target.value });
											}}
										/>
									</div>
								)}
							/>
							<Controller
								name={`transportor.${fields.length - 1}.billingState`}
								control={control}
								render={({ field: { onChange, value } }) => (
									<div>
										<Dropdown
											label="State"
											value={billingCopyChecked ? serviceData.state : value}
											onChange={(e) => {
												onChange(e);
												setBillingData({ ...billingData, state: e.target.value });
											}}
											options={stateOptions}
										/>
									</div>
								)}
							/>
							<Controller
								name={`transportor.${fields.length - 1}.billingZip`}
								control={control}
								render={({ field: { onChange, value } }) => (
									<div>
										<Input
											label="Zip"
											type="Text"
											value={billingCopyChecked ? serviceData.zip : value}
											onChange={(e) => {
												onChange(e);
												setBillingData({ ...billingData, zip: e.target.value });
											}}
										/>
									</div>
								)}
							/>
							<Controller
								name={`transportor.${fields.length - 1}.billingEmail`}
								control={control}
								render={({ field: { onChange, value } }) => (
									<div>
										<Input
											label="Email"
											type="Text"
											value={billingCopyChecked ? serviceData.email : value}
											onChange={(e) => {
												onChange(e);
												setBillingData({ ...billingData, email: e.target.value });
											}}
										/>
									</div>
								)}
							/>
							<Controller
								name={`transportor.${fields.length - 1}.billingPhone`}
								control={control}
								render={({ field: { onChange, value } }) => (
									<div>
										<PhoneInput
											label="Phone"
											value={billingCopyChecked ? serviceData.phone : value}
											onChange={(e) => {
												onChange(e);
												setBillingData({ ...billingData, phone: e.target.value });
											}}
										/>
									</div>
								)}
							/>
						</div>

						{/* Service Department */}
						<div className="w-1/2">
							<div className="flex justify-between">
								<div className="pb-4">Service Department</div>
								<div className="flex">
									<div className="pr-2">
										<Checkbox
											isChecked={serviceCopyChecked}
											setIsChecked={handleServiceCopyChange}
										/>
									</div>
									<div className="text-gray-600">Copy from Billing</div>
								</div>
							</div>
							<div className="border-t"></div>
							<Controller
								name={`transportor.${fields.length - 1}.serviceName`}
								control={control}
								render={({ field: { onChange, value } }) => (
									<div>
										<Input
											label="Name"
											type="Text"
											value={serviceCopyChecked ? billingData.name : value}
											onChange={(e) => {
												onChange(e);
												setServiceData({ ...serviceData, name: e.target.value });
											}}
										/>
									</div>
								)}
							/>
							{/* Repeat for other Service fields */}
							<Controller
								name={`transportor.${fields.length - 1}.serviceStreet`}
								control={control}
								render={({ field: { onChange, value } }) => (
									<div>
										<Input
											label="Street"
											type="Text"
											value={serviceCopyChecked ? billingData.street : value}
											onChange={(e) => {
												onChange(e);
												setServiceData({ ...serviceData, street: e.target.value });
											}}
										/>
									</div>
								)}
							/>
							<Controller
								name={`transportor.${fields.length - 1}.serviceSuite`}
								control={control}
								render={({ field: { onChange, value } }) => (
									<div>
										<Input
											label="Suite"
											type="Text"
											value={serviceCopyChecked ? billingData.suite : value}
											onChange={(e) => {
												onChange(e);
												setServiceData({ ...serviceData, suite: e.target.value });
											}}
										/>
									</div>
								)}
							/>
							<Controller
								name={`transportor.${fields.length - 1}.serviceState`}
								control={control}
								render={({ field: { onChange, value } }) => (
									<div>
										<Dropdown
											label="State"
											value={serviceCopyChecked ? billingData.state : value}
											onChange={(e) => {
												onChange(e);
												setServiceData({ ...serviceData, state: e.target.value });
											}}
											options={stateOptions}
										/>
									</div>
								)}
							/>
							<Controller
								name={`transportor.${fields.length - 1}.serviceZip`}
								control={control}
								render={({ field: { onChange, value } }) => (
									<div>
										<Input
											label="Zip"
											type="Text"
											value={serviceCopyChecked ? billingData.zip : value}
											onChange={(e) => {
												onChange(e);
												setServiceData({ ...serviceData, zip: e.target.value });
											}}
										/>
									</div>
								)}
							/>
							<Controller
								name={`transportor.${fields.length - 1}.serviceEmail`}
								control={control}
								render={({ field: { onChange, value } }) => (
									<div>
										<Input
											label="Email"
											type="Text"
											value={serviceCopyChecked ? billingData.email : value}
											onChange={(e) => {
												onChange(e);
												setServiceData({ ...serviceData, email: e.target.value });
											}}
										/>
									</div>
								)}
							/>
							<Controller
								name={`transportor.${fields.length - 1}.servicePhone`}
								control={control}
								render={({ field: { onChange, value } }) => (
									<div>
										<PhoneInput
											label="Phone"
											value={serviceCopyChecked ? billingData.phone : value}
											onChange={(e) => {
												onChange(e);
												setServiceData({ ...serviceData, phone: e.target.value });
											}}
										/>
									</div>
								)}
							/>
						</div>
					</div>

					<div className="mt-8 ml-6 mb-2 font-normal">About</div>
					<div className="border-t"></div>
					<div className="ml-6 mr-6">
						<div className="flex gap-8 w-full mt-2">
							<div className="w-1/2">
								<Controller
									name={`transportor.${fields.length - 1}.companySlogan`}
									control={control}
									rules={{ required: "Company Slogan is required" }}
									render={({ field: { onChange, value } }) => (
										<div>
											<Input label="Company Slogan" type="Text" value={value} onChange={onChange} />
											{errors.transportor?.[fields.length - 1]?.companySlogan && (
												<p className="text-red-500 text-sm mt-1">{errors.transportor[fields.length - 1].companySlogan.message}</p>
											)}
										</div>
									)}
								/>
							</div>
							<div className="w-1/2">
								<Controller
									name={`transportor.${fields.length - 1}.companyMissionStatement`}
									control={control}
									rules={{ required: "Company Mission Statement is required" }}
									render={({ field: { onChange, value } }) => (
										<div>
											<Input label="Company Mission Statement" type="Text" value={value} onChange={onChange} />
											{errors.transportor?.[fields.length - 1]?.companyMissionStatement && (
												<p className="text-red-500 text-sm mt-1">{errors.transportor[fields.length - 1].companyMissionStatement.message}</p>
											)}
										</div>
									)}
								/>
							</div>
						</div>

						<div className="flex gap-8 w-full ">
							<div className="w-1/2">
								<Controller
									name={`transportor.${fields.length - 1}.companyVisionStatement`}
									control={control}
									rules={{ required: "Company Vision Statement is required" }}
									render={({ field: { onChange, value } }) => (
										<div>
											<Input label="Company Vision Statement" type="Text" value={value} onChange={onChange} />
											{errors.transportor?.[fields.length - 1]?.companyVisionStatement && (
												<p className="text-red-500 text-sm mt-1">{errors.transportor[fields.length - 1].companyVisionStatement.message}</p>
											)}
										</div>
									)}
								/>
							</div>
						</div>
					</div>

					<div className="p-4">Dispatch Department</div>
					<div className="border-t"></div>
					<div className="flex gap-8 w-full pl-4">
						<div className="w-1/2">
							<Controller
								name={`transportor.${fields.length - 1}.dispatchEmail`}
								control={control}
								rules={{ required: "Email is required" }}
								render={({ field: { onChange, value } }) => (
									<div>
										<Input label="Email" type="Text" value={value} onChange={onChange} />
										{errors.transportor?.[fields.length - 1]?.dispatchEmail && (
											<p className="text-red-500 text-sm mt-1">{errors.transportor[fields.length - 1].dispatchEmail.message}</p>
										)}
									</div>
								)}
							/>
						</div>
						<div className="w-1/2">
							<Controller
								name={`transportor.${fields.length - 1}.dispatchPhone`}
								control={control}
								rules={{ required: "Phone is required" }}
								render={({ field: { onChange, value } }) => (
									<div>
										<PhoneInput label="Phone" value={value} onChange={onChange} IsRequired={true} />
										{errors.transportor?.[fields.length - 1]?.dispatchPhone && (
											<p className="text-red-500 text-sm mt-1">{errors.transportor[fields.length - 1].dispatchPhone.message}</p>
										)}
									</div>
								)}
							/>
						</div>
					</div>
					<div className="p-4 mt-6">User Information</div>
					<div className="border-t"></div>
					<div className="flex justify-between">
						<div className="w-1/2 pl-4">
							<Controller
								name={`transportor.${fields.length - 1}.assignedUser`}
								control={control}
								rules={{ required: "Assigned User is required" }}
								render={({ field }) => (
									<div>
										<Dropdown
											options={DriverOptions}
											value={field.value}
											onChange={field.onChange}
											label="Assigned User"
											className="text-sm"
										/>
										{errors.transportor?.[fields.length - 1]?.assignedUser && (
											<p className="text-red-500 text-sm mt-1">
												{errors.transportor[fields.length - 1].assignedUser.message}
											</p>
										)}
									</div>
								)}
							/>

						</div>
						<div className="flex items-center justify-end pr-4 mt-4">
							<BsPlusSquareFill style={{ color: '#1493d7', marginRight: '8px', fontSize: '24px' }} />
							Add New User
						</div>
					</div>

					<div className="p-4 mt-6">OCTO Connect: Subcontractor Partnership Program</div>
					<div className="border-t"></div>
					<div className="p-4 w-10/12  text-gray-600">
						By opting into the OCTO Connect Subcontractor Partnership Program, you agree to allow contractors to potentially connect with you based on the proximity of their generator s
						address to your service radius. When a contractor expresses interest in partnering with you, a notification will be sent to inform you of the potential partnership.
					</div>

					<div className="flex py-4 px-4 gap-6">
						{Object.keys(control._formValues.transportor[0].octoConnect).map((key) => (
							<Controller
								key={key}
								name={`transportor.${fields.length - 1}.octoConnect.${key}`}
								control={control}
								render={({ field: { value, onChange } }) => <Checkbox label={key.replace(/([A-Z])/g, " $1")} isChecked={value} setIsChecked={onChange} />}
							/>
						))}
					</div>
					<div className="flex items-center justify-end pr-4 mt-4">
						<BsPlusSquareFill style={{ color: '#1493d7', marginRight: '8px', fontSize: '24px' }} />
						Add Satellite Location
					</div>
					<div className="flex justify-end gap-8 py-16 pr-6">
						<Button btnStyle="form_nav_secondary" text={"View List"} onClick={handlePreviousBtn} />
						<Button btnStyle="form_nav_primary" text={"Save"} onClick={handleSubmit(onSubmit)} />
					</div>
				</div>
			</div>
		</>
	);
};

export default AddTransporter;
