import React, { useEffect, useRef, useState, useMemo } from 'react';
import { useFieldArray, useForm, Controller } from "react-hook-form";
import Input from "../../../../../components/UI/Input";
import MultiSelectRounded from "../../../../../components/UI/dropdowns/MultiSelectRounded";
import { useNavigate, useParams } from "react-router-dom";
import { createNewVendor, GetVendorData, EditVendorDetails } from '../../../../../utils/firebaseOperations';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from '../../../../../config/firebase';
import Dropdown from '../../../../../components/UI/dropdowns/Dropdown';
import Button from '../../../../../components/UI/Button';
import FileUpload from '../../../../../components/UI/FileUpload';
import Textarea from '../../../../../components/UI/Textarea'
import PhoneInput from '../../../../../components/UI/PhoneInput';
import UrlInput from '../../../../../components/UI/UrlInput';
import UploadFiles from '../../../../transporters/UploadFiles';
import { useGeocoding } from "../../../../../hooks/treatmentGeocoding"; // Geocoding hook

const AddNewVendor = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { control, handleSubmit, reset, formState: { errors }, setValue, watch } = useForm({
        defaultValues: {
            Vendor: [{
                name: "",
                apartment: "",
                state: "",
                phoneNumber: "",
                email: "",
                vendorType: [],
                vendorStatus: "",
                street: "",
                vendorWebsite: "",
                city: "",
                zipCode: "",
                notes: "",
                altphone: "",
                attachFiles: null,
                vendorCoordinates: null,   
            }],
        },
    });

    const { fields, append } = useFieldArray({
        control,
        name: "Vendor",
    });

    const fileInputRefs = useRef([]);
    const [vendorData, setVendorData] = useState({
        id: null,
        name: "",
        apartment: "",
        state: "",
        phoneNumber: "",
        email: "",
        vendorType: [],
        vendorStatus: "",
        vendorWebsite: "",
        street: "",
        city: "",
        zipCode: "",
        notes: "",
        altphone: "",
        attachFiles: null,
        vendorCoordinates: null,
    });

    const { geocodeAddress, calculateCenterPosition } = useGeocoding();  // Geocoding hook
    const [markers, setMarkers] = useState([]);
    const [currentCoordinates, setCurrentCoordinates] = useState(null);

    const initialCenter = useMemo(() => {
        return markers.length > 0
            ? calculateCenterPosition(markers)
            : { lat: 32.7157, lng: -117.1611 };  
    }, [markers]);

    const addVendor = () => {
        append({
            name: "",
            apartment: "",
            state: "",
            phoneNumber: "",
            email: "",
            vendorType: [],
            vendorStatus: "",
            street: "",
            city: "",
            vendorWebsite: "",
            zipCode: "",
            notes: "",
            altphone: "",
            attachFiles: null,
            vendorCoordinates: null,
        });
    };

    const handlePreviousBtn = () => {
        navigate("/admin/assets/manage-vendor");
    };

    const onSubmit = async (data) => {
        try {
            let dataToBeSent = { id: vendorData.id, ...data };
            const { attachFiles, Vendor, ...rest } = dataToBeSent;

             const { vendorCoordinates } = Vendor[0];
            if (vendorCoordinates) {
                 Vendor[0].vendorCoordinates = vendorCoordinates;
            } else {
                 const { street, city, state, zipCode } = Vendor[0];
                const streets = { street: street, city: city, state: state, zip: zipCode };

                const geocodedData = await geocodeAddress(streets);
                if (geocodedData) {
                    Vendor[0].vendorCoordinates = { lat: geocodedData.lat, lng: geocodedData.lng };
                    setCurrentCoordinates({ lat: geocodedData.lat, lng: geocodedData.lng });
                    setMarkers([geocodedData]);
                }
            }

            // File upload logic
            if (attachFiles) {
                const storageRef = ref(storage, `/admin1/attachFiles/${attachFiles.name}`);
                const snapshot = await uploadBytes(storageRef, attachFiles);
                const downloadURL = await getDownloadURL(snapshot.ref);

                Vendor[0].attachFiles = {
                    name: attachFiles.name,
                    url: downloadURL,
                };
            }

            if (id) {
                // Update vendor details in the database
                await EditVendorDetails(Vendor[0], id);
                toast.success("Vendor details updated successfully!");
                navigate("/admin/assets/manage-vendor");
            } else {
                // Create a new vendor
                await createNewVendor(Vendor[0]);
                toast.success("Vendor added successfully!");
                reset();
            }
        } catch (error) {
            console.error("Error saving vendor:", error);
            toast.error("Failed to save vendor: " + (error.message || "Unknown error."));
        }
    };

    const fetchVendorData = async () => {
        if (id) {
            try {
                const vendorData = await GetVendorData(id);
                if (vendorData) {
                    const initializedVendorData = {
                        ...vendorData,
                        vendorType: vendorData.vendorType || [],
                        vendorCoordinates: vendorData.vendorCoordinates || null,  
                    };

                    reset({ Vendor: [initializedVendorData] });
                    setVendorData(initializedVendorData);
                    setMarkers(vendorData.vendorCoordinates ? [vendorData.vendorCoordinates] : []);  
                }
            } catch (error) {
                console.error("Error fetching vendor data:", error);
                toast.error("Failed to fetch vendor data.");
            }
        } else {
            reset({
                Vendor: [{
                    name: "",
                    apartment: "",
                    state: "",
                    phoneNumber: "",
                    email: "",
                    vendorType: [],
                    vendorStatus: "",
                    street: "",
                    city: "",
                    vendorWebsite: "",
                    zipCode: "",
                    notes: "",
                    altphone: "",
                    attachFiles: null,
                    vendorCoordinates: null,
                }],
            });
            setVendorData({ id: null, attachFiles: null, vendorCoordinates: null });
        }
    };

    useEffect(() => {
        fetchVendorData();
    }, [id]);

    const handleDeleteFile = async () => {
        // Implement file deletion logic (if needed)
    };

    // Handle geocoding changes on address input change
    const handleAddressChange = async () => {
        const { street, city, state, zipCode } = watch("Vendor")[0];
        const address = { street: street, city: city, state: state, zip: zipCode };

        const geocodedData = await geocodeAddress(address);
        if (geocodedData) {
            setCurrentCoordinates({ lat: geocodedData.lat, lng: geocodedData.lng });
            setMarkers([geocodedData]);
        }
    };

    useEffect(() => {
        // Trigger geocoding if address is changed
        handleAddressChange();
    }, [watch("Vendor")[0].street, watch("Vendor")[0].city, watch("Vendor")[0].state, watch("Vendor")[0].zipCode]);

    const stateOptions = [
        { value: 'AZ', label: 'AZ' },
        { value: 'CA', label: 'CA' },
        { value: 'FL', label: 'FL' },
        { value: 'GA', label: 'GA' },
        { value: 'IL', label: 'IL' },
        { value: 'MI', label: 'MI' },
        { value: 'MA', label: 'MA' },
        { value: 'NC', label: 'NC' },
        { value: 'NJ', label: 'NJ' },
        { value: 'NY', label: 'NY' },
        { value: 'OH', label: 'OH' },
        { value: 'PA', label: 'PA' },
        { value: 'TX', label: 'TX' },
        { value: 'VA', label: 'VA' },
        { value: 'WA', label: 'WA' }
    ]
    
    const vendorOptions = [
        { value: "Supply Vendor", label: 'Supply Vendor' },
        { value: "Service Vendor", label: 'Service Vendor' },
    ];

    const VendorStatus = [
        { value: "Active", label: 'Active' },
        { value: "Inactive", label: 'Inactive' },
    ];

    const formatPhoneNumber = (value) => {
        const numericValue = value.replace(/[^\d]/g, "");
        const formattedValue = numericValue.replace(
            /(\d{3})(\d{3})(\d{4})/,    
            "($1) $2-$3"
        );
        return numericValue.length > 10 ? formattedValue.slice(0, 14) : formattedValue;
    };

    return (
        <div className='p-6'>
            <div className="flex flex-col border border-cardBorderCol bg-white rounded-cardRadii h-full">
                <ToastContainer />
                <div className='flex justify-between my-4 mx-6'>
                    <div>Vendor Profile</div>
                </div>
                <div className='border-b mb-2 border-[#CCCCCC]'></div>
                <div className='ml-6 mr-6'>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        {fields.map((field, index) => (
                            <div className="flex gap-8 w-full" key={field.id}>
                                <div className="w-1/2">
                                    <Controller
                                        name={`Vendor.${index}.name`}
                                        control={control}
                                        rules={{ required: "Vendor Name is required" }}
                                        render={({ field: { onChange, value } }) => (
                                            <div>
                                                <Input
                                                    className="w-full px-2 py-1 text-sm"
                                                    label="Vendor Name*"
                                                    value={value}
                                                    onChange={onChange}
                                                />
                                                {errors.Vendor?.[index]?.name && <p className="text-red-500 text-sm mt-1">{errors.Vendor[index].name.message}</p>}
                                            </div>
                                        )}
                                    />
                                    <Controller
                                        name={`Vendor.${index}.street`}
                                        control={control}
                                        //rules={{ required: "Address is required" }}
                                        render={({ field: { onChange, value } }) => (
                                            <div>
                                                <Input
                                                    className="w-full px-2 py-1 text-sm"
                                                    label="street"
                                                    value={value}
                                                    onChange={onChange}
                                                />
                                                {errors.Vendor?.[index]?.street && <p className="text-red-500 text-sm mt-1">{errors.Vendor[index].street.message}</p>}
                                            </div>
                                        )}
                                    />
                                    <Controller
                                        name={`Vendor.${index}.apartment`}
                                        control={control}
                                        //   rules={{ required: "Suite/Apartment is required" }}
                                        render={({ field: { onChange, value } }) => (
                                            <div>
                                                <Input
                                                    className="w-full px-2 py-1 text-sm"
                                                    label="Suite"
                                                    value={value}
                                                    onChange={onChange}
                                                />
                                                {errors.Vendor?.[index]?.apartment && <p className="text-red-500 text-sm mt-1">{errors.Vendor[index].apartment.message}</p>}
                                            </div>
                                        )}
                                    />
                                    <Controller
                                        name={`Vendor.${index}.city`}
                                        control={control}
                                        // rules={{ required: "City is required" }}
                                        render={({ field: { onChange, value } }) => (
                                            <div>
                                                <Input
                                                    className="w-full px-2 py-1 text-sm"
                                                    label="City"
                                                    value={value}
                                                    onChange={onChange}
                                                />
                                                {errors.Vendor?.[index]?.city && <p className="text-red-500 text-sm mt-1">{errors.Vendor[index].city.message}</p>}
                                            </div>
                                        )}
                                    />
                                    <Controller
                                        name={`Vendor.${index}.state`}
                                        control={control}
                                        //  rules={{ required: "State is required" }}
                                        render={({ field: { value, onChange } }) => (
                                            <div>
                                                < Dropdown
                                                    value={value}
                                                    onChange={onChange}
                                                    options={stateOptions}
                                                    label="State"
                                                    className="text-sm"
                                                />
                                                {errors.Vendor?.[index]?.state && <p className="text-red-500 text-sm mt-1">{errors.Vendor[index].state.message}</p>}
                                            </div>
                                        )}
                                    />
                                    <Controller
                                        name={`Vendor.${index}.zipCode`}
                                        control={control}
                                        //   rules={{ required: "Zip Code is required", pattern: { value: /^[0-9]+$/, message: "Zip Code must be numeric" } }}
                                        render={({ field: { onChange, value } }) => (
                                            <div>
                                                <Input
                                                    className="w-full px-2 py-1 text-sm"
                                                    label="Zip"
                                                    value={value}
                                                    onChange={onChange}
                                                />
                                                {errors.Vendor?.[index]?.zipCode && <p className="text-red-500 text-sm mt-1">{errors.Vendor[index].zipCode.message}</p>}
                                            </div>
                                        )}
                                    />
                                    <Controller
                                        name={`Vendor.${index}.vendorWebsite`}
                                        control={control}
                                        rules={{
                                            pattern: {
                                                value: /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/\S*)?$/,
                                                message: "Please enter a valid URL"
                                            }
                                        }}
                                        render={({ field: { onChange, value } }) => (
                                            <div>
                                                <UrlInput
                                                    className="w-full px-2 py-1 text-sm"
                                                    label="Vendor Website"
                                                    value={value}
                                                    onChange={onChange}
                                                />
                                                {errors.Vendor?.[index]?.vendorWebsite && (
                                                    <p className="text-red-500 text-sm mt-1">
                                                        {errors.Vendor[index].vendorWebsite.message}
                                                    </p>
                                                )}
                                            </div>
                                        )}
                                    />

                                </div>
                                <div className="w-1/2">
                                    <Controller
                                        name={`Vendor.${index}.email`}
                                        control={control}
                                        rules={{
                                            //  required: "email is required",
                                            pattern: {
                                                value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                                                message: "Please enter a valid email address"
                                            }
                                        }}
                                        render={({ field: { onChange, value } }) => (
                                            <div>
                                                <Input
                                                    className="w-full px-2 py-1 text-sm"
                                                    label="Email"
                                                    value={value}
                                                    onChange={onChange}
                                                />
                                                {errors.Vendor?.[index]?.email && (
                                                    <p className="text-red-500 text-sm mt-1">{errors.Vendor[index].email.message}</p>
                                                )}
                                            </div>
                                        )}
                                    />

                                    <Controller
                                        name={`Vendor.${index}.phoneNumber`}
                                        control={control}
                                        // rules={{ pattern: { value: /^\(\d{3}\) \d{3}-\d{4}$/, message: "Phone number format is invalid" } }}
                                        render={({ field }) => (
                                            <div>
                                                <PhoneInput
                                                    id={`Vendor.${index}.phoneNumber`}
                                                    label="Phone Number"
                                                    value={field.value || ""}
                                                    onChange={(formattedNumber) => {
                                                        field.onChange(formattedNumber);
                                                    }}
                                                    isDisabled={false}
                                                // IsRequired={true}
                                                />
                                                {errors.Vendor?.[index]?.phoneNumber && (
                                                    <p className="text-red-500 text-sm mt-1">{errors.Vendor[index].phoneNumber.message}</p>
                                                )}
                                            </div>
                                        )}
                                    />

                                    <Controller
                                        name={`Vendor.${index}.altphone`}
                                        control={control}
                                        render={({ field }) => (
                                            <div>
                                                <PhoneInput
                                                    id={`Vendor.${index}.altphone`}
                                                    label="Alt Phone Number"
                                                    value={field.value}
                                                    onChange={(formattedNumber) => {
                                                        field.onChange(formattedNumber);
                                                    }}
                                                    isDisabled={false}
                                                    IsRequired={false}
                                                />
                                                {errors.Vendor?.[index]?.altphone && <p className="text-red-500 text-sm mt-1">{errors.Vendor[index].altphone.message}</p>}
                                            </div>
                                        )}
                                    />
                                    <Controller
                                        name={`Vendor.${index}.vendorType`}
                                        control={control}
                                        // rules={{ required: "Vendor Type is required" }}
                                        render={({ field: { value, onChange } }) => (
                                            <div>
                                                <MultiSelectRounded
                                                    value={value}
                                                    onChange={onChange}
                                                    options={vendorOptions}
                                                    label="Vendor Type"
                                                    className="text-sm"
                                                />
                                                {errors.Vendor?.[index]?.vendorType && <p className="text-red-500 text-sm mt-1">{errors.Vendor[index].vendorType.message}</p>}
                                            </div>
                                        )}
                                    />
                                    <Controller
                                        name={`Vendor.${index}.vendorStatus`}
                                        control={control}
                                        //  rules={{ required: "Vendor Status is required" }}
                                        render={({ field: { value, onChange } }) => (
                                            <div>
                                                <Dropdown
                                                    value={value}
                                                    onChange={onChange}
                                                    options={VendorStatus}
                                                    label="Vendor Status"
                                                    className="text-sm"
                                                />
                                                {errors.Vendor?.[index]?.vendorStatus && <p className="text-red-500 text-sm mt-1">{errors.Vendor[index].vendorStatus.message}</p>}
                                            </div>
                                        )}
                                    />
                                    <Controller
                                        name={`Vendor.${index}.notes`}
                                        control={control}
                                        render={({ field: { onChange, value } }) => (
                                            <div>
                                                <Textarea
                                                    className="w-full px-2 py-1 text-sm"
                                                    label="Notes"
                                                    value={value}
                                                    onChange={onChange}
                                                />
                                            </div>
                                        )}
                                    />

                                    <div className="flex items-center justify-between">
                                        <label htmlFor="contractFile" className="truncate text-inputLabel font-normal mt-3"></label>
                                        <div className="w-full">
                                            <UploadFiles
                                                control={control}
                                                errors={errors}
                                                label="Attach File"
                                                name="attachFiles"
                                                existingFile={vendorData.attachFiles ? {
                                                    url: vendorData.attachFiles,
                                                    name: vendorData.attachFiles.name || 'Attached File'
                                                } : null}
                                                onDeleteFile={handleDeleteFile}
                                                isRequired={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}

                        <div className="flex justify-end gap-8 py-10">
                            <Button
                                onClick={handlePreviousBtn}
                                btnStyle="form_nav_secondary"
                                text={"View Vendor List"}
                            />
                            <Button
                                type="submit"
                                btnStyle="form_nav_primary"
                                text={"Save"}
                            >
                                {id ? "Save" : "Save"}
                            </Button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default AddNewVendor;