import React from "react";
import { HiArrowNarrowUp } from "react-icons/hi";

const DashInfoCard = ({ cardColor = "green", title = "", count = "", changePercentage = "" }) => {
	const isGreen = cardColor === "green";
	const bgColor = isGreen ? "bg-primaryGreen" : "bg-lightBlue";
	const textColor = isGreen ? "text-black" : "text-white";
	const subtitleColor = isGreen ? "text-cardTextGray" : "text-white";
	const arrowColor = isGreen ? "text-cardTextGreen" : "text-cardTextBlue";

	return (
		<div className={`${bgColor} text-white rounded-cardRadii p-4 md:p-6 flex justify-between flex-col gap-1`}>
			<p className={`text-sm md:text-lg ${subtitleColor}`}>{title}</p>
			<h1 className={`text-xl md:text-2xl font-bold ${textColor}`}>{count}</h1>

			<div className="flex items-center max-w-max bg-white bg-opacity-30 rounded-full">
				<div className="flex items-center bg-white rounded-full px-2 md:px-3 py-1">
					<HiArrowNarrowUp className={`${arrowColor} font-bold`} />
					<span className={`${arrowColor} font-bold`}>{changePercentage}%</span>
				</div>
				<p className="mx-4 text-xs md:text-sm text-gray-100 whitespace-nowrap truncate">from last month</p>
			</div>
		</div>
	);
};

export default DashInfoCard;
