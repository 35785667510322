import { useMapsLibrary } from "@vis.gl/react-google-maps";
import { useCallback, useMemo } from "react";

export const useGeocoding = () => {
  const geocodingLib = useMapsLibrary("geocoding");
  const geocoder = useMemo(() => geocodingLib && new geocodingLib.Geocoder(), [geocodingLib]);

  // In-memory cache for geocoding results
  const geocodeCache = useMemo(() => new Map(), []);
  
  // Cache key to uniquely identify each treatment address
  const cacheKey = (address) => `${address.street}_${address.city}_${address.state}_${address.zip}`;

  // Save geocoded result to sessionStorage and in-memory cache
  const saveToCache = (key, data) => {
    sessionStorage.setItem(key, JSON.stringify(data));  // Store in sessionStorage
    geocodeCache.set(key, data);  // Also store in in-memory cache
  };

  // Retrieve geocoding result from sessionStorage or in-memory cache
  const getFromCache = (key) => {
    if (geocodeCache.has(key)) {
      return geocodeCache.get(key);
    }
    const cached = sessionStorage.getItem(key);
    return cached ? JSON.parse(cached) : null;
  };

  // Geocode a single treatment address
  const geocodeAddress = useCallback(
    async (address, otherData = {}) => {
      const formattedAddress = `${address.street}, ${address.city}, ${address.state} ${address.zip}`;

      // If coordinates are already passed, skip geocoding
      if (otherData?.coordinates?.lat && otherData?.coordinates?.lng) {
        return {
          key: `${otherData.coordinates.lat + otherData.coordinates.lng + Math.floor(Math.random() * 1000)}, ${otherData?.name ?? otherData?.facilityName}`,
          lat: otherData.coordinates.lat,
          lng: otherData.coordinates.lng,
          ...otherData,
        };
      }

      if (!geocoder) {
        console.warn("Geocoder not initialized yet");
        return null;
      }

      if (!address.city || !address.state || !address.zip) return null;

      const key = cacheKey(address);
      const cachedResult = getFromCache(key);

      if (cachedResult) {
        console.log(`Using cached geocode result for ${key}`);
        return { ...cachedResult, ...otherData };
      }
      console.log("No cached data found, performing geocoding");

      return new Promise((resolve, reject) => {
        geocoder.geocode({ address: formattedAddress }, (results, status) => {
          if (status === window.google.maps.GeocoderStatus.OK && results[0]) {
            const geocodedData = {
              key: `${results[0].geometry.location.lat() + results[0].geometry.location.lng() + Math.floor(Math.random() * 1000)}, ${otherData?.name ?? otherData?.facilityName}`,
              lat: results[0].geometry.location.lat(),
              lng: results[0].geometry.location.lng(),
              formattedAdd: formattedAddress,
              ...otherData,
            };
            saveToCache(key, geocodedData); // Save result to cache
            resolve(geocodedData);
          } else {
            console.warn(`Geocoding failed for address: ${formattedAddress}. Status: ${status}`);
            reject(null);
          }
        });
      });
    },
    [geocoder, geocodeCache]
  );

  // Batch geocode multiple addresses for treatment facilities
  const batchGeocodeTreatmentAddresses = async (treatments) => {
    if (!geocoder || !treatments?.length) return [];
    const geocodedMarkers = await Promise.all(
      treatments.map((treatment) =>
        geocodeAddress(treatment.serviceAddress, treatment).catch(() => null)
      )
    );
    return geocodedMarkers.filter((marker) => marker !== null);
  };

  // Calculate center position based on geocoded markers (average of all latitudes and longitudes)
  const calculateCenterPosition = (markers) => {
    if (markers.length === 0) return null;
    if (markers.length === 1) return { lat: markers[0].lat, lng: markers[0].lng };

    const avgLat = markers.reduce((sum, marker) => sum + marker.lat, 0) / markers.length;
    const avgLng = markers.reduce((sum, marker) => sum + marker.lng, 0) / markers.length;
    return { lat: avgLat, lng: avgLng };
  };

  return {
    geocodeAddress,
    batchGeocodeTreatmentAddresses,
    calculateCenterPosition,
  };
};
