import React, { useState } from "react";
import Dropdown from "../../../components/UI/dropdowns/Dropdown";
import Button from "../../../components/UI/Button";
import { USStates } from "../../../utils/constants";
import { useNavigate } from "react-router-dom";
import SearchBox from "../../../components/UI/searchBox/Search";
import { FiSearch } from "react-icons/fi";
import { Upload } from "lucide-react";
import { toast } from "react-toastify";

const ServiceTickets = () => {
	const [isDragging, setIsDragging] = useState(false);
	const [file, setFile] = useState(null);

	const handleDragOver = (e) => {
		e.preventDefault();
		setIsDragging(true);
	};

	const handleDragLeave = () => {
		setIsDragging(false);
	};

	const handleDrop = (e) => {
		e.preventDefault();
		setIsDragging(false);

		const droppedFile = e.dataTransfer.files[0];
		if (isValidFileType(droppedFile)) {
			setFile(droppedFile);
		}
	};

	const handleFileSelect = (e) => {
		const selectedFile = e.target.files[0];
		if (isValidFileType(selectedFile)) {
			setFile(selectedFile);
		}
	};

	const isValidFileType = (file) => {
		const validTypes = [
			"application/zip",
			"image/jpeg",
			"image/png",
			"application/pdf",
			"application/msword",
			"application/vnd.openxmlformats-officedocument.wordprocessingml.document",
		];
		return file && validTypes.includes(file.type);
	};
	const handleNextClick = () => {
		console.log("Submitted successfully");
		toast.success("Submitted successfully");
	};

	return (
		<div className="p-6">
			<div className="main-container flex flex-col border p-8 gap-8 h-[80vh] border-cardBorderCol bg-white rounded-cardRadii">
				<div className="flex flex-col w-full py-16 justify-center items-center">
					<h1 className="text-lg font-medium text-center mb-2">Import Service Tickets</h1>
					<div
						className={`border-2 border-dashed rounded-lg p-8 max-w-fit ${
							isDragging ? "border-blue-500 bg-blue-50" : "border-gray-300"
						} transition-colors duration-200`}
						onDragOver={handleDragOver}
						onDragLeave={handleDragLeave}
						onDrop={handleDrop}
					>
						<div className="flex flex-col items-center justify-center text-center">
							<Upload className="w-8 h-8 text-gray-400 mb-4" />
							<p className="mb-2 font-semibold">
								Drag & Drop or{" "}
								<label className="text-blue-600 hover:text-blue-700 cursor-pointer">
									<span>choose file</span>
									<input
										type="file"
										className="hidden"
										onChange={handleFileSelect}
										accept=".zip,.pdf,.doc,.docx,.png,.jpg,.jpeg"
									/>
								</label>{" "}
								to upload
							</p>
							<p className="text-sm text-gray-500">Select zip,image,pdf or ms.word</p>
							{file && <p className="mt-4 text-sm text-green-600">Selected file: {file.name}</p>}
						</div>
					</div>
				</div>
				<div className="flex justify-end">
					<button
						onClick={handleNextClick}
						style={{ width: "184px", height: "44px" }}
						className="bg-primaryBlue text-white px-4 py-2 rounded-full border border-[#666666] hover:bg-white hover:text-black hover:border-[#666666] transition-opacity duration-300"
					>
						Submit
					</button>
				</div>
			</div>
		</div>
	);
};

export default ServiceTickets;
