import PropTypes from "prop-types";
import { useState } from "react";
import { AdvancedMarker, InfoWindow, useAdvancedMarkerRef } from "@vis.gl/react-google-maps";

export const MarkerWithInfoWindow = ({ children, position, name, updatePlaceName, isEditable = true, pin = null }) => {
	const [infoWindowOpen, setInfoWindowOpen] = useState(false);
	const [markerRef, marker] = useAdvancedMarkerRef();

	return (
		<>
			<AdvancedMarker ref={markerRef} onClick={() => setInfoWindowOpen(true)} position={position}>
				{pin}
			</AdvancedMarker>
			{infoWindowOpen && (
				<InfoWindow
					anchor={marker}
					className="w-80"
					onCloseClick={() => setInfoWindowOpen(false)}
					headerContent={
						<div>
							<p onClick={() => isEditable && updatePlaceName()} className="font-semibold text-sm">
								{name?.length > 0 ? name : "Click here to add Location name."}
							</p>
						</div>
					}
					// eslint-disable-next-line react/no-children-prop
					children={children}
					ariaLabel={"This is Aria Label"}
					shouldFocus={false}
				></InfoWindow>
			)}
		</>
	);
};

MarkerWithInfoWindow.propTypes = {
	children: PropTypes.node,
	isEditable: PropTypes.bool,
	name: PropTypes.string,
	pin: PropTypes.any,
	position: PropTypes.any,
	updatePlaceName: PropTypes.func,
};
