import React from 'react';

const ProgressBar = ({ completed, scheduled, incompleted, onClick }) => {
  console.log({ completed, scheduled, incompleted });

  const totalTasks = completed + scheduled + incompleted;
  const total = totalTasks || 1; // Prevent division by zero

  const completedWidth = (completed / total) * 100; // Green (Completed Service)
  const scheduledWidth = (scheduled / total) * 100; // Red (Incomplete Service)
  const incompletedWidth = (incompleted / total) * 100; // Blue (Total Service)


  return (
    <div className="flex flex-col items-center w-full">
      {/* Labels for sections */}
      <div className="relative w-full mb-2">
        {incompleted && <div
          className="absolute top-[-16px] text-sm font-medium"
          style={{ width: `${incompletedWidth}%`, left: '0%' }}
        >
          <p>Inprogress Service(s)</p>
        </div>}

        {scheduled > 0 && <div
          className="absolute top-[-16px] text-sm font-medium"
          style={{ width: `${scheduledWidth}%`, left: `${incompletedWidth}%` }}
        >
          Scheduled Service(s)
        </div>}

        {completed && <div
          className="absolute top-[-16px] text-sm font-medium"
          style={{ width: `${completedWidth}%`, left: `${incompletedWidth + scheduledWidth}%` }}
        >
          Complete Service(s)
        </div>}
      </div>

      {/* Number of tasks displayed */}
      <div className="relative w-full mb-2">
        {incompleted && <div
          className="absolute text-sm font-medium mt-8"
          style={{ width: `${incompletedWidth}%`, left: '0%' }}
        >
          <span className="absolute right-0 text-sm font-medium">{incompleted}</span>
        </div>}
        {scheduled > 0 && <div
          className="absolute text-sm font-medium mt-8"
          style={{ width: `${scheduledWidth}%`, left: `${incompletedWidth}%` }}
        >
          <span className="absolute right-0 text-sm font-medium">{scheduled}</span>
        </div>}

        {completed && <div
          className="absolute text-sm font-medium mt-8"
          style={{ width: `${completedWidth}%`, left: `${incompletedWidth + scheduledWidth}%` }}
        >
          <span className="absolute right-0 text-sm font-medium">{completed}</span>
        </div>}
      </div>

      {/* Clickable Progress Bar */}
      <div
        className="relative w-full h-5 bg-gray-200 rounded-md overflow-hidden cursor-pointer"
        onClick={onClick}
      >
        {incompleted && <div
          className="absolute top-0 left-0 h-full bg-blue-500"
          style={{ width: `${incompletedWidth}%` }}
        />}
        {scheduled && <div
          className="absolute top-0 h-full bg-red-500"
          style={{ width: `${scheduledWidth}%`, left: `${incompletedWidth}%` }}
        />}
        {completed && <div
          className="absolute top-0 h-full bg-green-400"
          style={{ width: `${completedWidth}%`, left: `${incompletedWidth + scheduledWidth}%` }}
        />}
      </div>
    </div>
  );
};

export default ProgressBar;
