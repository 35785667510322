import React, { useState, useEffect } from "react";
import ProgressBar from "./components/ProgressBar";
import DateRangePicker from "../../../components/UI/DateRangePicker";
import Dropdown from "../../../components/UI/dropdowns/Dropdown";
import BoxTable from "./components/BoxTable";
import MultipleMarkersMap from "./components/MultipleMarkersMap";
import { useNavigate } from "react-router-dom";
import {
	getAllRoutes,
	getAllGenerators,
	getAllVendor,
	getDrivers,
	getAllTreatment,
} from "../../../utils/firebaseOperations";
import { serviceTypes, generatorStatus } from "../../../utils/constants";
import MapWithRoutes from "./components/MapWithRoutes";

const BoxLocations = () => {
	const navigate = useNavigate();

	// State variables
	const [generators, setGenerators] = useState("");
	const [serviceType, setServiceType] = useState("");
	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);
	const [loading, setLoading] = useState(false);
	const [routes, setRoutes] = useState([]);
	const [generatorOptions, setGeneratorOptions] = useState([]);
	const [vendors, setVendors] = useState([]);
	const [drivers, setDrivers] = useState([]);
	const [treatments, setTreatments] = useState([]);

	// Dummy data for box table
	const [data, setData] = useState([
		{ id: 1, date: "2024-10-05", time: "10:30 AM", box: "Box A", generatorName: "Generator 1" },
		{ id: 2, date: "2024-10-06", time: "11:45 AM", box: "Box B", generatorName: "Generator 2" },
		{ id: 3, date: "2024-10-07", time: "02:15 PM", box: "Box C", generatorName: "Generator 3" },
		{ id: 4, date: "2024-10-08", time: "01:00 PM", box: "Box D", generatorName: "Generator 4" },
	]);

	// Marker locations
	const locations = [
		{ latitude: 34.052235, longitude: -118.243683 }, // Los Angeles (Downtown)
		{ latitude: 34.068922, longitude: -118.445183 }, // UCLA Campus
		{ latitude: 34.139729, longitude: -118.035345 }, // Pasadena
		{ latitude: 34.05266, longitude: -118.248524 }, // LA Live (Staples Center)
		{ latitude: 34.101558, longitude: -118.340944 }, // Griffith Observatory
		{ latitude: 34.063536, longitude: -118.445091 }, // Santa Monica Pier
		{ latitude: 34.020181, longitude: -118.69192 }, // Venice Beach
		{ latitude: 34.076234, longitude: -118.398844 }, // Beverly Hills
		{ latitude: 33.941589, longitude: -118.40853 }, // Los Angeles International Airport (LAX)
		{ latitude: 34.064858, longitude: -118.300364 }, // Koreatown
	];

	// Fetch all data in parallel
	useEffect(() => {
		const fetchData = async () => {
			try {
				setLoading(true);
				const [routesData, generatorsData, vendorsData, driversData, treatmentsData] = await Promise.all([
					getAllRoutes(),
					getAllGenerators(),
					getAllVendor(),
					getDrivers(),
					getAllTreatment(),
				]);

				setRoutes(routesData);
				setVendors(vendorsData);
				setDrivers(driversData);
				setTreatments(treatmentsData);

				const generatorOptions = generatorsData
					.filter((gen) => gen.generatorName && gen.generatorName.trim() !== "" && gen.id >= 0)
					.map((gen) => ({ label: gen.generatorName, value: gen.id }));

				setGeneratorOptions(generatorOptions);
			} catch (error) {
				console.error("Error fetching data:", error);
			} finally {
				setLoading(false);
			}
		};

		fetchData();
	}, []);

	// Handle filter logic
	const handleFilter = () => {
		console.log("Filter button clicked");
		console.log("Selected Date Range:", startDate, endDate);
		console.log("Selected Generator:", generators);
		console.log("Selected Service Type:", serviceType);
	};

	// Navigation handlers
	const handlePrevious = () => navigate("/admin/wastes/wastefinder");
	const handleNext = () => {
		console.log("its clicked");
		navigate("/admin/wastes/weights");
	};

	return (
		<div className="p-6">
			<div className="main-container flex flex-col border bg-white rounded-cardRadii h-full">
				{/* <div className="text-lg font-medium px-8 p-6 pb-2 border-b border-cardTextGray border-opacity-40">
					Box Locations
				</div> */}

				<div className="px-8 py-4 relative">
					<div className="text-lg font-medium py-2 pb-4">View Map</div>
					<MapWithRoutes routes={[]} isLoading={false} />
				</div>

				{/* <div className='px-6 py-4 relative'>
          <MultipleMarkersMap locations={locations} isLoading={loading} />
        </div> */}

				<div className="text-lg font-medium mx-8 pb-2 border-b-2 border-cardTextGray border-opacity-40">
					Customizing Reports
				</div>

				<div className="flex items-end py-[2%] gap-6 px-8">
					<div className="w-56">
						<p className="text-cardTextGray">Date</p>
						<DateRangePicker
							startDate={startDate}
							endDate={endDate}
							setStartDate={setStartDate}
							setEndDate={setEndDate}
						/>
					</div>

					<Dropdown
						label="Generator"
						options={generatorOptions}
						styles="flex-col gap-1 w-56"
						margin={"0"}
						value={generators}
						onChange={setGenerators}
					/>

					<Dropdown
						label="Service type"
						options={serviceTypes}
						styles="flex-col gap-1 w-56"
						margin={"0"}
						value={serviceType}
						onChange={setServiceType}
					/>

					<button
						onClick={handleFilter}
						style={{ width: "150px", height: "36px" }}
						className="bg-lightBlue text-white rounded-full hover:bg-primaryBlue hover:border-[#666666] transition-all duration-300"
					>
						View
					</button>
				</div>

				<div className="text-lg font-medium px-8 py-2">Current Location Report</div>
				<BoxTable loading={loading} data={data} handleRowClick={(id) => console.log(`Row with ID ${id} clicked!`)} />

				<div className="flex justify-end p-4 mb-12 mt-12">
					<button
						onClick={handlePrevious}
						className="bg-[#007AFF] text-white px-4 py-2 rounded-full mr-4 border border-[#666666] hover:bg-white hover:text-black hover:border-[#666666] transition duration-300"
						style={{ width: "184px", height: "44px" }}
					>
						Previous
					</button>
					<button
						onClick={handleNext}
						className="bg-[#007AFF] text-white px-4 py-2 rounded-full border border-[#666666] hover:bg-white hover:text-black hover:border-[#666666] transition duration-300"
						style={{ width: "184px", height: "44px" }}
					>
						Next
					</button>
				</div>
			</div>
		</div>
	);
};

export default BoxLocations;
