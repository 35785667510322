// import { useMapsLibrary } from "@vis.gl/react-google-maps";
// import { useCallback, useMemo } from "react";

// export const useGeocoding = () => {
// 	const geocodingLib = useMapsLibrary("geocoding");
// 	const geocoder = useMemo(() => geocodingLib && new geocodingLib.Geocoder(), [geocodingLib]);

// 	const geocodeAddress = useCallback(
// 		async (address, otherData = {}) => {
// 			if (!geocoder) {
// 				console.warn("Geocoder not initialized yet");
// 				return null;
// 			}
// 			if (!address.city && !address.state && !address.zipCode) return null;
// 			const formattedAddress = `${address.street}, ${address.city}, ${address.state} ${address.zipCode}`;
// 			return new Promise((resolve, reject) => {
// 				geocoder.geocode({ address: formattedAddress }, (results, status) => {
// 					if (status === window.google.maps.GeocoderStatus.OK && results[0]) {
// 						resolve({
// 							key: `${results[0].geometry.location.lat() + results[0].geometry.location.lng() + Math.floor(Math.random() * 1000)}, ${address.name}`,
// 							lat: results[0].geometry.location.lat(),
// 							lng: results[0].geometry.location.lng(),
// 							formattedAdd: formattedAddress,
// 							name: address?.name ?? "",
// 							...otherData,
// 						});
// 					} else {
// 						console.warn(`Geocoding failed for address: ${formattedAddress}. Status: ${status}`);
// 						reject(null);
// 					}
// 				});
// 			});
// 		},
// 		[geocoder] // geocoder is the only dependency
// 	);

// 	const batchGeocodeAddresses = async (addresses) => {
// 		if (!geocoder || !addresses?.length) return [];
// 		const geocodedMarkers = await Promise.all(addresses.map((address) => geocodeAddress(address).catch(() => null)));
// 		return geocodedMarkers.filter((marker) => marker !== null);
// 	};

// 	const batchGeocodeGeneratorsAddresses = async (generators) => {
// 		if (!geocoder || !generators?.length) return [];
// 		const geocodedMarkers = await Promise.all(generators.map((generator) => geocodeAddress(generator.serviceAddress, generator).catch(() => null)));
// 		return geocodedMarkers.filter((marker) => marker !== null);
// 	};

// 	const calculateCenterPosition = (markers) => {
// 		if (markers.length === 0) return null;
// 		if (markers.length === 1) return { lat: markers[0].lat, lng: markers[0].lng };

// 		const avgLat = markers.reduce((sum, marker) => sum + marker.lat, 0) / markers.length;
// 		const avgLng = markers.reduce((sum, marker) => sum + marker.lng, 0) / markers.length;
// 		return { lat: avgLat, lng: avgLng };
// 	};

// 	return {
// 		geocodeAddress,
// 		batchGeocodeAddresses,
// 		calculateCenterPosition,
// 		batchGeocodeGeneratorsAddresses,
// 	};
// };

import { useMapsLibrary } from "@vis.gl/react-google-maps";
import { useCallback, useMemo } from "react";

export const useGeocoding = () => {
	const geocodingLib = useMapsLibrary("geocoding");
	const geocoder = useMemo(() => geocodingLib && new geocodingLib.Geocoder(), [geocodingLib]);

	const geocodeCache = useMemo(() => new Map(), []); // In-memory cache
	const cacheKey = (address) => `${address.street}_${address.city}_${address.state}_${address.zipCode}`;

	// Save geocoded result to sessionStorage (or you can use IndexedDB/localStorage)
	const saveToCache = (key, data) => {
		sessionStorage.setItem(key, JSON.stringify(data));
		geocodeCache.set(key, data); // Also cache it in-memory
	};

	// Retrieve from sessionStorage or memory
	const getFromCache = (key) => {
		if (geocodeCache.has(key)) {
			return geocodeCache.get(key);
		}
		const cached = sessionStorage.getItem(key);
		return cached ? JSON.parse(cached) : null;
	};

	const geocodeAddress = useCallback(
		async (address, otherData = {}) => {
			const formattedAddress = `${address.street}, ${address.city}, ${address.state} ${address.zipCode}`;
			// Skip geocoding if coordinates already exist
			if (otherData?.serviceAddCoordinates?.lat && otherData?.serviceAddCoordinates?.lng) {
				return {
					key: `${otherData.serviceAddCoordinates.lat + otherData.serviceAddCoordinates.lng + Math.floor(Math.random() * 1000)}, ${otherData?.name ?? otherData?.generatorName}`,
					lat: otherData.serviceAddCoordinates.lat,
					lng: otherData.serviceAddCoordinates.lng,
					...otherData,
				};
			}

			if (!geocoder) {
				console.warn("Geocoder not initialized yet");
				return null;
			}

			if (!address.city && !address.state && !address.zipCode) return null;

			const key = cacheKey(address);
			const cachedResult = getFromCache(key);

			if (cachedResult) {
				console.log(`Using cached geocode result for ${key}`);
				return { ...cachedResult, ...otherData };
			}
			console.log("Cached data not found, going to fetch from geocoding");

			return new Promise((resolve, reject) => {
				geocoder.geocode({ address: formattedAddress }, (results, status) => {
					if (status === window.google.maps.GeocoderStatus.OK && results[0]) {
						const geocodedData = {
							key: `${results[0].geometry.location.lat() + results[0].geometry.location.lng() + Math.floor(Math.random() * 1000)}, ${otherData?.name ?? otherData?.generatorName}`,
							lat: results[0].geometry.location.lat(),
							lng: results[0].geometry.location.lng(),
							formattedAdd: formattedAddress,
							...otherData,
						};
						saveToCache(key, geocodedData); // Save to cache
						resolve(geocodedData);
					} else {
						console.warn(`Geocoding failed for address: ${formattedAddress}. Status: ${status}`);
						reject(null);
					}
				});
			});
		},
		[geocoder, geocodeCache]
	);

	const batchGeocodeGeneratorsAddresses = async (generators) => {
		if (!geocoder || !generators?.length) return [];
		const geocodedMarkers = await Promise.all(generators.map((generator) => geocodeAddress(generator.serviceAddress, generator).catch(() => null)));
		return geocodedMarkers.filter((marker) => marker !== null);
	};

	const calculateCenterPosition = (markers) => {
		if (markers.length === 0) return null;
		if (markers.length === 1) return { lat: markers[0].lat, lng: markers[0].lng };

		const avgLat = markers.reduce((sum, marker) => sum + marker.lat, 0) / markers.length;
		const avgLng = markers.reduce((sum, marker) => sum + marker.lng, 0) / markers.length;
		return { lat: avgLat, lng: avgLng };
	};

	return {
		geocodeAddress,
		batchGeocodeGeneratorsAddresses,
		calculateCenterPosition,
	};
};
