import React, { useEffect, useState } from 'react';
import { useMap, Map, useMapsLibrary } from "@vis.gl/react-google-maps";

// Predefined array of colors for routes
const routeColors = [
  '#FF0000', '#00FF00', '#0000FF', '#FFFF00', '#FF00FF', '#00FFFF', '#FFA500', '#800080', '#008000', '#000080',
];

function MapWithRoutes({ routes = [], isLoading }) {
  const map = useMap();

  useEffect(() => {
    if (!map || routes.length === 0) return;

    const bounds = new window.google.maps.LatLngBounds();

    routes.forEach((route) => {
      bounds.extend(new window.google.maps.LatLng(route.startLocation.latitude, route.startLocation.longitude));
      bounds.extend(new window.google.maps.LatLng(route.endLocation.latitude, route.endLocation.longitude));
      route.stops.forEach((stop) => {
        bounds.extend(new window.google.maps.LatLng(stop.lat, stop.lng));
      });
    });

    map.fitBounds(bounds);
  }, [map, routes]);

  return (
    <div style={{ height: '400px', width: '100%' }} className="z-0 overflow-hidden rounded-secondary_radii relative">
      {isLoading ? (
        <div className="animate-pulse h-full w-full bg-gray-200 rounded-lg">
          <div className="w-full h-full bg-gradient-to-r from-gray-200 via-gray-300 to-gray-200 rounded-lg" />
        </div>
      ) : (
        <Map
          defaultCenter={{ lat: 32.7364432, lng: -117.1460877 }}
          defaultZoom={8}
          mapId={"ROUTES_ONE"}
        >
          {routes.map((route, index) => (
            <React.Fragment key={index}>
              <Directions
                route={route}
                color={routeColors[index % routeColors.length]}
              />
              <RoutePolygon
                route={route}
                color={routeColors[index % routeColors.length]}
              />
              {/* <RouteMarkers
                route={route}
                color={routeColors[index % routeColors.length]}
              /> */}
            </React.Fragment>
          ))}
        </Map>
      )}
    </div>
  );
}

const Directions = ({ route, color }) => {
  const [directionService, setDirectionService] = useState(null);
  const [directionRenderer, setDirectionRenderer] = useState(null);
  const map = useMap();
  const routesLib = useMapsLibrary("routes");

  useEffect(() => {
    if (!routesLib || !map) return;
    setDirectionService(new routesLib.DirectionsService());
    const renderer = new routesLib.DirectionsRenderer({
      map,
      polylineOptions: {
        strokeColor: color,
        strokeWeight: 4,
      },
    });
    setDirectionRenderer(renderer);
  }, [routesLib, map, color]);

  useEffect(() => {
    if (!directionService || !directionRenderer || !route.startLocation || !route.endLocation) return;

    const waypoints = route.stops.map((stop) => ({
      location: { lat: stop.lat, lng: stop.lng },
      stopover: true,
    }));

    directionService
      .route({
        origin: { lat: route.startLocation.latitude, lng: route.startLocation.longitude },
        destination: { lat: route.endLocation.latitude, lng: route.endLocation.longitude },
        waypoints: waypoints,
        travelMode: "DRIVING",
        optimizeWaypoints: true,
        provideRouteAlternatives: false,
      })
      .then((response) => {
        directionRenderer.setDirections(response);
      })
      .catch((error) => console.error('Direction error', error));
  }, [directionService, directionRenderer, route]);

  return null;
};

// Add the Polygon to cover the area of the route
const RoutePolygon = ({ route, color }) => {
  const map = useMap();
  const [polygon, setPolygon] = useState(null);

  useEffect(() => {
    if (!map || !route) return;

    const routePath = [
      { lat: route.startLocation.latitude, lng: route.startLocation.longitude },
      ...route.stops.map((stop) => ({ lat: stop.lat, lng: stop.lng })),
      { lat: route.endLocation.latitude, lng: route.endLocation.longitude },
    ];

    const newPolygon = new window.google.maps.Polygon({
      paths: routePath,
      strokeColor: color,
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: color,
      fillOpacity: 0.2,
    });

    newPolygon.setMap(map);
    setPolygon(newPolygon);

    // Clean up the polygon when the component unmounts
    return () => {
      if (polygon) {
        polygon.setMap(null);
      }
    };
  }, [map, route, color]);

  return null;
};

// // // Add Markers for each stop
// const RouteMarkers = ({ route, color }) => {
//   const map = useMap();

//   useEffect(() => {
//     if (!map || !route) return;

//     // Mark start location with label '1'
//     new window.google.maps.Marker({
//       position: { lat: route.startLocation.latitude, lng: route.startLocation.longitude },
//       map,
//       label: '1',
//       icon: {
//         path: window.google.maps.SymbolPath.CIRCLE,
//         fillColor: color,
//         fillOpacity: 1,
//         strokeWeight: 0,
//         scale: 8,
//       },
//     });

//     // Mark stops with numeric labels (2, 3, 4, ...)
//     route.stops.forEach((stop, index) => {
//       new window.google.maps.Marker({
//         position: { lat: stop.lat, lng: stop.lng },
//         map,
//         label: `${index + 2}`, // Incremental labels starting from 2
//         icon: {
//           path: window.google.maps.SymbolPath.CIRCLE,
//           fillColor: color,
//           fillOpacity: 1,
//           strokeWeight: 0,
//           scale: 8,
//         },
//       });
//     });

//     // Mark end location with label based on the number of stops
//     new window.google.maps.Marker({
//       position: { lat: route.endLocation.latitude, lng: route.endLocation.longitude },
//       map,
//       label: `${route.stops.length + 2}`, // The last marker, e.g., 3+stops will be 4, 5, etc.
//       icon: {
//         path: window.google.maps.SymbolPath.CIRCLE,
//         fillColor: color,
//         fillOpacity: 1,
//         strokeWeight: 0,
//         scale: 8,
//       },
//     });
//   }, [map, route, color]);

//   return null;
// };


export default MapWithRoutes;
