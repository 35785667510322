import React from "react";
import FullCalender from "./FullCalender";

const Subcontrator_Schedules = () => {
	const compareroutes = () => {};
	return (
		<div className="p-6">
			<div className="main-container flex flex-col border border-cardBorderCol bg-white px-8 rounded-cardRadii h-full">
				<div className="p-6 px-0 pb-2 font-medium">Subcontractor Information Management</div>
				<div className="border-b"></div>
				<div className="py-4 text-cardTextGray">
					Next Pick-Up Date <span className="ml-4 text-black">12/22/2024</span>
				</div>
				<div className="p-6 px-0 pb-2 font-medium">Schedule Recommendations</div>
				<div className="border-b"></div>
				<div className="py-4 text-cardTextGray">
					Provide Suggested Schedules <span className="ml-4 text-black">Lorem ipsum dolor sit amet</span>
				</div>
				<div className="flex justify-between">
					<div className="p-6 px-0 pb-2 font-medium">Service Calendar</div>
					<div className="">
						<button onClick={compareroutes} style={{ width: "184px", height: "38px" }} className="bg-whiteBlue px-4 py-1 rounded-full hover:bg-blue-300 transition-colors duration-300">
							Compare Routes
						</button>
					</div>
				</div>
				<div className="px-6 border-b"></div>
				<div className="flex gap-8 py-8">
					<div className="w-full xl:w-1/5">
						<div className="flex flex-col gap-2">
							<div className="flex bg-blue-700 items-center h-10 p-3 gap-3 rounded-[4px]">
								<h6 className="text-white">Lorem, ipsum dolor.</h6>
							</div>
							<h6 className="text-cardTextGray">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Eius, ducimus?</h6>
							<div className="flex bg-green-500 items-center h-10 p-3 gap-3 rounded-[4px]">
								<div className="w-4 h-4 rounded-full bg-white"></div>
								<h6 className="text-white">Lorem, ipsum dolor.</h6>
							</div>
							<div className="flex bg-yellow-500 h-10 items-center p-3 gap-3 rounded-[4px]">
								<div className="w-4 h-4 rounded-full bg-white"></div>
								<h6 className="text-white">Lorem, ipsum dolor.</h6>
							</div>
							<div className="flex h-10 bg-red-500 items-center p-3 gap-3 rounded-[4px]">
								<div className="w-4 h-4 rounded-full bg-white"></div>
								<h6 className="text-white">Lorem, ipsum dolor.</h6>
							</div>
						</div>
						<div className="py-4">
							<h6 className="text-base font-medium">How it works?</h6>
							<h6 className=" text-cardTextGray">
								It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. Richard McClintock, a Latin professor at
								Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage.
							</h6>
						</div>
					</div>
					<div className="w-full xl:w-4/5">
						<FullCalender />
					</div>
				</div>
			</div>
		</div>
	);
};

export default Subcontrator_Schedules;
