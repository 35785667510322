const notificationOptions = [
	{ label: "Send email on pick-up", value: "EMAIL_ON_PICKUP" },
	{ label: "Send email on drop-off", value: "EMAIL_ON_DROPOFF" },
	{ label: "Send service email", value: "SERVICE_EMAIL" },
	{ label: "Send email for billing and payment receipts", value: "EMAIL_FOR_BILLING_PAYMENT" },
	{ label: "Send email on manifest edit", value: "EMAIL_ON_MANIFEST_CHANGE" },
];

const templateOptions = [
	{ label: "How to log in", value: "howToLogin" },
	{ label: "How to see your service calendar", value: "howToSeeServiceCalendar" },
	{ label: "How to see your online manifests", value: "howToSeeOnlineManifests" },
];

const accessOptions = [
	{ label: "Contract Management", value: "CONTRACT_MANAGEMENT" },
	{ label: "Service", value: "SERVICE" },
	{ label: "Billing", value: "BILLING" },
	{ label: "Compliance", value: "COMPLIANCE" },
	{ label: "Other", value: "OTHER" },
];
import React, { useState, useEffect } from "react";
import { useFieldArray, Controller, useForm } from "react-hook-form";
import { BsPlusSquareFill } from "react-icons/bs";
import Input from "../../../../../../../../components/UI/Input";
import MultiSelectRounded from "../../../../../../../../components/UI/dropdowns/MultiSelectRounded";
import ContactModal from "./components/ContactModal";
import Button from "../../../../../../../../components/UI/Button";
import PhoneInput from "../../../../../../../../components/UI/PhoneInput";
import { toast } from "react-toastify";
import GeneratorInfoHeader from "./components/GeneratorInfoHeader";
import Dropdown from "../../../../../../../../components/UI/dropdowns/Dropdown";
import PasswordInput from "../../../../../../../../components/UI/PasswordInput";
import { updateContacts, updateContactsInDB } from "../../../../../../../../store/sliceReducers/formSlice";
import { useDispatch } from "react-redux";
import { sendEmailTemplates } from "../../../../../../../../utils/apiOps";

const PhoneNumberChip = ({ number, onRemove }) => (
	<div className="inline-flex items-center bg-gray-100 rounded-full px-3 py-1 text-sm text-gray-700 mr-2 mb-2">
		<span>{number}</span>
		<button onClick={() => onRemove(number)} type="button" className="ml-2 font-bold">
			&times;
		</button>
	</div>
);

const defaultContactValue = {
	name: "",
	phoneNumbers: [],
	emailAdd: "",
	username: "",
	password: "",
	contactStatus: "",
	urlLink: "",
	notification: [],
	contactNote: "",
	access: [],
	otherAccess: "",
};

const GeneratorContacts = ({ onNextClick, onClickBack, generatorData = {} }) => {
	const dispatch = useDispatch();
	const { control, handleSubmit, setValue, getValues, watch } = useForm({
		defaultValues: {
			currentContact: defaultContactValue,
		},
	});

	const [phoneNumber, setPhoneNumber] = useState("");
	const [selectedTemplates, setSelectedTemplates] = useState();
	const { fields, append, remove, update } = useFieldArray({
		control,
		name: "contacts",
	});
	const watchAccess = watch("currentContact.access");

	useEffect(() => {
		if (generatorData.contacts) {
			setValue("contacts", generatorData.contacts);
		}
	}, [generatorData, setValue]);

	const [isModalOpen, setIsModalOpen] = useState(false);

	const addPhoneNumber = () => {
		if (phoneNumber?.trim()?.length > 0) {
			let prevPhones = getValues("currentContact.phoneNumbers");
			setValue("currentContact.phoneNumbers", [...prevPhones, phoneNumber]);
			setPhoneNumber("");
		}
	};

	const removePhoneNumber = (numberToRemove) => {
		const updatedPhoneNumbers = getValues("currentContact.phoneNumbers").filter((number) => number !== numberToRemove);
		setValue("currentContact.phoneNumbers", updatedPhoneNumbers);
	};

	const addContact = () => {
		const currentContact = getValues("currentContact");

		// Only add contact if at least one field has data
		const hasData = Object.values(currentContact).some((value) => {
			if (Array.isArray(value)) {
				return value.length > 0;
			}
			return value !== "" && value !== null && value !== undefined;
		});

		if (hasData) {
			if (currentContact.access?.includes("OTHER") && currentContact.otherAccess) {
				currentContact.otherAccess = currentContact.otherAccess;
			}

			if (phoneNumber?.trim()?.length > 0) {
				let prevPhones = getValues("currentContact.phoneNumbers");
				currentContact.phoneNumbers = [...prevPhones, phoneNumber];
			}

			append(currentContact);
			setPhoneNumber("");
			setValue("currentContact", defaultContactValue);
			setValue("currentContact.notification", []);
			setValue("currentContact.access", []);
		} else {
			toast.warning("Please fill at least one field to add a contact");
		}
	};

	const onSubmitForm = async (e) => {
		e.preventDefault();

		// Only process contacts that have at least one field filled
		let allContacts = fields
			?.filter((contact) => {
				return Object.values(contact).some((value) => {
					if (Array.isArray(value)) {
						return value.length > 0;
					}
					return value !== "" && value !== null && value !== undefined;
				});
			})
			.map((contact) => {
				if (contact?.access?.includes("OTHER") && contact?.otherAccess) {
					return {
						...contact,
						otherAccessText: contact.otherAccess,
					};
				}
				return contact;
			});

		const currentContact = getValues("currentContact");

		// Only add contact if at least one field has data
		const hasData = Object.values(currentContact).some((value) => {
			if (Array.isArray(value)) {
				return value.length > 0;
			}
			return value !== "" && value !== null && value !== undefined;
		});

		if (hasData) {
			if (currentContact.access?.includes("OTHER") && currentContact.otherAccess) {
				currentContact.otherAccess = currentContact.otherAccess;
			}

			if (phoneNumber?.trim()?.length > 0) {
				let prevPhones = getValues("currentContact.phoneNumbers");
				currentContact.phoneNumbers = [...prevPhones, phoneNumber];
			}

			allContacts.push(currentContact);
		}

		let dataToSent = {
			id: generatorData.id,
			contacts: allContacts,
		};

		if (!generatorData.onboardingStatus) {
			dataToSent.onboardingStatus = {};
		} else {
			dataToSent.onboardingStatus = generatorData.onboardingStatus;
		}

		dataToSent.onboardingStatus.registrationCompleted = true;
		dataToSent.onboardingStatus.profileCompleted = true;
		dataToSent.onboardingStatus.contactsCompleted = true;

		try {
			dispatch(updateContacts(dataToSent));
			dispatch(updateContactsInDB(dataToSent));
			toast.success("Saved successfully!");
			onNextClick();
		} catch (error) {
			console.error(error);
			toast.error("Error saving data");
		}
	};

	const renderAccessOptions = () => {
		return (
			<>
				<Controller
					name="currentContact.access"
					control={control}
					render={({ field: { value, onChange } }) => (
						<MultiSelectRounded
							value={value}
							onChange={(newValue) => {
								onChange(newValue);
								if (!newValue?.includes("OTHER")) {
									setValue("currentContact.otherAccess", "");
								}
							}}
							options={accessOptions}
							label="Access"
							id="access-input"
						/>
					)}
				/>
				{watchAccess?.includes("OTHER") && (
					<Controller
						name="currentContact.otherAccess"
						control={control}
						render={({ field: { onChange, value } }) => (
							<Input label="Other Access" value={value} onChange={onChange} id="otherAccess-input" />
						)}
					/>
				)}
			</>
		);
	};

	const sendEmailToGenerator = async () => {
		let email = getValues("currentContact.emailAdd");
		let username = getValues("currentContact.username");
		let password = getValues("currentContact.password");
		console.log({ email, selectedTemplates });

		try {
			let resp = await sendEmailTemplates(email, selectedTemplates, { username, password });
			toast.success("Email sent successsfully!");
		} catch (error) {
			toast.error("Something went wrong!");
		}
	};

	return (
		<form onSubmit={onSubmitForm} className="w-full z-0 bg-white p-8 py-6 gap-4 rounded-cardRadii flex flex-col">
			<GeneratorInfoHeader generatorData={generatorData} />
			<div className="py-2 text-lg flex justify-between items-center border-b mb-2 border-[#CCCCCC]">
				<h6 className="font-medium">{"Generator Contacts (People)"}</h6>
				<p
					className="text-dashInActiveBtnText text-sm hover:cursor-pointer hover:text-cardTextBlue"
					onClick={() => setIsModalOpen(true)}
				>
					View ({fields.length})
				</p>
			</div>
			<div className="flex flex-col md:flex-row gap-4 md:gap-8 w-full">
				<div className="w-full md:w-1/2 space-y-4">
					<Controller
						name="currentContact.name"
						control={control}
						render={({ field: { onChange, value } }) => (
							<Input label="Contact Name" value={value} onChange={onChange} id="name-input" />
						)}
					/>
					<div>
						<PhoneInput
							label="Phone Number"
							type="multi"
							onClick={addPhoneNumber}
							value={phoneNumber}
							onChange={(value) => setPhoneNumber(value)}
						/>
						<div className="mt-2">
							{getValues("currentContact.phoneNumbers").map((number, index) => (
								<PhoneNumberChip key={index} number={number} onRemove={removePhoneNumber} />
							))}
						</div>
					</div>
					<Controller
						name="currentContact.emailAdd"
						control={control}
						render={({ field: { onChange, value } }) => (
							<Input label="Email Address" value={value} onChange={onChange} id="emailAdd-input" />
						)}
					/>
					<Controller
						name="currentContact.username"
						control={control}
						render={({ field: { onChange, value } }) => (
							<Input label="User Name" value={value} onChange={onChange} id="username-input" />
						)}
					/>
					<Controller
						name="currentContact.password"
						control={control}
						render={({ field: { onChange, value } }) => (
							<PasswordInput label="Password" value={value} onChange={onChange} id="password-input" />
						)}
					/>
					<Controller
						name="currentContact.contactStatus"
						control={control}
						render={({ field: { onChange, value } }) => (
							<Dropdown
								label="Contact Status"
								options={[
									{ label: "Active", value: "ACTIVE" },
									{ label: "Inactive", value: "INACTIVE" },
								]}
								value={value}
								onChange={onChange}
							/>
						)}
					/>
					<Controller
						name="currentContact.urlLink"
						control={control}
						render={({ field: { onChange, value } }) => (
							<Input label="URL Link" type="url" value={value} onChange={onChange} id="urlLink-input" />
						)}
					/>
					<MultiSelectRounded
						label="Template"
						options={templateOptions}
						value={selectedTemplates}
						onChange={(value) => setSelectedTemplates(value)}
					/>
					<button className="rounded-full w-40 py-2 bg-[#e3f0ff]" onClick={sendEmailToGenerator} type="button">
						Send
					</button>
				</div>
				<div className="w-full md:w-1/2 space-y-4">
					<Controller
						name="currentContact.notification"
						control={control}
						render={({ field: { value, onChange } }) => (
							<MultiSelectRounded
								value={value}
								onChange={onChange}
								options={notificationOptions}
								label="Notification"
								id="notification-input"
							/>
						)}
					/>
					<Controller
						name="currentContact.contactNote"
						control={control}
						render={({ field: { onChange, value } }) => (
							<Input label="Contact Note" value={value} onChange={onChange} id="contactNote-input" />
						)}
					/>
					{renderAccessOptions()}
				</div>
			</div>
			<div className="flex justify-end mt-4">
				<button
					type="button"
					className="flex gap-2 items-center hover:text-cardTextBlue text-dashInActiveBtnText rounded-full"
					onClick={addContact}
				>
					<BsPlusSquareFill className="text-cardTextBlue rounded-[4px]" size={22} />
					<p className="text-sm">Add</p>
				</button>
			</div>
			<ContactModal
				isModalOpen={isModalOpen}
				onUpdate={(index, updatedContact) => {
					update(index, updatedContact);
				}}
				onDelete={(index) => remove(index)}
				onClose={() => setIsModalOpen(false)}
				contacts={fields}
			/>
			<div className="flex justify-end gap-8 py-6">
				<Button btnStyle="form_nav_secondary" text={"Back"} onClick={onClickBack} />
				<Button btnStyle="form_nav_primary" text="Save & Next" onClick={onSubmitForm} />
			</div>
		</form>
	);
};

export default GeneratorContacts;
