import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { createOrUpdateGenerator } from "../../utils/firebaseOperations";
import { toast } from "react-toastify";

// Initial State
const initialState = {
	serviceNotes: {},
	profile: {},
	contacts: {},
	routeAssignments: {},
};

// Thunks for updating in DB and Redux store
export const updateProfileInDB = createAsyncThunk("form/updateProfileInDB", async (profileData, thunkAPI) => {
	const dataToSave = {
		...profileData,
		updatedAt: new Date(),
	};
	try {
		await createOrUpdateGenerator(dataToSave);
		// toast.success("Saved successfully!");
		return profileData;
	} catch (error) {
		console.log(error);
		return thunkAPI.rejectWithValue(error?.message);
	}
});

export const updateContactsInDB = createAsyncThunk("form/updateContactsInDB", async (contactsData, thunkAPI) => {
	const dataToSave = {
		...contactsData,
		updatedAt: new Date(),
	};
	try {
		await createOrUpdateGenerator(dataToSave);
		// toast.success("Saved successfully!");
		return contactsData;
	} catch (error) {
		console.log(error);
		return thunkAPI.rejectWithValue(error?.message);
	}
});

export const updateServiceNotesInDB = createAsyncThunk("form/updateServiceNotesInDB", async (serviceNotesData, thunkAPI) => {
	const dataToSave = {
		...serviceNotesData,
		updatedAt: new Date(),
	};
	try {
		await createOrUpdateGenerator(dataToSave);
		// toast.success("Saved successfully!");
		return serviceNotesData;
	} catch (error) {
		console.log(error);
		return thunkAPI.rejectWithValue(error?.message);
	}
});

// Redux slice
export const formSlice = createSlice({
	name: "form",
	initialState,
	reducers: {
		updateProfileData: (state, action) => {
			state.profile = action.payload;
		},
		updateContacts: (state, action) => {
			state.contacts = action.payload;
		},
		updateServiceNotes: (state, action) => {
			state.serviceNotes = action.payload;
		},
	},
	extraReducers: (builder) => {
		// Async thunks for updating the store after DB update
		builder
			.addCase(updateProfileInDB.fulfilled, (state, action) => {
				state.profile = action.payload;
			})
			.addCase(updateContactsInDB.fulfilled, (state, action) => {
				state.contacts = action.payload;
			})
			.addCase(updateServiceNotesInDB.fulfilled, (state, action) => {
				state.serviceNotes = action.payload;
			})
			.addCase(updateProfileInDB.rejected, (state, action) => {
				console.log("Error updating profile:", action.payload);
			})
			.addCase(updateContactsInDB.rejected, (state, action) => {
				console.log("Error updating contacts:", action.payload);
			})
			.addCase(updateServiceNotesInDB.rejected, (state, action) => {
				console.log("Error updating service notes:", action.payload);
			});
	},
});

// Export the synchronous actions
export const { updateProfileData, updateContacts, updateServiceNotes } = formSlice.actions;

export default formSlice.reducer;
