import React, { useState, useMemo } from "react";
import { PiCalendarDotsLight } from "react-icons/pi";

const currentDate = new Date();
const fiveDaysLater = new Date(currentDate);
fiveDaysLater.setDate(currentDate.getDate() + 5);

const pickUpData = [
	{
		id: "4",
		title: "Bulk Item Removal",
		subTitle: "Residential Areas",
		vehicleNo: "BU 1122 LK",
		date: new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 4),
	},
	{
		id: "5",
		title: "Electronic Waste Collection",
		subTitle: "Tech Park",
		vehicleNo: "EW 3344 TP",
		date: new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 5),
	},
	{
		id: "7",
		title: "Medical Waste Collection",
		subTitle: "Silicon Valley",
		vehicleNo: "EW 3344 TP",
		date: new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 5),
	},
	{
		id: "1",
		title: "General Waste Collection",
		subTitle: "Downtown Area",
		vehicleNo: "WM 1234 RC",
		date: new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 1),
	},
	{
		id: "2",
		title: "Recycling Pickup",
		subTitle: "Suburban District",
		vehicleNo: "RC 5678 WM",
		date: new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 1),
	},
	{
		id: "3",
		title: "Organic Waste",
		subTitle: "City Center",
		vehicleNo: "OW 9101 GR",
		date: new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 1),
	},
];

const OnGoingPickups = () => {
	const [activeTab, setActiveTab] = useState("ongoing");

	const filteredPickups = useMemo(() => {
		return pickUpData.filter((pickup) => {
			if (activeTab === "ongoing") {
				return pickup.date >= currentDate && pickup.date < new Date(currentDate.getTime() + 24 * 60 * 60 * 1000);
			} else {
				return pickup.date > currentDate && pickup.date <= fiveDaysLater;
			}
		});
	}, [activeTab]);

	const formatDate = (date) => {
		return date.toLocaleDateString("en-US", { day: "numeric", month: "short", year: "numeric" });
	};

	return (
		<div className="bg-white rounded-cardRadii h-96 shadow-sm p-6 flex flex-col gap-2">
			<div className="flex justify-between">
				<h2 className="text-lg font-medium">Ongoing Pickups</h2>
			</div>
			<div className="rounded-full bg-creamWhite flex items-center my-2 mb-4 max-w-max relative">
				<div
					className="absolute transition-all duration-300 ease-in-out bg-dashBtnGradient rounded-full"
					style={{
						left: activeTab === "ongoing" ? "0" : "50%",
						width: "50%",
						height: "100%",
					}}
				></div>
				<button
					onClick={() => setActiveTab("ongoing")}
					className={`px-6 py-2 relative z-10 transition-colors duration-300 ${activeTab === "ongoing" ? "text-white" : "text-dashInActiveBtnText"}`}
				>
					On Going
				</button>
				<button
					onClick={() => setActiveTab("next5days")}
					className={`px-6 py-2 relative z-10 transition-colors duration-300 ${activeTab === "next5days" ? "text-white" : "text-dashInActiveBtnText"}`}
				>
					Next 5 days
				</button>
			</div>
			<div className="space-y-4 overflow-auto h-full">
				{filteredPickups.length > 0 ? (
					filteredPickups.map(({ id, title, subTitle, vehicleNo, date }, i) => (
						<div key={id}>
							<div className="flex justify-between">
								<div className="flex gap-6">
									<div className="flex flex-col gap-2 min-h-full">
										<h3 className="font-medium">{title}</h3>
										<p className="pl-0 ml-0 text-sm text-cardTextGray">{subTitle}</p>
									</div>
								</div>
								<div className="text-cardTextGray flex flex-col items-end gap-2 my-1 min-h-full">
									<p className={`rounded-full bg-creamWhite flex items-center max-w-max text-right text-sm border border-logoutBtn p-1 px-3`}>{vehicleNo}</p>
									<div className="flex items-center gap-1 text-sm pr-2">
										<PiCalendarDotsLight className="text-[#6c6c72] mr-1 ml-3" />
										<p className="text-gray-500">{formatDate(date)}</p>
									</div>
								</div>
							</div>
							{i < filteredPickups.length - 1 && <div className="bg-gray-200 w-full h-[1px] mt-4 rounded-[25px]"></div>}
						</div>
					))
				) : (
					<div className="flex h-full text-cardTextGray text-xl justify-center items-center">
						<p>No pickup found</p>
					</div>
				)}
			</div>
		</div>
	);
};

export default OnGoingPickups;
