import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
 import SearchBox from "../../../components/UI/searchBox/Search";
 import { useBreadcrumb } from "../../../context/BreadcrumbContext";
 import HamburgerMenu from "../../../components/header/HamburgerMenu";
import Breadcrumb from "../../../components/header/Breadcrumb";
import UserAccBtn from "../../../components/userAccBtn/UserAccBtn";
 import { formattedDate } from "../../../utils/helpers";
import Notification from "../../../components/notifications/Notification";

const TransportHeader = ({ activeId }) => {
    const [isNotificationOpen, setIsNotificationOpen] = useState(false);
    const [breadCrumbOptions, setBreadCrumbOptions] = useState([]);
    const location = useLocation();
    const navigate = useNavigate();
    const { breadcrumbData } = useBreadcrumb();

    const pathSegments = location.pathname.split("/");
    const pathId = pathSegments[3] === "new" ? "New" : pathSegments[3];
    const formName = pathSegments[4] || "";

    useEffect(() => {
        if (location.pathname.includes("/satellite")) {
            setBreadCrumbOptions(getSatelliteBreadcrumbItems(location, formName, breadcrumbData.id, pathId));
        }
    }, [location, pathId, breadcrumbData.id, formName]);

    const activeIndex = breadCrumbOptions.findIndex(item =>
        item.label.toLowerCase() === formName.toLowerCase() || item.path.includes(formName) || !formName
    );

    const headerClass = location.pathname === "/" ? "bg-transparent pt-4" : "bg-white border border-borderCol h-20";
    const wrapperClass = location.pathname === "/satellites" ? "ml-72 max-w-full mx-auto" : "max-w-full mx-auto";
    const menuItems = [];

    return (
        <div className={`top-0 flex items-center px-8 text-sm xl:text-base justify-between ${headerClass} ${wrapperClass}`}>
            <div className="md:hidden">
                <HamburgerMenu />
            </div>
            {location.pathname === "/" ? (
                <div className="md:w-1/3">
                    <SearchBox menuItems={menuItems} />
                </div>
            ) : (
                <Breadcrumb items={breadCrumbOptions} activeIndex={activeIndex} navigate={navigate} />
            )}
            <div className="flex gap-2 md:gap-4 items-center">
                {location.pathname === "/satellites" && (
                    <>
                        <div className="hidden xl:flex items-center bg-white border border-gray-200 rounded-full px-10 h-12">
                            <h6 className="truncate">{formattedDate(new Date())}</h6>
                        </div>
                        <Notification isOpen={isNotificationOpen} setIsOpen={setIsNotificationOpen} />
                    </>
                )}
                <UserAccBtn />
            </div>
        </div>
    );
};

export default TransportHeader;

const getSatelliteBreadcrumbItems = (location, formName, breadcrumbId, pathId) => {
    const baseCrumbs = [{ label: "Satellite Locations", path: "/satellites" }];
    const formCrumbs = [];

     if (location.pathname.includes("/satellite/add-satellite")) {
        formCrumbs.push({
            label: "Add Satellite Location",
            path: "/satellite/add-satellite"
        });
    } 
    return [...baseCrumbs, ...formCrumbs];
};
