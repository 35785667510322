import PropTypes from "prop-types";
import { AdvancedMarker, InfoWindow, Pin } from "@vis.gl/react-google-maps";
import { useCallback, useState } from "react";
import { IoMdClose } from "react-icons/io";
import { TbChartArcs } from "react-icons/tb";
import { LuUser, LuUsers } from "react-icons/lu";

const GeneratorSingleMarkerWithInfoWindow = ({ marker, isCurrent = false }) => {
	const [infoWindowOpen, setInfoWindowOpen] = useState(false);

	const handleMarkerClick = useCallback(() => {
		setInfoWindowOpen(true);
	}, []);

	const handleInfoWindowClose = useCallback(() => {
		setInfoWindowOpen(false);
	}, []);

	return (
		<>
			{infoWindowOpen && (
				<InfoWindow onCloseClick={handleInfoWindowClose} position={{ lat: marker.lat, lng: marker.lng }}>
					<div className="bg-white shadow-lg rounded-lg overflow-hidden relative">
						<div className="flex justify-between items-center bg-gray-100 p-2 border-b border-gray-300">
							<h2 className="text-lg font-semibold text-gray-800">{marker.name}</h2>
							<button
								onClick={handleInfoWindowClose}
								className="text-gray-500 hover:text-red-500 transition-colors duration-200"
							>
								<IoMdClose size={24} />
							</button>
						</div>
						<div className="border-t border-b h-fit p-4 text-sm">
							<div className="flex gap-6">
								<div className="font-medium text-nowrap w-1/3">
									<p>Service Address:</p>
									<p>OCTO No:</p>
									<p>Service Type:</p>
									<p>Service Frequency:</p>
									<p>Route Assigned:</p>
								</div>
								<div className="w-2/3">
									<p className="text-nowrap">{marker.formattedAdd}</p>
									<p>{marker.octoNumber}</p>
									<p>{marker.serviceType?.join(", ")}</p>
									<p>{marker.serviceFrequency ?? "--"}</p>
									<p>{marker.assignedRoutes ?? "None"}</p>
								</div>
							</div>
						</div>
					</div>
					<style>{`
        .gm-style-iw.gm-style-iw-c {
          padding: 0 !important;
          overflow: hidden !important;
        }
        .gm-style-iw-ch {
          padding: 0 !important;
          overflow: hidden !important;
        }
        .gm-ui-hover-effect {
          display: none !important;
        }
        .gm-style-iw-d {
          padding: 0 !important;
          overflow: hidden !important;
        }
      `}</style>
				</InfoWindow>
			)}
			<AdvancedMarker position={{ lat: marker.lat, lng: marker.lng }} onClick={handleMarkerClick}>
				{!isCurrent && (
					<Pin scale={1.2}>
						<LuUsers className="text-xl" />
					</Pin>
				)}
				{isCurrent && (
					<Pin scale={1.5} background={"#FFA500"} borderColor={"#FFA500"} glyphColor={"#E3963E"}>
						<LuUsers className="text-3xl text-white" />
					</Pin>
				)}
			</AdvancedMarker>
		</>
	);
};

GeneratorSingleMarkerWithInfoWindow.propTypes = {
	marker: PropTypes.shape({
		assignedRoutes: PropTypes.string,
		formattedAdd: PropTypes.any,
		lat: PropTypes.number.isRequired,
		lng: PropTypes.number.isRequired,
		name: PropTypes.any,
		octoNumber: PropTypes.any,
		serviceFrequency: PropTypes.string,
		serviceType: PropTypes.array,
	}),
};

export default GeneratorSingleMarkerWithInfoWindow;
